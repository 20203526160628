import React, { Component, useState } from 'react'
import PropTypes from 'prop-types'
import {
  CardForm,
  CardHeader,
  CardRightContent,
  CardTitle,
  CardWrapper,
  Detail,
} from './MyGlobalStyle'
import { AddButton, CancelButton } from 'react-trello/dist/styles/Elements'
import EditableLabel from 'react-trello/dist/widgets/EditableLabel'
import { MyButton } from '../_MyComponents'

const MyNewCardForm = (props) => {
  const [value, setValue] = useState('')

  const updateField = (val) => {
    setValue(val)
    //props.onAdd({"title": value})
  }

  const handleAdd = (val) => {
    props.onAdd({ title: value })
  }

  const { onCancel, t } = props
  return (
    <CardForm>
      <CardWrapper>
        <CardHeader>
          <CardTitle>
            <EditableLabel
              placeholder={t('placeholder.title')}
              onChange={(val) => updateField(val)}
              autoFocus
            />
          </CardTitle>
        </CardHeader>
      </CardWrapper>
      <div className="card-button-wrapper">
        <MyButton size="small" onClick={onCancel}>
          {t('button.Cancel')}
        </MyButton>
        <MyButton
          size="small"
          variant="contained"
          color="primary"
          onClick={handleAdd}
        >
          Add Tag
        </MyButton>
      </div>
    </CardForm>
  )
}

MyNewCardForm.propTypes = {
  onCancel: PropTypes.func.isRequired,
  onAdd: PropTypes.func.isRequired,
  t: PropTypes.func.isRequired,
}

MyNewCardForm.defaultProps = {}

export default MyNewCardForm
