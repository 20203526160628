import { inject, observer } from 'mobx-react'
import CreateWorkspace from './CreateWorkspace'
import './style.scss'

export default inject(({ orgsWorkspacesStore }) => ({
  orgName: orgsWorkspacesStore.orgName,
  workspaceName: orgsWorkspacesStore.workspaceName,
  addWorkspace: orgsWorkspacesStore.addWorkspace,
  workspaceNameChange: orgsWorkspacesStore.workspaceNameChange,
}))(observer(CreateWorkspace))
