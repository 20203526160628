import { inject, observer } from 'mobx-react'
import CreateOrganization from './CreateOrganization'
import './style.scss'
import './mobile.scss'

export default inject(({ orgsWorkspacesStore }) => ({
  name: orgsWorkspacesStore.orgName,
  addOrganization: orgsWorkspacesStore.addOrganization,
  orgNameChange: orgsWorkspacesStore.orgNameChange,
}))(observer(CreateOrganization))
