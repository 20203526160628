import React, { useState, useEffect } from 'react'
import { MyKeyword } from '../../_MyComponents'
import { uniqBy } from 'lodash'
import File from '../../File'
import TagList from '../../TagList'
import { CSSTransition, TransitionGroup } from 'react-transition-group'
import Person from '../../Person'
import Files from '../../Files'

import { Steps } from 'intro.js-react'
import { steps2 } from '../../_intro-js'

const UploadTagging = ({
  // from store
  recentlyUploadedFiles,
  allTags,
  onRemoveTag,
  bulkTag,
  bulkRemoveTags,
  userState,
  updateUserState,
  isAnonymous,

  //from parent
  appliedTags,
  setAppliedTags,
}) => {
  const [newPersons, setNewPersons] = useState([])
  const [anchorAll, setAnchorAll] = useState(null)

  const [introJS, setIntroJS] = useState(false)

  useEffect(() => {
    setTimeout(() => {
      setIntroJS(true)
    }, 1000)
  }, [])

  const onCloseIntro = () => {
    setIntroJS(false)
    updateUserState({ 'onboarding.autoTags': true })
  }

  const anchorOrigin = {
    vertical: 'bottom',
    horizontal: 0,
  }

  ///////////////////////////////
  //////  HANDLE PERSONS
  ///////////////////////////////

  const getPersons = () => {
    let tempFilePersons = recentlyUploadedFiles.map((file) => {
      const persons = file.persons ? [...file.persons] : []
      return persons
    })

    tempFilePersons = tempFilePersons
      .flat()
      .filter((p) => p.removed === false)
      .filter(
        (p) =>
          //only show person that have defaultFaceLocation
          p.defaultFaceLocation && p.defaultFaceLocation.length > 0
      )

    tempFilePersons = tempFilePersons.map((person) => {
      var count = tempFilePersons.filter(
        (p) => p._id.toString() === person._id.toString()
      ).length
      return { ...person, count }
    })

    const fullFilePersons = uniqBy(tempFilePersons, '_id')

    console.log({ fullFilePersons })

    const filePersons =
      !!fullFilePersons?.length > 0 &&
      fullFilePersons
        //.sort((a, b) => b.count - a.count)
        .map((person, idx) => (
          <div className="flex-col" key={person._id}>
            <Person
              isClickable={false}
              key={`person-${person._id}`}
              person={person}
              itemId={`${person._id}-k`}
              //setView={setView}
            />
            <p className="p tag-count">
              {`Found in ${person.count} ${
                person.count === 1 ? 'file' : 'files'
              }`}
            </p>
          </div>
        ))

    // get new keywords that don't have existing tags
    setNewPersons(filePersons)

    //remove walkthrough step if no new people
    if (!filePersons.length && steps2.length === 3) {
      steps2.splice(1, 1)
    }
  }

  useEffect(() => {
    getPersons()
  }, [])

  useEffect(() => {
    getPersons()
  }, [recentlyUploadedFiles])

  const fileTags = (file) => {
    const filetags = recentlyUploadedFiles.find(
      (it) => it.fileId === file.fileId
    ).tags
    return filetags.map((tag) => {
      return (
        <CSSTransition key={tag._id} classNames="kw-animation" timeout={3000}>
          <MyKeyword
            text={tag.name}
            key={`key_${tag.name}`}
            onClick={() =>
              onRemoveTag({ fileId: file._id, tagsIds: [tag._id] })
            }
          />
        </CSSTransition>
      )
    })
  }

  const addTagToAll = ({ tags }) => {
    setAppliedTags(tags)
    //let fileIds = recentlyUploadedFiles.map((file) => file._id)
    //bulkTag({ fileIds: fileIds, tags: tags, clearExisting: false })
  }

  const removeTagFromAll = ({ tags }) => {
    //ugly workaround so not to change the main functionality of the TagList
    setAppliedTags(appliedTags.filter((tag) => tag != tags[0]))
    //let fileIds = recentlyUploadedFiles.map((file) => file._id)
    //let tagIds = tags.map((tag) => tag._id)
    //bulkRemoveTags({ fileIds: fileIds, tagIds: tagIds })
  }

  const keywords =
    appliedTags.length > 0
      ? appliedTags
          .slice()
          .sort((a, b) => b.count - a.count)
          .map((tag, idx) => {
            const { name } = tag
            return (
              <MyKeyword
                key={`tag-${idx}`}
                text={name}
                onDelete={() => removeTagFromAll({ tags: [tag] })}
              />
            )
          })
      : ''

  const previewFiles = (
    <Files
      uploadMode={true}

      //openUpload={setIsUploadDialogOpen}
      //previewDialog={setIsPreviewDialogOpen}
      //collapseSidebar={collapseSidebar}
      /* onDelete={(id) => {
        setShowDeleteCollectionDialog(true)
        setDeleteCollectionId(id)
      }}
      editCollection={() => {
        setShowEditCollectionDialog(true)
      }} */
      /* setView={setView}
      isCollection={true}
      setIsPlanPrompt={setIsPlanPrompt} */
    />
  )

  return (
    <div className="autotags-container">
      <div className="autotags-box">
        <h1 className="h1">Tag Files</h1>
        <div className="autotag-section">
          <div className="autotag-header">
            <h2 className="h2">Apply tags to all files</h2>
          </div>
          <span className="tag-placeholder">{keywords}</span>
          <div
            className="add-tag-button"
            onClick={() => {
              //querySelector is a workaround because selecting the ref breaks the popover
              setAnchorAll(document.querySelector('.add-tag-button'))
            }}
          >
            + Add a tag
          </div>
        </div>
        {newPersons.length && (
          <div className="autotag-section">
            <div className="autotag-header">
              <h2 className="h2">Name people in images</h2>
            </div>
            <div className="persons-content-container">
              <div className="persons-content">{newPersons}</div>
            </div>
          </div>
        )}
      </div>
      <div className="upload-files-box">{previewFiles}</div>
      <TagList
        width={{ width: '250px' }}
        anchor={anchorAll}
        anchorOrigin={anchorOrigin}
        onClose={() => setAnchorAll(null)}
        tags={appliedTags}
        allTags={allTags}
        onAddTag={addTagToAll}
        onRemoveTag={removeTagFromAll}
        /* need to change */
        fileId={'000'}
      />
      {!isAnonymous && userState && !userState?.onboarding?.autoTags && (
        <Steps
          enabled={introJS}
          steps={steps2}
          initialStep={0}
          onExit={onCloseIntro}
          options={{
            exitOnOverlayClick: false,
            doneLabel: 'Start tagging',
          }}
        />
      )}
    </div>
  )
}

export default UploadTagging
