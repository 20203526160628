import { inject, observer } from 'mobx-react'
import CollectionHeader from './CollectionHeader'
import './CollectionHeader.scss'
import './mobile.scss'

export default inject(({ filesStore, orgsWorkspacesStore }) => ({
  currentCollection: filesStore.currentCollection,
  onReset: filesStore.onReset,
  isSearchMode: filesStore.isSearchMode,
  onResetFilteres: filesStore.onResetFilteres,
  isJustCollectionUser: orgsWorkspacesStore.isJustCollectionUser,
  isAllowed: orgsWorkspacesStore.isAllowed,
  onPersonSelected: filesStore.onPersonSelected,
  bulkDownload: filesStore.bulkDownload,
  getCurrentCollection: filesStore.getCurrentCollection,
  updateCollectionCover: filesStore.updateCollectionCover,
}))(observer(CollectionHeader))
