import { inject, observer } from 'mobx-react'
import AssignWorkspace from './AssignWorkspace'
import './AssignWorkspace.css'

export default inject(({ dropboxImportStore }) => ({
  folderHierarchy: dropboxImportStore.folderHierarchy,
  loading: dropboxImportStore.loadingHierarchy,
  folders: dropboxImportStore.folders,
  browseTo: dropboxImportStore.browseTo,
  parent: dropboxImportStore.parent,
  toggleAddFolder: dropboxImportStore.toggleAddFolder,
  folderSelected: dropboxImportStore.folderSelected,
  breadcrumb: dropboxImportStore.breadcrumb,
  removeFolder: dropboxImportStore.removeFolder,
}))(observer(AssignWorkspace))
