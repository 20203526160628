import { inject, observer } from 'mobx-react'
import Person from './Person'
import './Person.scss'

export default inject(({ filesStore, orgsWorkspacesStore }) => ({
  renamePerson: filesStore.renamePerson,
  removePerson: filesStore.removePerson,
  onPersonSelected: filesStore.onPersonSelected,
  isAllowed: orgsWorkspacesStore.isAllowed,
  getRecentlyUploadedFiles: filesStore.getRecentlyUploadedFiles,
}))(observer(Person))
