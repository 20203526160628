import React, { useEffect, useState, useRef } from 'react'
import { makeStyles } from '@material-ui/core/styles'
import File from '../File'
import './mobile.scss'
import MAGNIFYING from '../../assets/magnifying-glass.svg'
import EmptyStateMain from '../EmptyStateMain'
import { MyButton } from '../_MyComponents'
import Selecto from 'react-selecto'
import InfiniteScroll from 'react-infinite-scroll-component'
import CollectionHeader from '../CollectionHeader'
// virtual scroll
import { useScroller } from 'mini-virtual-list'
import { usePositioner, useResizeObserver, useMasonry } from 'masonic'
import useEvent from '@react-hook/event'
import TableView from '../TableView'

import ToggleButton from '@mui/material/ToggleButton'
import ToggleButtonGroup from '@mui/material/ToggleButtonGroup'
import GRID from '../../assets/grid-icon.svg'
import TABLE from '../../assets/table-icon.svg'
import { RULES } from '../../store/OrgsWorkspacesStore/permissions'
import Tooltip from '@material-ui/core/Tooltip'

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    zIndex: 0,
    display: 'flex',
    flexDirection: 'column',
    height: '100%',
  },
}))

const Files = ({
  // from store
  addToFavorite,
  getFilesFiltered,
  isCollectionMode,
  isLoading,
  setSelectedList,
  FILES_IN_PAGE,
  planResolvedPercent,
  orgPlan,
  DBFiles,
  filesCount: allFilesCount,
  isSearchMode,
  fileViewMode,
  toggleFileViewMode,
  isAllowed,
  filesInQ,

  // from parent
  openUpload,
  previewDialog,
  collapseSidebar,
  onDelete,
  editCollection,
  setView,
  isCollection = false,
  setIsPlanPrompt,
  uploadMode,
}) => {
  const classes = useStyles()
  const [files, setFiles] = useState([])
  const [filesCount, setFilesCount] = useState(allFilesCount)

  useEffect(() => {
    uploadMode ? setFiles(filesInQ) : setFiles(DBFiles)
    uploadMode ? setFilesCount(filesInQ.length) : setFilesCount(allFilesCount)

    //workaround to fix rendering issue
    setTimeout(() => {
      handleResize()
    }, 100)
  }, [DBFiles, filesInQ])

  /////////////////////////////////////////
  // selecto (drag select)
  /////////////////////////////////////////

  const [scrollOptions, setScrollOptions] = useState({})
  const selectoRef = useRef(null)

  useEffect(() => {
    setScrollOptions({
      container: document.body,
      getScrollPosition: () => {
        return [containerRef.current.scrollLeft, containerRef.current.scrollTop]
      },
      throttleTime: 30,
      threshold: 5,
    })
  }, [])

  /////////////////////////////////////////
  // virtual scroll
  /////////////////////////////////////////

  const [size, setSize] = useState({ height: 0, width: 0 })

  const containerRef = useRef(null)

  const calcSize = () => {
    const clientHeight = containerRef?.current?.clientHeight
    const clientWidth = containerRef?.current?.clientWidth
    const styles = !!containerRef?.current
      ? getComputedStyle(containerRef?.current)
      : undefined
    const height =
      clientHeight -
      parseFloat(styles?.paddingTop) -
      parseFloat(styles?.paddingBottom)
    const width =
      clientWidth -
      parseFloat(styles?.paddingLeft) -
      parseFloat(styles?.paddingRight)
    return { height, width }
  }

  const handleResize = () => setSize(calcSize())

  // ugly workaround - this recalculated the sizes after the 0.5s animation of the sidebar (+100ms buffer)
  useEffect(() => {
    const timer = setTimeout(() => {
      handleResize()
    }, '600')
    return () => clearTimeout(timer)
  }, [collapseSidebar])

  // copied from useSize

  useEvent(
    typeof window !== 'undefined' ? window : null,
    'resize',
    handleResize
  )

  useEvent(
    typeof window !== 'undefined' ? window : null,
    'orientationchange',
    handleResize
  )

  const { height, width } = size
  const { scrollTop, isScrolling } = useScroller(containerRef)

  // workaround for the collection header to be taken into account in the scroll position
  const headerHeight =
    document.querySelector('.collection-header-container')?.clientHeight ||
    height

  const columnWidth = window.mobileCheck() ? 140 : 220

  const positioner = usePositioner(
    {
      width,
      columnWidth: columnWidth,
      columnGutter: 4,
    },
    [files?.length]
  )
  const resizeObserver = useResizeObserver(positioner)

  const MasonryCard = ({ index, data: file, width }) => (
    <File
      key={file.fileId}
      fullFile={file}
      addToFavorite={addToFavorite}
      previewDialog={previewDialog}
      width={width}
      uploadMode={uploadMode}
    ></File>
  )

  const CardWithClick = React.useCallback(
    (props) => <MasonryCard {...props} />,
    []
  )

  function nextPage() {
    getFilesFiltered({ skip: files.length })
  }

  //////////////////////////////////////////////
  // General for all modes
  //////////////////////////////////////////////

  const handleUploadClick = () => {
    if (planResolvedPercent * 100 < 100 || orgPlan !== 'free') {
      openUpload((prev) => !prev)
    } else {
      setIsPlanPrompt(true)
    }
  }

  const handleSelect = (e) => {
    const newFiles = []
    if (e.selected.length) {
      e.selected.forEach((file) => {
        const tempData = file.dataset
        const tempFile = files.find((file) => file._id === tempData.id)
        newFiles.push(tempFile)
      })
      setSelectedList(newFiles)
    } else if (
      e.inputEvent.ctrlKey ||
      e.inputEvent.target.nodeName === 'INPUT' ||
      e.inputEvent.target.nodeName === 'BUTTON'
    ) {
      return
    } else {
      setSelectedList([])
    }
  }

  return (
    <div
      className="files"
      tabIndex="0"
      onScroll={() => {
        if (!window.mobileCheck) {
          selectoRef.current.checkScroll()
        }
      }}
    >
      {!window.mobileCheck() && (
        <Selecto
          // The container to add a selection element
          //container={document.body}
          // The area to drag selection element (default: container)
          dragContainer={
            uploadMode ? '.upload-wizard-dialog' : '.content-wrapper'
          }
          // Targets to select. You can register a queryselector or an Element.
          selectableTargets={
            uploadMode ? ['.upload-file'] : ['.file', '.MuiDataGrid-row']
          }
          // Whether to select by click (default: true)
          selectByClick={false}
          // Whether to select from the target inside (default: true)
          selectFromInside={true}
          // After the select, whether to select the next target with the selected target (deselected if the target is selected again).
          continueSelect={false}
          // Determines which key to continue selecting the next target via keydown and keyup.
          toggleContinueSelect={'shift'}
          // The container for keydown and keyup events
          keyContainer={window}
          // The rate at which the target overlaps the drag area to be selected. (default: 100)
          hitRate={2}
          ref={selectoRef}
          scrollOptions={scrollOptions}
          onScroll={(e) => {
            containerRef.current.scrollBy(
              e.direction[0] * 40,
              e.direction[1] * 40
            )
          }}
          onSelect={handleSelect}
        />
      )}
      <div className={classes.root + ' mobile-search-files'}>
        <div
          className={'files-container ' + (files?.length > 0 && 'has-files')}
          ref={containerRef}
          id="scrollableDiv"
        >
          {isCollection && (
            <CollectionHeader
              onDelete={onDelete}
              editCollection={editCollection}
              setView={setView}
              previewDialog={previewDialog}
              fileCount={files.length}
              files={files}
            />
          )}

          {!!files?.length && !isLoading && (
            <div className="files-subheader-container">
              <h2 className="h2">
                {filesCount.toLocaleString()}{' '}
                {filesCount === 1 ? 'asset' : 'assets'}
              </h2>
              {isAllowed(RULES.SELECT_GRID) && !uploadMode && (
                <div className="view-toggle">
                  <ToggleButtonGroup
                    value={fileViewMode}
                    size="small"
                    exclusive
                    onChange={(value) => {
                      if (value) {
                        toggleFileViewMode()
                      }
                    }}
                    aria-label="text alignment"
                  >
                    <Tooltip
                      placement="bottom"
                      key={`grid-toggle`}
                      enterDelay={1000}
                      enterNextDelay={1000}
                      title={'Grid view'}
                    >
                      <ToggleButton value="grid" aria-label="left aligned">
                        <img src={GRID} alt="grid" />
                      </ToggleButton>
                    </Tooltip>
                    <Tooltip
                      placement="bottom"
                      key={`table-view`}
                      enterDelay={1000}
                      enterNextDelay={1000}
                      title={'Table view'}
                    >
                      <ToggleButton value="table" aria-label="centered">
                        <img src={TABLE} alt="table" />
                      </ToggleButton>
                    </Tooltip>
                  </ToggleButtonGroup>
                </div>
              )}
            </div>
          )}

          {fileViewMode === 'grid' && !!files?.length && !isLoading && (
            <InfiniteScroll
              className="infinite-scroll-container"
              dataLength={files?.length}
              scrollThreshold={'2000px'} //switched from % to px tp prevent premature fetches
              next={nextPage}
              hasMore={!!files?.length && files?.length % FILES_IN_PAGE === 0}
              loader={<p className="infinite-scroll-message">Loading...</p>}
              endMessage={
                files?.length > FILES_IN_PAGE && (
                  <p className="infinite-scroll-message">
                    Yay! You have seen it all
                  </p>
                )
              }
              scrollableTarget="scrollableDiv"
              //add 100% min-height
              style={{
                minHeight: '100%',
                overflow: 'visible',
                flex: 1,
                overflowX: 'hidden',
              }}
            >
              {
                <CustomMasonry
                  positioner={positioner}
                  resizeObserver={resizeObserver}
                  files={files}
                  height={height}
                  headerHeight={headerHeight}
                  scrollTop={scrollTop}
                  isScrolling={isScrolling}
                  CardWithClick={CardWithClick}
                />
              }
            </InfiniteScroll>
          )}

          {fileViewMode === 'table' && !!files?.length && !isLoading && (
            <div className="table-wrapper">
              <TableView previewDialog={previewDialog} />
            </div>
          )}

          {/* {(!!files?.length && files?.length % 120 === 0) && <div className="load-more"><Button variant="outlined" onClick={nextPage}>load more</Button></div>} */}
          {files?.length === 0 && isSearchMode() && !isLoading && (
            <div className="no-files-container">
              <img
                className="es-image"
                src={MAGNIFYING}
                alt="No results for this search"
              />
              <div className="no-files-text">Couldn’t find anything</div>
              <div>Another filter, perhaps?</div>
            </div>
          )}
          {files?.length === 0 &&
            isCollectionMode() &&
            !isSearchMode() &&
            !isLoading && (
              <div className="empty-state-card-container">
                <div className="empty-state-card">
                  <div className="empty-state-content">
                    <div className="file-type-icon-wrappaer">
                      <img
                        src={MAGNIFYING}
                        className="file-type-icon"
                        alt="No files in this collection"
                      />
                    </div>
                    <div className="es-card-title">
                      No files in this collection.
                    </div>
                    {isAllowed(RULES.UPLOAD_DOCUMENTS) && (
                      <div className="es-card-desc">
                        Upload files directly to collection, or choose existing
                        files from the Home screen
                      </div>
                    )}
                  </div>
                  {isAllowed(RULES.UPLOAD_DOCUMENTS) && (
                    <MyButton
                      onClick={handleUploadClick}
                      className="es-card-button"
                      variant="contained"
                      color="primary"
                    >
                      <label /* htmlFor="contained-button-file" */>
                        Upload
                      </label>
                    </MyButton>
                  )}
                </div>
              </div>
            )}
          {files?.length === 0 &&
            !uploadMode &&
            !isSearchMode() &&
            !isCollectionMode() &&
            !isLoading && (
              <div className="empty-state-container">
                <EmptyStateMain openUpload={handleUploadClick} />
              </div>
            )}
        </div>
      </div>
    </div>
  )
}

// separate component for the masonry to avoid re-renders
const CustomMasonry = ({
  positioner,
  resizeObserver,
  files,
  height,
  headerHeight,
  scrollTop,
  isScrolling,
  CardWithClick,
}) => {
  return useMasonry({
    positioner,
    resizeObserver,
    items: files,
    height: height + headerHeight,
    scrollTop,
    isScrolling,
    overscanBy: 2,
    render: CardWithClick,
  })
}

export default Files
