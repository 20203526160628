import { inject, observer } from 'mobx-react'
import CollectionFMPDialog from './CollectionFMPDialog'
import './CollectionFMPDialog.scss'
import './mobile.scss'

export default inject(({ filesStore, orgsWorkspacesStore }) => ({
  simpleUploadFileToS3: filesStore.simpleUploadFileToS3,
  searchFaceFromImage: filesStore.searchFaceFromImage,
  filesInQ: filesStore.filesInQ,
  isUploadFinished: filesStore.isUploadFinished,
  cleanUploadQueue: filesStore.cleanUploadQueue,
}))(observer(CollectionFMPDialog))
