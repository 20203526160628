import { inject, observer } from 'mobx-react'
import TopBar from './TopBar'
import './mobile.scss'

export default inject(
  ({ authenticationStore, filesStore, orgsWorkspacesStore }) => ({
    logout: authenticationStore.logout,
    selectedExt: filesStore.selectedExt,
    getFilesFiltered: filesStore.getFilesFiltered,
    org: orgsWorkspacesStore.org,
    isAllowed: orgsWorkspacesStore.isAllowed,
    isUpload: filesStore.isUpload,
    closeUpload: filesStore.closeUpload,
    organization: orgsWorkspacesStore.organization,
    organizations: orgsWorkspacesStore.organizations,
    setOrg: orgsWorkspacesStore.setCurrentOrganization,
    changeOrganization: orgsWorkspacesStore.changeOrganization,
    orgName: orgsWorkspacesStore.orgName,
    orgType: orgsWorkspacesStore.orgType,
    workspace: orgsWorkspacesStore.workspace,
    workspaces: orgsWorkspacesStore.workspaces,
    workspaceId: orgsWorkspacesStore.workspaceId,
    workspaceType: orgsWorkspacesStore.workspaceType,
    isAnonymous: authenticationStore.isAnonymous,
    updateWorkspace: orgsWorkspacesStore.updateWorkspace,
    getFilesByIds: filesStore.getFilesByIds,
    planResolvedPercent: orgsWorkspacesStore.planResolvedPercent,
    isJustCollectionUser: orgsWorkspacesStore.isJustCollectionUser,
    orgPlan: orgsWorkspacesStore.orgPlan,
    logo: orgsWorkspacesStore.logo,
    featureFlags: orgsWorkspacesStore.featureFlags,
  })
)(observer(TopBar))
