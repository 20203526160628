import { inject, observer } from 'mobx-react'
import NewVersionUpload from './NewVersionUpload'

export default inject(({ filesStore }) => ({
  uploadToS3: filesStore.uploadToS3,
  filesInQ: filesStore.filesInQ,
  analysisQIndex: filesStore.analysisQIndex,
  recentlyUploadedFilesIds: filesStore.recentlyUploadedFilesIds,
  isUpload: filesStore.isUpload,
  mergeVersion: filesStore.mergeVersion,
  closeUpload: filesStore.closeUpload,
  deleteFile: filesStore.deleteFile,
}))(observer(NewVersionUpload))
