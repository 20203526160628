import { inject, observer } from 'mobx-react'
import NavBar from './NavBar'
import './navbar.scss'

export default inject(
  ({ filesStore, orgsWorkspacesStore, authenticationStore }) => ({
    org: orgsWorkspacesStore.org,
    isAllowed: orgsWorkspacesStore.isAllowed,
    organization: orgsWorkspacesStore.organization,
    orgName: orgsWorkspacesStore.orgName,
    orgType: orgsWorkspacesStore.orgType,
    workspace: orgsWorkspacesStore.workspace,
    workspaces: orgsWorkspacesStore.workspaces,
    workspaceId: orgsWorkspacesStore.workspaceId,
    workspaceType: orgsWorkspacesStore.workspaceType,
    workspaceName: orgsWorkspacesStore.workspaceName,
    changeWorkspace: orgsWorkspacesStore.changeWorkspace,
    updateWorkspace: orgsWorkspacesStore.updateWorkspace,
    updateWorkspaceName: orgsWorkspacesStore.updateWorkspaceName,
    onReset: filesStore.onReset,
    onIsFavoriteChanged: filesStore.onIsFavoriteChanged,
    selectedTags: filesStore.selectedTags,
    isFavorite: filesStore.isFavorite,
    isJustCollectionUser: orgsWorkspacesStore.isJustCollectionUser,
    isAnonymous: authenticationStore.isAnonymous,
    collections: filesStore.collections,
    orgPlan: orgsWorkspacesStore.orgPlan,
    userRole: orgsWorkspacesStore.role,
  })
)(observer(NavBar))
