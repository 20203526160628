import { inject, observer } from 'mobx-react'
import SearchByType from './SearchByType'
import './SearchByType.scss'

export default inject(({ filesStore, orgsWorkspacesStore }) => ({
  exts: filesStore.exts,
  selectedExt: filesStore.selectedExt,
  onExtSelected: filesStore.onExtSelected,
  selectedFileType: filesStore.selectedFileType,
  onFileTypeSelected: filesStore.onFileTypeSelected,
  selectedDuration: filesStore.selectedDuration,
  onDurationSelected: filesStore.onDurationSelected,
  selectedFileSize: filesStore.selectedFileSize,
  onFileSizeSelected: filesStore.onFileSizeSelected,
  selectedOrientationMode: filesStore.selectedOrientationMode,
  onOrientationModeSelected: filesStore.onOrientationModeSelected,
  selectedResolution: filesStore.selectedResolution,
  onResolutionSelected: filesStore.onResolutionSelected,
  onUnTaggedChanged: filesStore.onUnTaggedChanged,
  unTagged: filesStore.unTagged,
}))(observer(SearchByType))
