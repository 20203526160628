import { inject, observer } from 'mobx-react'
import TagsManagment from './TagsManagment'
import './TagsManagment.scss'

export default inject(({ filesStore, orgsWorkspacesStore }) => ({
  getAllTags: filesStore.getAllTags,
  DeleteTags: filesStore.DeleteTags,
  renameTag: filesStore.renameTag,
  toggleTagCategory: filesStore.toggleTagCategory,
  addTagToCategoryById: filesStore.addTagToCategoryById,
  categories: filesStore.categories,
  renameCategory: filesStore.renameCategory,
  createCategory: filesStore.createCategory,
  deleteCategory: filesStore.deleteCategory,
  addOrgTag: filesStore.addOrgTag,
  reorderCategory: filesStore.reorderCategory,
  resortCategory: filesStore.resortCategory,
}))(observer(TagsManagment))
