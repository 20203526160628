import React, { useState, useEffect } from 'react'
import Dialog from '@material-ui/core/Dialog'
import './upload-wizard.scss'
import IconButton from '@material-ui/core/IconButton'
import CloseIcon from '@material-ui/icons/Close'
import Upload from './Upload'
import UploadTagging from './UploadTagging'
import ArrowForwardIcon from '@material-ui/icons/ArrowForward'
import CircularProgress from '@mui/material/CircularProgress'
import success from '../../assets/success-check-circled.svg'
import { MyTabs, MyTab, MyButton } from '../_MyComponents'
import ActionDialog from '../ActionDialog'
import { useDropzone } from 'react-dropzone'
import { useHistory, useLocation } from 'react-router-dom'

const UploadWizard = ({
  // from store
  filesInQ,
  isUpload,
  closeUpload,
  getAllTags,
  getAllPersons,
  uploadQIndex,
  analysisQIndex,
  isUploadFinished,
  bulkAddCollections,
  recentlyUploadedFilesIds,
  updateFilters,
  getAllFilesUsers,
  getAllFilesExtensions,
  getOrgLimits,
  uploadToS3,
  bulkTag,

  // from parent
  type,
  isOpen,
  setDialog,
  currentCollection,
}) => {
  const [menuState, setMenuState] = useState(0)
  const [showCloseDialog, setShowCloseDialog] = useState(false)
  const [appliedTags, setAppliedTags] = useState([])
  const location = useLocation()
  const historyDOM = useHistory()

  useEffect(() => {
    // add uploader query param
    const queryParams = new URLSearchParams(location.search)
    if (!queryParams.has('uploader')) {
      queryParams.append('uploader', 'open')
      historyDOM.replace({
        search: queryParams.toString(),
      })
    }
  }, [])

  // if files are being uploaded, show tagging screen, else show empty upload screen
  useEffect(() => {
    if (filesInQ.length) {
      setMenuState(1)
    } else {
      setMenuState(0)
    }
  }, [filesInQ.length])

  const onDrop = (acceptedFiles) => {
    uploadToS3(acceptedFiles)
    setMenuState(1)
  }

  const { getRootProps, getInputProps, isDragAccept } = useDropzone({ onDrop })

  const handleClose = async () => {
    // remove uploader query param
    const queryParams = new URLSearchParams(location.search)
    if (queryParams.has('uploader')) {
      queryParams.delete('uploader')
      historyDOM.replace({
        search: queryParams.toString(),
      })
    }

    closeUpload()
    setDialog(false)

    if (!!currentCollection) {
      await bulkAddCollections({
        fileIds: recentlyUploadedFilesIds,
        collectionsIds: [currentCollection._id],
      })
    }

    document.querySelector('.files-container').scrollTop = 0
    updateFilters()
    getAllTags({})
    getAllFilesUsers({})
    getAllFilesExtensions({})
    getAllPersons({})
    getOrgLimits()
  }

  const tagAllFiles = () => {
    let fileIds = recentlyUploadedFilesIds
    bulkTag({ fileIds: fileIds, tags: appliedTags, clearExisting: false })
  }

  const nextStep = async () => {
    switch (menuState) {
      case 0:
        await getAllTags({})
        setMenuState(menuState + 1)
        break
      case 1:
        handleClose()
        tagAllFiles()
        break
      default:
        break
    }
  }

  return (
    <Dialog
      onClose={handleClose}
      fullScreen
      //maxWidth={"lg"}
      aria-labelledby="simple-dialog-title"
      open={!!isOpen}
      className="upload-wizard-dialog"
    >
      <div className="wizard-header">
        <MyTabs indicatorColor="primary" variant="scrollable" value={menuState}>
          <MyTab label="1. Upload" /* onClick={() => setMenuState(0)} */ />
          <MyTab label="2. Tag" /* onClick={() => setMenuState(1)} */ />
        </MyTabs>
        <IconButton
          className="close-wizard"
          onClick={() => setShowCloseDialog(true)}
          component="span"
        >
          <CloseIcon />
        </IconButton>
      </div>

      <div
        className="wizard-container"
        {...getRootProps({ onClick: (event) => event.stopPropagation() })}
      >
        <input {...getInputProps()} />
        <div className="wizard-content-container">
          {menuState === 0 && (
            <Upload type={type} setMenuState={setMenuState} />
          )}
          {menuState > 0 && (
            <UploadTagging
              appliedTags={appliedTags}
              setAppliedTags={setAppliedTags}
            />
          )}
        </div>
        <div className="button-container">
          {!!filesInQ.length && (
            <div className="upload-indicator">
              {!isUpload &&
                !!filesInQ.filter(
                  (file) => file.status === 'complete' && !file.failed
                ).length && <img src={success} />}
              {isUpload && (
                <CircularProgress style={{ color: '#FBC02D' }} size={20} />
              )}
              {!isUpload &&
                (!!filesInQ.filter(
                  (file) =>
                    (file.status === 'complete' ||
                      file.status === 'duplicate') &&
                    !file.failed
                ).length ? (
                  <p className="p">{`Successfuly uploaded and analyzed ${
                    filesInQ.filter(
                      (file) =>
                        (file.status === 'complete' ||
                          file.status === 'duplicate') &&
                        !file.failed
                    ).length
                  } of ${filesInQ.length} files`}</p>
                ) : (
                  <p className="p">{`Failed uploading all ${filesInQ.length} files`}</p>
                ))}
              {isUpload &&
                (isUploadFinished ? (
                  <p className="p">{`analyzing ${analysisQIndex}/${filesInQ.length} files`}</p>
                ) : (
                  <p className="p">{`Uploading ${uploadQIndex}/${filesInQ.length} files`}</p>
                ))}
            </div>
          )}
          <MyButton
            endIcon={<ArrowForwardIcon />}
            variant="contained"
            color="primary"
            onClick={() => nextStep()}
            disabled={filesInQ.length < 1 || !isUploadFinished}
          >
            {menuState === 1 ? 'Finish upload' : 'Continue'}
          </MyButton>
        </div>
      </div>
      <ActionDialog
        actionName={'Cancel'}
        cancelName={'Close uploader'}
        cancel={() => handleClose()}
        action={() => setShowCloseDialog(false)}
        question={`Close Upload?`}
        comments={[
          'Closing the uploader might prevent some files from completing the upload process.',
          'We recommend completing the upload using the button at the bottom of the screen.',
        ]}
        //color={'primary'}
        isOpen={showCloseDialog}
      />
    </Dialog>
  )
}

export default UploadWizard
