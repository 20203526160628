import React, { useCallback, useEffect, useState, useMemo } from 'react'
import { makeStyles } from '@material-ui/core/styles'
import AppBar from '@material-ui/core/AppBar'
import Toolbar from '@material-ui/core/Toolbar'
import Button from '@material-ui/core/Button'
import Avatar from '@material-ui/core/Avatar'
import AccountCircleIcon from '@mui/icons-material/AccountCircle'
import Menu from '@material-ui/core/Menu'
import MenuItem from '@material-ui/core/MenuItem'
import Snackbar from '@material-ui/core/Snackbar'
import { RULES } from '../../store/OrgsWorkspacesStore/permissions'
import Alert from '@material-ui/lab/Alert'

import IconButton from '@material-ui/core/IconButton'
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown'
import './style.scss'
import CreateBookmark from '../CreateBookmark'
import ButtonGroup from '@material-ui/core/ButtonGroup'
import Popper from '@material-ui/core/Popper'
import Grow from '@mui/material/Grow'
import Paper from '@material-ui/core/Paper'
import ClickAwayListener from '@material-ui/core/ClickAwayListener'
import MenuList from '@material-ui/core/MenuList'
import Fab from '@material-ui/core/Fab'
import AddIcon from '@material-ui/icons/Add'
import MenuIcon from '@mui/icons-material/Menu'
import { MyButton } from '../_MyComponents'
import Divider from '@mui/material/Divider'

import ShareDialog from './ShareDialog'
import OmniSearch from '../OmniSearch'

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 0,
    zIndex: 99,
    width: '100%',
  },
}))

const TopBar = ({
  logout,
  settingsDialog,
  cleanupDialog,
  disable, // used only in create organization view - should refactor out
  organization,
  organizations,
  changeOrganization,
  isAllowed,
  getFilesFiltered,
  openNavbar,
  orgName,
  orgType,
  workspaceId,
  workspaceType,
  reset,
  isAnonymous,

  updateWorkspace,
  isBookmarkOpen,
  openBookmark,
  closeBookmark,
  setSelectedList,
  setShowBulkEditDialog,
  setType,
  uploadDialog,
  isJustCollectionUser,
  importDialog,

  //upload snackbar
  isUpload,
  getFilesByIds,
  closeUpload,
  previewDialog,

  //plan
  planResolvedPercent,
  setIsPlanPrompt,
  orgPlan,
  logo,
  featureFlags,
}) => {
  const classes = useStyles()
  const [anchorEl, setAnchorEl] = React.useState(null)
  //const [snackbarOpen, setSnackbarOpen] = React.useState(false);
  const [uploadError, setUploadError] = React.useState()
  //const [fileInQ, setFileInQ] = React.useState(0);
  //const [filterSidebar, setFilterSidebar] = React.useState(false);
  const [recentlyUploadedFiles, setRecentlyUploadedFiles] = useState(false)

  const [showShareDialog, setShowShareDialog] = useState(false)

  /*
  useEffect(() => {
    const imageList = [israidLogo]
    imageList.forEach((image) => {
      new Image().src = image
    })
  }, [])
  */

  const onLoad = useCallback((event) => {
    event.preventDefault()
    event.returnValue = ''
  }, [])

  useEffect(() => {
    if (isUpload) {
      window.addEventListener('beforeunload', onLoad)
    } else {
      window.removeEventListener('beforeunload', onLoad)
    }
    return () => window.removeEventListener('beforeunload', onLoad)
  }, [isUpload, onLoad])

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget)
  }

  const handleClose = () => {
    setAnchorEl(null)
  }

  const isLnet = orgType === 'lnet'

  const openSettings = () => {
    settingsDialog('workspace')
    setAnchorEl(null)
  }

  const openCleanup = () => {
    cleanupDialog(true)
    setAnchorEl(null)
  }

  const openShare = () => {
    setShowShareDialog(true)
    setAnchorEl(null)
  }

  function goToHelpCenter() {
    window.open(
      'https://tagbox.notion.site/Tagbox-s-Help-Center-ad93580eb41d4b7dabe45bdf706cc397',
      '_blank'
    )
  }

  function submitFeedback() {
    window.open('https://airtable.com/shryZsFxMBBRTV89z', '_blank')
  }

  const handleLogout = () => {
    isAnonymous
      ? window.open(
          `https://tagbox.io?utm_source=in_app&utm_medium=public_view&utm_campaign=${orgName}`,
          '_blank'
        )
      : logout()
  }

  const bulkTag = async () => {
    const fullFiles = await getFilesByIds(recentlyUploadedFiles)
    setSelectedList(fullFiles)
    setShowBulkEditDialog(true)
    setType('REGULAR')
  }

  const closeUploadSnack = () => {
    closeUpload()
    setSelectedList([])
  }

  const orgs = organizations.map((org, idx) => {
    return (
      <MenuItem
        disabled={organization._id == org.orgId._id}
        selected={organization._id == org.orgId._id}
        key={idx}
        onClick={() => {
          setAnchorEl(null)
          changeOrganization({ org: org.orgId })
        }}
      >
        <AccountCircleIcon className="menu-icon" fontSize="small" />
        {org.orgId.name}
      </MenuItem>
    )
  })

  const nonSelectedOrgs = organizations.filter(
    (org) => organization._id != org.orgId._id
  )

  return (
    <div className={classes.root + ' top-bar'}>
      <ShareDialog
        open={showShareDialog}
        setOpen={setShowShareDialog}
        currentWorkspaceType={workspaceType}
        updateWorkspace={updateWorkspace}
      ></ShareDialog>
      <Snackbar
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'right',
        }}
        onClose={() => setUploadError()}
        open={!!uploadError}
        autoHideDuration={6000}
      >
        <Alert severity="error">{uploadError}</Alert>
      </Snackbar>

      {/* actual content */}
      <AppBar position="static">
        <Toolbar className={'toolbar' + ' header-menu'}>
          <div className="topbar-left">
            {!window.mobileCheck() && (
              <span
                className="flex-row"
                style={{ cursor: 'pointer' }}
                onClick={() => {
                  if (!isJustCollectionUser()) {
                    reset()
                  }
                }}
              >
                {!!organization && logo && (
                  <img className="logo" src={logo} alt="logo" />
                )}
              </span>
            )}
            {window.mobileCheck() &&
              !isJustCollectionUser() &&
              !isAnonymous && (
                <IconButton
                  className="filter-icon-mobile"
                  aria-label="close"
                  color="inherit"
                  onClick={() => openNavbar()}
                >
                  <MenuIcon fontSize="inherit" />
                </IconButton>
              )}
          </div>
          <div className="topbar-middle">
            {!disable && (
              <OmniSearch
                previewDialog={previewDialog} //from Home
              />
            )}
          </div>
          <div className="topbar-right">
            {!isAnonymous ? (
              <Avatar
                className={'settings'}
                aria-controls="top-bar-menu"
                aria-haspopup="true"
                onClick={handleClick}
              >
                <AccountCircleIcon
                  style={{
                    height: '100%',
                    width: '100%',
                    backgroundColor: 'white',
                    color: '#212121',
                  }}
                />
              </Avatar>
            ) : (
              !window.mobileCheck() &&
              isAnonymous && (
                <Button
                  color="primary"
                  style={{ textTransform: 'none', width: 'max-content' }}
                  onClick={handleLogout}
                  fullWidth
                  variant="contained"
                >
                  Try Tagbox Free
                </Button>
              )
            )}
            {!disable && isAllowed(RULES.UPLOAD_DOCUMENTS) && (
              <SplitButton
                mobile={window.mobileCheck()}
                onClick={handleClose}
                openBookmark={openBookmark}
                getFilesFiltered={getFilesFiltered}
                aria-controls="upload-menu"
                workspaceId={workspaceId}
                uploadDialog={uploadDialog}
                planResolvedPercent={planResolvedPercent}
                setIsPlanPrompt={setIsPlanPrompt}
                orgPlan={orgPlan}
                importDialog={importDialog}
                featureFlags={featureFlags}
              />
            )}
            {isBookmarkOpen && (
              <CreateBookmark
                id="upload-menu"
                open={isBookmarkOpen}
                closeBookmark={closeBookmark}
              />
            )}
          </div>
          <Menu
            id="top-bar-menu"
            anchorEl={anchorEl}
            keepMounted
            open={Boolean(anchorEl)}
            onClose={handleClose}
            PaperProps={{
              style: {
                boxShadow: '0px 2px 10px rgba(0, 0, 0, 0.25)',
              },
            }}
          >
            {!disable &&
              isAllowed(RULES.ADDING_USERS, RULES.REMOVING_USERS) && (
                <MenuItem onClick={openSettings}>Settings</MenuItem>
              )}
            {!disable && isAllowed(RULES.SHARE_WORKSPACE) && (
              <MenuItem onClick={openShare}>Share</MenuItem>
            )}
            {/* {!disable && isAllowed(RULES.SMART_ORGANIZER) && (
              <MenuItem onClick={openCleanup}>Tag Cleanup</MenuItem>
            )} */}
            <MenuItem onClick={goToHelpCenter}>Help Center</MenuItem>
            <MenuItem onClick={submitFeedback}>Submit feedback</MenuItem>
            {nonSelectedOrgs.length > 0 && (
              <div>
                <Divider className="divider" />
                <MenuItem disabled>
                  <b>My accounts</b>
                </MenuItem>
                {orgs}
                {/*<MenuItem onClick={submitFeedback}><AddCircleOutlineIcon className="menu-icon" fontSize="small"/><b>Add account</b></MenuItem>*/}
                <Divider className="divider" />
              </div>
            )}

            {!isLnet && (
              <MenuItem onClick={handleLogout}>
                {isAnonymous ? 'Login' : 'Logout'}
              </MenuItem>
            )}
          </Menu>
        </Toolbar>
      </AppBar>
    </div>
  )
}

export default TopBar

export function SplitButton(props) {
  const {
    openBookmark,
    mobile,
    uploadDialog,
    planResolvedPercent,
    setIsPlanPrompt,
    orgPlan,
    featureFlags,
  } = props
  const [open, setOpen] = React.useState(false)
  const anchorRef = React.useRef(null)

  const handleToggle = () => {
    setOpen((prevOpen) => !prevOpen)
  }

  const handleClose = (event) => {
    if (anchorRef.current && anchorRef.current.contains(event.target)) {
      return
    }
    setOpen(false)
  }

  const handleUploadClick = (type) => {
    if (planResolvedPercent * 100 < 100 || orgPlan !== 'free') {
      uploadDialog(type)
    } else {
      setIsPlanPrompt(true)
    }
  }

  return (
    <div className="uploadButton">
      {mobile ? (
        <Fab
          color="primary"
          ref={anchorRef}
          onClick={handleToggle}
          aria-label="split button"
          id="upload-button"
          className="mobile-upload-button"
        >
          <AddIcon />
        </Fab>
      ) : (
        <ButtonGroup
          variant="text"
          id="upload-button"
          disableElevation
          ref={anchorRef}
          aria-label="split button"
        >
          <MyButton
            onClick={handleUploadClick}
            variant="contained"
            color="primary"
          >
            <label>Upload</label>
          </MyButton>
          <MyButton
            color="primary"
            variant="contained"
            className="arrow-menu"
            size="small"
            aria-controls={open ? 'split-button-menu' : undefined}
            aria-expanded={open ? 'true' : undefined}
            aria-label="select merge strategy"
            aria-haspopup="menu"
            onClick={handleToggle}
          >
            <KeyboardArrowDownIcon />
          </MyButton>
        </ButtonGroup>
      )}
      <Popper
        open={open}
        anchorEl={anchorRef.current}
        role={undefined}
        transition
        disablePortal
      >
        {({ TransitionProps, placement }) => (
          <Grow
            {...TransitionProps}
            style={{
              transformOrigin:
                placement === 'bottom' ? 'center top' : 'center bottom',
            }}
          >
            <Paper>
              <ClickAwayListener onClickAway={handleClose}>
                <MenuList id="split-button-menu">
                  <MenuItem onClick={() => handleUploadClick('files')}>
                    Upload Files
                  </MenuItem>
                  <MenuItem onClick={() => handleUploadClick('folders')}>
                    Upload Folders
                  </MenuItem>
                  <MenuItem onClick={openBookmark}>Add Link</MenuItem>
                  {featureFlags?.import?.enabled && (
                    <MenuItem onClick={() => props.importDialog(true)}>
                      Import Files
                    </MenuItem>
                  )}
                </MenuList>
              </ClickAwayListener>
            </Paper>
          </Grow>
        )}
      </Popper>
    </div>
  )
}
