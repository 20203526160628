import React, { useState, useEffect } from 'react'
import Dialog from '@material-ui/core/Dialog'
import Button from '@material-ui/core/Button'
import DialogActions from '@material-ui/core/DialogActions'
import DialogContent from '@material-ui/core/DialogContent'
import DialogContentText from '@material-ui/core/DialogContentText'
import DialogTitle from '@material-ui/core/DialogTitle'
import TextField from '@material-ui/core/TextField'
import Switch from '@material-ui/core/Switch'
import { MyButton, MyKeyword } from '../_MyComponents'
import './createCollectionDialog.scss'
import MenuItem from '@material-ui/core/MenuItem'
import Checkbox from '@mui/material/Checkbox'
import TagList from '../TagList'
import Menu from '@material-ui/core/Menu'
import KeyboardArrowDownIcon from '@material-ui/icons/KeyboardArrowDown'
import MAGIC from '../../assets/auto-collection-icon.svg'

const CreateCollectionDialog = ({
  createCollection,
  editCollection,
  getCurrentCollection,
  getAllCollections,
  createMode = true,
  collection,
  isOpen,
  close,
  allTags,
  allPersons,
  updateCollectionType,
  updateCollectionRules,
  collectionsRulesToSimpleTags,
  runCollectionRules,
  isMobile,
}) => {
  const [collectionName, setCollectionName] = useState('')
  const [collectionDescription, setCollectionDescription] = useState('')
  const [buttonEnabled, setButtonEnabled] = useState(false)
  const [automationToggle, setAutomationToggle] = useState(false)
  const [automationTags, setAutomationTags] = useState([])
  const [automationPersons, setAutomationPersons] = useState([])
  const [automationRetro, setAutomationRetro] = useState(true)
  const [anyOrAll, setAnyOrAll] = useState('All')
  const [anchor, setAnchor] = useState(null) // tagList
  const [ddAnchor, setDdAnchor] = useState(null) // all/any dropdown

  useEffect(() => {
    if (!createMode && !!collection) {
      setCollectionName(collection?.name)
      setCollectionDescription(collection?.description)
      setAutomationToggle(collection?.type === 'automated')
      setAutomationRetro(false)
      if (collection?.type === 'automated') {
        let { tags, persons, anyOrAll } =
          collectionsRulesToSimpleTags(collection)
        setAnyOrAll(anyOrAll === 'any' ? 'Any' : 'All')
        setAutomationTags(tags)
        setAutomationPersons(persons)
      } else {
        setAnyOrAll('All')
        setAutomationTags([])
        setAutomationPersons([])
      }
    } else {
      setCollectionName('')
      setCollectionDescription('')
      setAutomationToggle(false)
      setAnyOrAll('All')
      setAutomationTags([])
      setAutomationPersons([])
      setAutomationRetro(true)
    }
    setButtonEnabled(false)
  }, [collection, createMode])

  // show tags and persons selected
  const both = automationTags.concat(
    automationPersons.map((person) => {
      return { ...person, type: 'REGULAR', isPerson: true }
    })
  )

  // send tags and persons to taglist
  const allBoth = allTags.concat(
    allPersons.map((person) => {
      return { ...person, type: 'REGULAR', isPerson: true }
    })
  )

  const handleChangeName = (e) => {
    setCollectionName(e.target.value)
    if (collectionName) {
      setButtonEnabled(true)
    } else {
      setButtonEnabled(false)
    }
  }

  const handleChangeDescription = (e) => {
    setCollectionDescription(e.target.value)
    if (!createMode) {
      setButtonEnabled(true)
    }
  }

  const createCollectionAction = async () => {
    var newCollection = await createCollection({
      name: collectionName,
      description: collectionDescription,
    })
    if (automationToggle) {
      await updateCollectionType({ id: newCollection._id, type: 'automated' })
      const tagIds = automationTags.map((tag) => tag._id)
      const personsIds = automationPersons.map((person) => person._id)
      await updateCollectionRules({
        id: newCollection._id,
        tagIds,
        personsIds,
        anyOrAll,
      })
      if (automationRetro) {
        await runCollectionRules({ id: newCollection._id })
      }
    }
    getAllCollections({})
    setAutomationToggle(false)
    setAnyOrAll('All')
    setAutomationTags([])
    setAutomationPersons([])
    setAutomationRetro(true)
    close()
  }

  const updateCollectionAction = async () => {
    await editCollection({
      id: collection._id,
      name: collectionName,
      description: collectionDescription,
    })

    if (automationToggle) {
      await updateCollectionType({ id: collection?._id, type: 'automated' })
      const tagIds = automationTags.map((tag) => tag._id)
      const personsIds = automationPersons.map((person) => person._id)
      await updateCollectionRules({
        id: collection?._id,
        tagIds,
        personsIds,
        anyOrAll,
      })
      if (automationRetro) {
        await runCollectionRules({ id: collection._id })
      }
    } else {
      await updateCollectionType({ id: collection?._id, type: 'regular' })
    }

    getCurrentCollection(collection._id)
    setAutomationToggle(false)
    setAnyOrAll('All')
    setAutomationTags([])
    setAutomationPersons([])
    setAutomationRetro(true)
    close()
  }

  const onClickApproveCollection = async () => {
    if (createMode) {
      createCollectionAction()
    } else {
      updateCollectionAction()
    }
  }

  //////////////////////////////////////
  /////////  COLLECTION AUTOMATIONS
  //////////////////////////////////////

  const automationTagsItems = both.map((tag, idx) => {
    return (
      <>
        <MyKeyword
          text={tag?.name}
          avatar={tag?.defaultFaceLocation}
          onClick={() => removeTagFromAutomation(tag)}
        />
        {idx !== both.length - 1 && (
          <p className="p connector">{anyOrAll === 'All' ? 'and' : 'or'}</p>
        )}
      </>
    )
  })

  const openAnyOrAll = (e) => {
    setDdAnchor(e.currentTarget)
  }

  const closeAnyOrAll = () => {
    setDdAnchor(null)
  }

  const changeAnyOrAll = (val) => {
    setAnyOrAll(val)
    closeAnyOrAll()
    setButtonEnabled(true)
  }

  const toggleAutomation = () => {
    setAutomationToggle((prev) => !prev)
    setButtonEnabled(true)
  }

  const toggleRetro = () => {
    setAutomationRetro((prev) => !prev)
    setButtonEnabled(true)
  }

  const automationSetup = (
    <div className="automation-container">
      <div className="flex-row automation-toggle-section">
        <div className="flex-row">
          <Switch
            //className={"share-toggle"}
            checked={automationToggle}
            color="primary"
            onChange={toggleAutomation}
          />
          <p className="p">Add assets automatically</p>
        </div>
        <img src={MAGIC} alt="share icon" />
      </div>
      {!!automationToggle && (
        <div className="automation-details-section">
          <p className="p light">
            New assets with these tags or people will be automatically added to
            this collection.
          </p>
          <div className="flex-row">
            <p className="p light">Add assets with</p>
            <MyButton
              onClick={(e) => openAnyOrAll(e)}
              variant="contained"
              color="secondary"
              sx={{ alignSelf: 'flex-start' }}
              className="menu-button"
              size="small"
            >
              {anyOrAll}
              <KeyboardArrowDownIcon fontSize="inherit" />
            </MyButton>
            <p className="p light">of the following tags or people</p>
          </div>
          <div className="automation-tags-container">
            <div className="automation-tags">{automationTagsItems}</div>
            <div
              className="add-tag-button"
              onClick={() => {
                setAnchor(document.querySelector('.automation-tags-container'))
              }}
            >
              + Add a tag or person
            </div>
          </div>
          <div className="flex-row">
            <Checkbox
              checked={automationRetro}
              onChange={() => toggleRetro()}
              inputProps={{ 'aria-label': 'controlled' }}
            />
            <p className="p light">Also add existing assets</p>
          </div>
        </div>
      )}
    </div>
  )

  const addTagToAutomation = (info) => {
    var tags = info.tags.filter((tag) => !tag.isPerson)
    var persons = info.tags.filter((tag) => !!tag.isPerson)

    setAutomationTags(tags)
    setAutomationPersons(persons)
    setButtonEnabled(true)
  }

  const removeTagFromAutomation = (tagOrPerson) => {
    var tag = !tagOrPerson.isPerson ? tagOrPerson : {}
    var person = !!tagOrPerson.isPerson ? tagOrPerson : {}

    if (Object.keys(tag).length > 0) {
      const tempTags = automationTags.filter((t) => {
        return t._id !== tag._id
      })
      setAutomationTags(tempTags)
    }

    if (Object.keys(person).length > 0) {
      const tempPersons = automationPersons.filter((p) => {
        return p._id !== person._id
      })
      setAutomationPersons(tempPersons)
    }

    setButtonEnabled(true)
  }

  const tagPopup = (
    <TagList
      type={'AUTOMATION'}
      width={{ width: '250px' }}
      anchor={anchor}
      onClose={() => setAnchor(null)}
      allTags={allBoth}
      tags={both}
      onAddTag={addTagToAutomation}
      onRemoveTag={removeTagFromAutomation}
      /* need to change */
      fileId={'000'}
    />
  )

  const anyOrAllPopup = (
    <Menu
      id="and-or-menu"
      className="dropdown"
      anchorEl={ddAnchor}
      keepMounted
      open={Boolean(ddAnchor)}
      onClose={closeAnyOrAll}
      disableRestoreFocus={true}
    >
      <MenuItem onClick={() => changeAnyOrAll('All')}>All</MenuItem>
      <MenuItem onClick={() => changeAnyOrAll('Any')}>Any</MenuItem>
    </Menu>
  )

  return (
    <Dialog
      onClose={close}
      fullWidth={isMobile ? false : true}
      maxWidth={'sm'}
      aria-labelledby="simple-dialog-title"
      open={isOpen}
    >
      <DialogTitle id="form-dialog-title">
        {createMode ? 'Create Collection' : 'Edit Collection'}
      </DialogTitle>
      <DialogContent className="dialog-content">
        <div className="dialog-question">
          <DialogContentText>Collection name</DialogContentText>
          <TextField
            autoFocus
            variant="outlined"
            onChange={handleChangeName}
            size="small"
            defaultValue={createMode ? '' : collection?.name}
            //placeholder={createMode ? "" : collection?.name}
            fullWidth
          />
        </div>
        <div className="dialog-question">
          <DialogContentText>Description (optional)</DialogContentText>
          <TextField
            multiline
            minRows={4}
            variant="outlined"
            onChange={handleChangeDescription}
            size="small"
            defaultValue={createMode ? '' : collection?.description}
            //placeholder={createMode ? "" : collection?.description}
            fullWidth
          />
        </div>
        {automationSetup}
      </DialogContent>
      <DialogActions>
        <Button
          onClick={close}
          variant="contained"
          //color="grey"
        >
          Cancel
        </Button>
        <Button
          onClick={onClickApproveCollection}
          color="primary"
          variant="contained"
          disabled={
            !buttonEnabled ||
            collectionName.length === 0 ||
            (automationToggle && both.length === 0)
          }
        >
          {createMode ? 'Create Collection' : 'Edit Collection'}
        </Button>
      </DialogActions>
      {tagPopup}
      {anyOrAllPopup}
    </Dialog>
  )
}

export default CreateCollectionDialog
