import { inject, observer } from 'mobx-react'
import OmniSearch from './OmniSearch'
import { searchCollections } from '../../services/apis/collections'
import files from '../../services/apis/files'

export default inject(
  ({ authenticationStore, filesStore, orgsWorkspacesStore }) => ({
    onSearchChanged: filesStore.onSearchChanged,
    preSearchResults: filesStore.preSearchResults,
    textToPreSearch: filesStore.textToPreSearch,
    onTagSelected: filesStore.onTagSelected,
    onPersonSelected: filesStore.onPersonSelected,
    selectedUsers: filesStore.selectedUsers,
    onUserSelected: filesStore.onUserSelected,
    addToFileViewed: filesStore.addToFileViewed,
    selectedExt: filesStore.selectedExt,
    onExtSelected: filesStore.onExtSelected,
    selectedFileType: filesStore.selectedFileType,
    getCurrentCollection: filesStore.getCurrentCollection,
    currentCollection: filesStore.currentCollection,
    onPreSearchChanged: filesStore.onPreSearchChanged,
    view: filesStore.mainView,
    setView: filesStore.setMainView,
    isAnonymous: authenticationStore.isAnonymous,
    workspaceType: orgsWorkspacesStore.workspaceType,
    isAllowed: orgsWorkspacesStore.isAllowed,
    searchCollections: filesStore.searchCollections,
  })
)(observer(OmniSearch))
