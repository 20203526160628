import CreateBookmark from './CreateBookmark'
import { inject, observer } from 'mobx-react'

export default inject(
  ({ filesStore, orgsWorkspacesStore, authenticationStore }) => ({
    createBookmark: filesStore.createBookmark,
    orgId: orgsWorkspacesStore.orgId,
    workspaceId: orgsWorkspacesStore.workspaceId,
    username: authenticationStore.username,
    getFilesFiltered: filesStore.getFilesFiltered,
    onReset: filesStore.onReset,
  })
)(observer(CreateBookmark))
