import { inject, observer } from 'mobx-react'
import Register from './Register'

export default inject(({ registerStore, authenticationStore }) => ({
  doesUserRegistered: registerStore.doesUserRegistered,
  username: registerStore.username,
  fullname: registerStore.fullname,
  password: registerStore.password,
  displayName: registerStore.displayName,
  userInfo: registerStore.userInfo,
  confirmPassword: registerStore.confirmPassword,
  message: registerStore.message,
  usernameError: registerStore.usernameError,
  confirmPasswordError: registerStore.confirmPasswordError,
  registerOnProcess: registerStore.registerOnProcess,
  showPassword: registerStore.showPassword,
  userDetails: registerStore.userDetails,
  credentialChange: registerStore.credentialChange,
  onSignUp: registerStore.onSignUp,
  toggleShowPassword: registerStore.toggleShowPassword,
  passwordErrorMessage: registerStore.passwordErrorMessage,
  confirmPasswordErrorMessage: registerStore.confirmPasswordErrorMessage,
  usernameErrorMessage: registerStore.usernameErrorMessage,
  displayNameErrorMessage: registerStore.displayNameErrorMessage,
  emailFromInvitation: registerStore.emailFromInvitation,
  googleLogin: authenticationStore.googleLogin,
}))(observer(Register))
