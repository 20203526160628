import { inject, observer } from 'mobx-react'
import Files from './Files'
import './Files.scss'

export default inject(({ filesStore, orgsWorkspacesStore }) => ({
  addToFavorite: filesStore.addToFavorite,
  getFilesFiltered: filesStore.getFilesFiltered,
  isSearchMode: filesStore.isSearchMode,
  isCollectionMode: filesStore.isCollectionMode,
  isLoading: filesStore.isLoading,
  setSelectedList: filesStore.setSelectedList,
  FILES_IN_PAGE: filesStore.FILES_IN_PAGE,
  planResolvedPercent: orgsWorkspacesStore.planResolvedPercent,
  orgPlan: orgsWorkspacesStore.orgPlan,
  categories: filesStore.categories,
  DBFiles: filesStore.files,
  filesCount: filesStore.filesCount,
  onAddTag: filesStore.addFileTag,
  onRemoveTag: filesStore.removeFileTag,
  allTags: filesStore.allTags,
  addToFileViewed: filesStore.addToFileViewed,
  updateFileName: filesStore.updateFileName,
  fileViewMode: filesStore.fileViewMode,
  toggleFileViewMode: filesStore.toggleFileViewMode,
  isAllowed: orgsWorkspacesStore.isAllowed,
  filesInQ: filesStore.filesInQ,
}))(observer(Files))
