import React from 'react'
import Popover from '@material-ui/core/Popover'
import Button from '@material-ui/core/Button'
import './style.scss'

const CreateBookmark = (props) => {
  const {
    id,
    open,
    closeBookmark,
    createBookmark,
    orgId,
    workspaceId,
    username,
    getFilesFiltered,
    onReset,
  } = props
  const [anchorEl, setAnchorEl] = React.useState(open)
  const [urlMissing, setUrlMissing] = React.useState('')
  const [data, setData] = React.useState({ name: '', link: '' })
  const show = Boolean(anchorEl)

  const handleClose = () => {
    setAnchorEl(null)
    closeBookmark()
  }

  const handleChange = (ev) => {
    const d = { ...data }
    d[ev.target.name] = ev.target.value
    setData(d)
  }

  const saveBookmark = async () => {
    setUrlMissing('')
    if (data.link) {
      try {
        const timer = (ms) => new Promise((res) => setTimeout(res, ms))
        await createBookmark(data, orgId, username, workspaceId)
        await timer(500)
        onReset()
        closeBookmark()
      } catch (err) {
        setUrlMissing(err?.message)
      }
    } else {
      setUrlMissing('Link missing')
    }
  }

  return (
    <Popover
      id={id}
      open={show}
      anchorEl={anchorEl}
      onClose={handleClose}
      anchorOrigin={{
        vertical: 'bottom',
        horizontal: 'center',
      }}
      transformOrigin={{
        vertical: 'top',
        horizontal: 'center',
      }}
      className="test"
    >
      <div className="create-bookmark">
        <div className="label">Name</div>
        <input
          onChange={handleChange}
          defaultValue={data.name}
          name="name"
          type="text"
          placeholder="Best website for something"
        />
        <div className="link-container">
          <div className="label">Link</div>
          <input
            required
            type="url"
            onChange={handleChange}
            defaultValue={data.link}
            name="link"
            placeholder="www.website.com"
          />
          {urlMissing && <div className="error-message">{urlMissing}</div>}
        </div>
        <div className="bookmarks-buttons">
          <Button onClick={closeBookmark}>Cancel</Button>
          <Button
            onClick={saveBookmark}
            className="save"
            variant="contained"
            color="primary"
          >
            Save
          </Button>
        </div>
      </div>
    </Popover>
  )
}

export default CreateBookmark
