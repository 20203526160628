import React, { useState, useEffect } from 'react'
import { useHistory, useLocation } from 'react-router-dom'
import IconButton from '@material-ui/core/IconButton'
import ArrowBackIcon from '@mui/icons-material/ArrowBack'
import CameraAltOutlinedIcon from '@mui/icons-material/CameraAltOutlined'
import CloseIcon from '@material-ui/icons/Close'
import EditOutlinedIcon from '@mui/icons-material/EditOutlined'
import DeleteOutlineIcon from '@mui/icons-material/DeleteOutline'
//import ReplyOutlinedIcon from '@mui/icons-material/ReplyOutlined';
import SHARE from '../../assets/share-icon.svg'
import Tooltip from '@material-ui/core/Tooltip'
import CollectionShareDialog from '../CollectionShareDialog'
import CollectionFMPDialog from '../CollectionFMPDialog'
import TagsFilter from '../TagsFilter'
import BCG from '../../assets/collection-bcg.png'
import OmniSearch from '../OmniSearch'
import SimpleUpload from '../SimpleUpload'
import MoreVertIcon from '@material-ui/icons/MoreVert'
import Menu from '@material-ui/core/Menu'
import MenuItem from '@material-ui/core/MenuItem'
import { MyButton } from '../_MyComponents'
import { RULES } from '../../store/OrgsWorkspacesStore/permissions'
import LINK from '../../assets/link-icon.svg'
import DOWNLOAD from '../../assets/download-icon.svg'

function useQuery() {
  return new URLSearchParams(useLocation().search)
}

const CollectionHeader = ({
  currentCollection,
  onReset,
  onResetFilteres,
  onDelete,
  editCollection,
  isSearchMode,
  previewDialog,
  setView,
  isJustCollectionUser,
  isAllowed,
  fileCount,
  onPersonSelected,
  bulkDownload,
  files,
  getCurrentCollection,
  updateCollectionCover,
}) => {
  const [showShareDialog, setShowShareDialog] = useState(false)
  const [newCoverOpen, setNewCoverOpen] = useState(false)
  const [anchorEl, setAnchorEl] = React.useState(null)

  //FMP
  const [isFMP, setIsFMP] = useState(false) // options: false, true
  const [showingFMP, setShowingFMP] = useState(false) // options: false, true

  const [FMPAvatar, setFMPAvatar] = useState(null) // person details
  const [FMPPerson, setFMPPerson] = useState(null) // person details
  const [showFMPDialog, setShowFMPDialog] = useState(false)

  useEffect(() => {
    setIsFMP(currentCollection.isFMP)
  }, [currentCollection])

  let query = useQuery()

  useEffect(() => {
    setShowFMPDialog(query.get('openfmp') || false)
  }, [])

  //const {permissions} = currentCollection;

  const closeUploadCover = () => {
    setNewCoverOpen(false)
  }

  const location = useLocation()
  const historyDOM = useHistory()

  useEffect(() => {
    if (currentCollection) {
      const queryParams = new URLSearchParams(location.search)

      if (!queryParams.has('collectionId')) {
        console.log('Append collectionId without')
        queryParams.append('collectionId', currentCollection._id)
        historyDOM.replace({
          search: queryParams.toString(),
        })
      } else {
        console.log('Append collectionId but we have')
        queryParams.delete('collectionId')
        queryParams.append('collectionId', currentCollection._id)
        historyDOM.replace({
          search: queryParams.toString(),
        })
      }
    }
  }, [currentCollection, historyDOM, location.search])

  const handleClose = () => {
    setAnchorEl(null)
  }

  const actionButtons = (
    <div className="collection-actions">
      {isAllowed(RULES.SHARE_COLLECTION) && (
        <MyButton
          className="collection-header-button"
          variant="contained"
          color="primary"
          onClick={() => setShowShareDialog(true)}
        >
          <img src={SHARE} alt="share icon" />
          <p className="p">Share</p>
        </MyButton>
      )}
      {isAllowed(RULES.EDIT_COLLECTION) && (
        <Tooltip title={'More options'}>
          <IconButton
            className="collection-header-icon"
            aria-label="close"
            color="inherit"
            size="medium"
            onClick={(e) => setAnchorEl(e.currentTarget)}
          >
            <MoreVertIcon fontSize="inherit" />
          </IconButton>
        </Tooltip>
      )}
    </div>
  )

  const inSearchMode = (
    <div className="collection-header-wrapper">
      <div className="collection-header-top">
        <div className="title">
          <IconButton
            className="collection-header-icon"
            aria-label="close"
            color="inherit"
            size="small"
            onClick={isSearchMode() ? () => onResetFilteres() : () => onReset()}
          >
            {isSearchMode() ? (
              <CloseIcon fontSize="inherit" />
            ) : (
              <ArrowBackIcon fontSize="inherit" />
            )}
          </IconButton>
          <h1 className="h1">{currentCollection?.name}</h1>
        </div>
        {actionButtons}
      </div>
      {<TagsFilter />}
    </div>
  )

  const handleFMPInSearch = () => {
    if (FMPPerson) {
      setShowingFMP(true)
      onPersonSelected(FMPPerson)
    } else {
      setShowFMPDialog(true)
    }
  }

  const inMainMode = (
    <div className="collection-header-wrapper landing-page">
      <img
        className="bcg-image"
        alt="collection-background"
        src={currentCollection?.coverImage || BCG}
      />
      <div className="collection-header-overlay"></div>
      <div className="collection-header-top">
        <div className="title">
          {!isJustCollectionUser() && (
            <IconButton
              className="collection-header-icon"
              aria-label="close"
              color="inherit"
              size="small"
              onClick={() => onReset()}
            >
              <ArrowBackIcon fontSize="inherit" />
            </IconButton>
          )}
        </div>
        {actionButtons}
      </div>
      <div className="landing-content">
        <h1 className="h1">{currentCollection?.name}</h1>
        {!!currentCollection?.description.length && (
          <h2 className="h2 collection-desc">
            {currentCollection?.description}
          </h2>
        )}
        {!showingFMP && (
          <OmniSearch
            previewDialog={previewDialog} //from Home
            hideFilters={true}
            handleFMPInSearch={handleFMPInSearch}
            isFMP={isFMP}
          />
        )}
        {!showingFMP && <TagsFilter />}
      </div>
    </div>
  )

  const handleEdit = () => {
    editCollection()
    handleClose()
  }

  const handleCoverChange = () => {
    setNewCoverOpen(true)
    handleClose()
  }

  const handleDelete = () => {
    onDelete(currentCollection._id)
    handleClose()
  }

  const handleShowAll = () => {
    setShowingFMP(false)
    onResetFilteres()
  }

  const handleDownloadAll = () => {
    const fileIds = files.map((file) => file.fileId)
    bulkDownload({ fileIds })
  }

  const FMPHeader = (
    <div className="FMP-header">
      <div className="FMP-photo">
        <img src={FMPAvatar?.s3location} alt="your uploaded profile" />
      </div>

      <div className="FMP-content">
        <div className="FMP-title">
          <h1 className="h1">We found you in {fileCount} photos</h1>
        </div>
        <div className="FMP-actions">
          {/* <MyButton
                    size="small"
                    className="collection-header-button"
                    variant='contained'
                    color="primary"
                    onClick={()=>setShowShareDialog(true)}
                >
                    <img src={LINK} alt='link icon' />
                    <p className="p">Share link</p>
                </MyButton> */}
          <MyButton
            size="small"
            className="collection-header-button"
            variant="contained"
            color="primary"
            onClick={handleDownloadAll}
          >
            <img src={DOWNLOAD} alt="download icon" />
            <p className="p">Download all</p>
          </MyButton>
          <MyButton
            size="small"
            className="collection-header-button"
            variant="contained"
            color="secondary"
            onClick={handleShowAll}
            sx={[
              {
                '.MuiButton-label p': {
                  color: '#397CE2',
                },
              },
            ]}
          >
            <p className="p">View all photos</p>
          </MyButton>
        </div>
      </div>
    </div>
  )

  const replaceCover = async (newFileResult) => {
    await updateCollectionCover({
      id: currentCollection._id,
      coverImage: newFileResult,
    })
    getCurrentCollection(currentCollection._id)
  }

  return (
    <div className="collection-header-container">
      {isSearchMode() && !showingFMP ? inSearchMode : inMainMode}
      <CollectionShareDialog
        open={showShareDialog}
        setOpen={setShowShareDialog}
      />
      <CollectionFMPDialog
        open={isFMP && showFMPDialog}
        setOpen={setShowFMPDialog}
        setShowingFMP={setShowingFMP}
        setFMPPerson={setFMPPerson}
        setFMPAvatar={setFMPAvatar}
      />
      <Menu
        id="collection-show-more"
        anchorEl={anchorEl}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left',
        }}
        getContentAnchorEl={null}
        transformOrigin={{ vertical: 'top', horizontal: 'center' }}
        keepMounted
        open={Boolean(anchorEl)}
        onClose={handleClose}
        PaperProps={{
          style: {
            boxShadow: '0px 2px 10px rgba(0, 0, 0, 0.25)',
          },
        }}
      >
        <MenuItem onClick={handleEdit}>
          <EditOutlinedIcon fontSize="small" />
          Edit collection
        </MenuItem>
        <MenuItem onClick={handleCoverChange}>
          <CameraAltOutlinedIcon fontSize="small" />
          Change cover image
        </MenuItem>
        {isAllowed(RULES.DELETE_COLLECTION) && (
          <MenuItem style={{ color: '#CF232E' }} onClick={handleDelete}>
            <DeleteOutlineIcon fontSize="small" />
            Delete collection
          </MenuItem>
        )}
      </Menu>
      {newCoverOpen && (
        <SimpleUpload
          title="Upload new cover image"
          buttonText="Replace cover image"
          fileSpec="JPG, PNG (up to 10MB)"
          collectionId={currentCollection._id}
          close={closeUploadCover}
          isOpen={newCoverOpen}
          endFunction={replaceCover}
        />
      )}

      {showingFMP && !!FMPPerson && FMPHeader}
    </div>
  )
}

export default CollectionHeader
