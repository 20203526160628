import React from 'react'
import { makeStyles } from '@material-ui/core/styles'
import Button from '@material-ui/core/Button'
import TopBar from '../TopBar'
import TextField from '@material-ui/core/TextField'

const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: theme.palette.background.paper,
  },
  button: {
    display: 'block',
    width: '100%',
    marginTop: '20px',
  },
  input: {
    width: '100%',
    marginBottom: '20px',
    background: 'white',
  },
  title: {
    paddingBottom: '15px',
    fontWeight: 'bold',
    fontSize: '22px',
  },
  register: {
    textAlign: 'center',
    padding: '10px 0',
    textDecoration: 'underline',
    cursor: 'pointer',
  },
  logo: {
    textAlign: 'center',
    fontSize: '32px',
    color: 'grey',
    marginTop: '20px',
  },
  error: {
    color: 'red',
  },
}))

const CreateOrganization = ({ name, addOrganization, orgNameChange }) => {
  const classes = useStyles()

  return (
    <div className={classes.root}>
      <TopBar disable={true} />
      <div className="mobile-organized">
        <div className="title">
          We can’t wait to start helping you get organized!
        </div>
        <div className="formControl">
          {/* <TextField className="input" label="Full name" variant="outlined" /> */}
          <TextField
            className="input"
            value={name}
            label="Organization name"
            variant="outlined"
            onChange={(event) => {
              orgNameChange(event.target.value)
            }}
          />
          <Button
            className="button"
            variant="contained"
            color="primary"
            disabled={name.length < 2}
            onClick={() => {
              addOrganization(name)
            }}
          >
            Continue
          </Button>
        </div>
      </div>
    </div>
  )
}

export default CreateOrganization
