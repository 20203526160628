import React, { useState, useEffect, useCallback } from 'react'

import KeyboardArrowRightIcon from '@mui/icons-material/KeyboardArrowRight'

import Popper from '@material-ui/core/Popper'

import Fade from '@mui/material/Fade'
import Paper from '@mui/material/Paper'
import { MyKeyword } from '../../_MyComponents'
import { sortCategory } from '../../../util/tags'
import { set } from 'lodash'

const TagsByCategories = ({
  // from store
  categories,

  // from TagList
  fileTags = [],
  fileId = '',
  anchorEl,
  setAnchorEl,
  onAddTag,
  onRemoveTag,
  tags,
}) => {
  const [category, setCategory] = useState(null)
  const [hasMouseEnter, setHasMouseEnter] = useState(false)
  const [miscellaneousCategoryTags, setMiscellaneousCategoryTags] = useState([])

  const handleFileTag = (selected, tag) => {
    selected
      ? onRemoveTag({ fileId, tagsIds: [tag._id], tags: [tag] })
      : onAddTag({ fileId, tagsIds: [tag._id], tags: tags.concat(tag) })
  }

  useEffect(() => {
    categories?.otherCategory?.length > 0 &&
      setMiscellaneousCategoryTags([
        {
          name: 'Miscellaneous',
          tags: categories?.otherCategory,
        },
      ])
  }, [categories])

  const handleHoverCategory = (target, _category) => {
    if (!window.mobileCheck()) {
      setCategory(_category)
      setAnchorEl(target)
    } else {
      //the timeout is a hack to fix a bug on mobile where the tag is clicked automatically
      setTimeout(() => {
        setCategory(_category)
        setAnchorEl(target)
      }, 100)
    }
  }

  return (
    <>
      <div className="tags-group-wrapper">
        {(categories?.categoriesArrays.length
          ? [...categories?.categoriesArrays, ...miscellaneousCategoryTags]
          : []
        )?.map((category, index) => {
          return (
            <div className="tags-filter" key={index}>
              <div
                className="tags-group-category"
                onMouseEnter={() => {
                  // hasMouseMoved is a hack to fix a bug where the popup opens too soon
                  setHasMouseEnter(true)
                }}
                onMouseMove={(e) => {
                  if (hasMouseEnter) {
                    handleHoverCategory(e.currentTarget, category)
                    setHasMouseEnter(false)
                  }
                }}
              >
                <div className="tags-group-category-text">{category.name}</div>
                <div className="tags-group-category-icon">
                  <KeyboardArrowRightIcon fontSize="small" />
                </div>
              </div>
            </div>
          )
        })}
      </div>

      <Popper
        open={!!anchorEl && !!category}
        anchorEl={anchorEl}
        placement="right-end"
        className="cat-tags-popper"
        transition
      >
        {({ TransitionProps }) => (
          <Fade {...TransitionProps} appear={true} timeout={350}>
            <Paper>
              <div className="cat-tags-paper">
                {category?.tags.length > 0 ? (
                  sortCategory({ category })?.map((tag, index) => {
                    return (
                      <div key={index} className="tags-group-tag-container">
                        <MyKeyword
                          key={`tag-to-add-${tag.name}`}
                          onClick={(e) => {
                            e.stopPropagation()
                            handleFileTag(fileTags.includes(tag._id), tag)
                          }}
                          text={tag.name}
                          selected={fileTags.includes(tag._id)}
                        />
                      </div>
                    )
                  })
                ) : (
                  <div>No tags</div>
                )}
              </div>
            </Paper>
          </Fade>
        )}
      </Popper>
    </>
  )
}

export default TagsByCategories
