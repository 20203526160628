import { inject, observer } from 'mobx-react'
import DetailsBar from './DetailsBar'

export default inject(
  ({ orgsWorkspacesStore, filesStore, authenticationStore }) => ({
    orgId: orgsWorkspacesStore.orgId,
    workspaceId: orgsWorkspacesStore.workspaceId,
    username: authenticationStore.username,

    role: orgsWorkspacesStore.role,
    addToFileViewed: filesStore.addToFileViewed,
    allTags: filesStore.allTags,
    deleteFile: filesStore.deleteFile,
    isAllowed: orgsWorkspacesStore.isAllowed,
    addOrgTag: filesStore.addOrgTag,
    tagsList: filesStore.tagsList,
    onAddTag: filesStore.addFileTag,
    onRemoveTag: filesStore.removeFileTag,
    addCollectionToFile: filesStore.addCollectionToFile,
    removeCollectionToFile: filesStore.removeCollectionToFile,
    updateFileDescription: filesStore.updateFileDescription,
    restoreVersion: filesStore.restoreVersion,
    setTranscription: filesStore.setTranscription,
    transcription: filesStore.transcription,
    userState: authenticationStore.userState,
    updateUserState: authenticationStore.updateUserState,
  })
)(observer(DetailsBar))
