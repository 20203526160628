import React, { useState, useEffect } from 'react'

import MAGNIFYING from '../../assets/magnifying-glass.svg'
import CheckIcon from '@mui/icons-material/Check'
import ArrowForwardIcon from '@material-ui/icons/ArrowForward'

import Dialog from '@material-ui/core/Dialog'
import { MyButton } from '../_MyComponents'

import { Steps } from 'intro.js-react'
import { steps1 } from '../_intro-js'
import DemoUpload from '../DemoUpload'

import './EmptyStateMain.scss'
import './mobile.scss'

const EmptyStateMain = ({
  openUpload,
  userState,
  updateUserState,
  isAnonymous,
}) => {
  const [introJS, setIntroJS] = useState(null)
  const [introVideo, setIntroVideo] = useState(false)

  const onCloseIntro = () => {
    updateUserState({ 'onboarding.upload': true })
  }

  const onCloseIntroVideo = () => {
    setIntroVideo(false)
    if (introJS === null) {
      setTimeout(() => {
        setIntroJS(true)
      }, 1000)
    }
  }

  useEffect(() => {
    if (userState && !userState?.onboarding?.upload) {
      setIntroVideo(true)
    }
  }, [userState])

  return (
    <div className="empty-state">
      <div className="empty-state-card-container">
        <div className="empty-state-card">
          <div className="empty-state-content">
            <div className="file-type-icon-wrappaer">
              <img
                src={MAGNIFYING}
                className="file-type-icon"
                alt="search-icon"
              />
            </div>
            <div className="es-card-title">
              Upload a few images to get started.
            </div>
            <div className="es-card-desc">
              Once you upload, we'll help you organize them using the magic of
              tags
            </div>
          </div>
          <MyButton
            onClick={openUpload}
            className="es-card-button"
            variant="contained"
            color="primary"
          >
            <label /* htmlFor="contained-button-file" */>Upload</label>
          </MyButton>
          <p
            className="p light"
            style={{ cursor: 'pointer' }}
            onClick={() => setIntroVideo(true)}
          >
            Watch explainer video
          </p>
        </div>
      </div>
      <DemoUpload openUpload={openUpload} />
      <Dialog
        onClose={onCloseIntroVideo}
        maxWidth={'md'}
        aria-labelledby="simple-dialog-title"
        open={introVideo}
      >
        <div className="walkthrough-container">
          <div className="dialog-header">
            Welcome to Tagbox{' '}
            <span role="img" aria-label="hi">
              👋
            </span>
          </div>
          <div className="dialog-content">
            <div className="dialog-text">
              <div className="h1">
                Manage all your creative assets in one place
              </div>
              <div>
                <div className="p">
                  <CheckIcon fontSize="small" />
                  Upload your assets of any file type
                </div>
                <div className="p">
                  <CheckIcon fontSize="small" />
                  Organize automatically using smart tags
                </div>
                <div className="p">
                  <CheckIcon fontSize="small" />
                  Search and filter to instantly find the assets you're looking
                  for
                </div>
              </div>
              <MyButton
                variant="contained"
                color="primary"
                onClick={onCloseIntroVideo}
                sx={{ alignSelf: 'flex-end' }}
                endIcon={<ArrowForwardIcon />}
              >
                Let's start
              </MyButton>
            </div>
            <div className="dialog-video">
              <iframe
                width="560"
                height="315"
                src="https://www.youtube.com/embed/hOirEwMBPT8"
                title="YouTube video player"
                frameborder="0"
                allow="fullscreen; accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                allowfullscreen
              ></iframe>
            </div>
          </div>
        </div>
      </Dialog>
      {!isAnonymous && userState && !userState?.onboarding?.upload && (
        <Steps
          enabled={introJS}
          steps={steps1}
          initialStep={0}
          onExit={onCloseIntro}
          options={{
            doneLabel: 'Start',
            disableInteraction: false,
            showBullets: false,
          }}
        />
      )}
    </div>
  )
}

export default EmptyStateMain
