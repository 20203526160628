import { inject } from 'mobx-react'
import App from './App'
import './App.css'

export default inject(
  ({ appMounted, appWillUnmount, authenticationStore, registerStore }) => ({
    appMounted,
    appWillUnmount,
    doesUserAuthenticated: authenticationStore.doesUserAuthenticated,
    doesUserRegistered: registerStore.doesUserRegistered,
  })
)(App)
