import React, { useState, useEffect } from 'react'
import Dialog from '@material-ui/core/Dialog'
import IconButton from '@material-ui/core/IconButton'
import CloseIcon from '@material-ui/icons/Close'
import Import from './Import'
import Organize from './Organize'
import Refine from './Refine'
import ArrowForwardIcon from '@material-ui/icons/ArrowForward'
import { MyTabs, MyTab, MyButton } from '../_MyComponents'
import ActionDialog from '../ActionDialog'

const ImportWizard = ({
  isOpen,
  setDialog,
  closeUpload,
  getAllTags,
  getAllPersons,
  updateFilters,
  getAllFilesUsers,
  getAllFilesExtensions,
  getOrgLimits,
  walkthrough,
  setWalkthrough,
  driveFoldersNames,
  createDriveCategories,
  SyncChosenFiles,
}) => {
  const [menuState, setMenuState] = useState(0)
  const [showCloseDialog, setShowCloseDialog] = useState(false)

  const handleClose = async () => {
    closeUpload()
    setDialog(false)

    //onReset()
    updateFilters()
    getAllTags({})
    getAllFilesUsers({})
    getAllFilesExtensions({})
    getAllPersons({})
    getOrgLimits()
  }

  const nextStep = async () => {
    switch (menuState) {
      case 0:
        setMenuState(menuState + 1)
        break
      case 1:
        await createDriveCategories()
        SyncChosenFiles()
        handleClose()
        break
      default:
        break
    }
  }

  useEffect(() => {
    if (driveFoldersNames) {
      setMenuState(1)
    }
  }, [driveFoldersNames])

  return (
    <Dialog
      onClose={handleClose}
      fullScreen
      //maxWidth={"lg"}
      aria-labelledby="simple-dialog-title"
      open={!!isOpen}
    >
      <div className="wizard-header">
        <MyTabs indicatorColor="primary" variant="scrollable" value={menuState}>
          <MyTab label="1. Import" onClick={() => setMenuState(0)} />
          <MyTab label="2. Organize" onClick={() => setMenuState(1)} />
          {/* <MyTab label="2. Refine" onClick={() => setMenuState(2)} /> */}
        </MyTabs>
        <IconButton
          className="close-wizard"
          onClick={() => setShowCloseDialog(true)}
          component="span"
        >
          <CloseIcon />
        </IconButton>
      </div>

      <div className="wizard-container">
        <div className="wizard-content-container">
          {menuState === 0 && (
            <Import setDialog={setDialog} setMenuState={setMenuState} />
          )}
          {menuState === 1 && (
            <Organize
              walkthrough={walkthrough}
              setWalkthrough={setWalkthrough}
            />
          )}
          {menuState === 2 && <Refine />}
        </div>

        <div className="button-container">
          <MyButton
            endIcon={<ArrowForwardIcon />}
            variant="contained"
            color="primary"
            onClick={() => nextStep()}
          >
            Continue
          </MyButton>
        </div>
      </div>

      <ActionDialog
        actionName={'Cancel'}
        cancelName={'Close import'}
        cancel={() => handleClose()}
        action={() => setShowCloseDialog(false)}
        question={`Close import?`}
        comments={[
          'Closing the import might casue issues.',
          'We recommend not closing it.',
        ]}
        //color={'primary'}
        isOpen={showCloseDialog}
      />
    </Dialog>
  )
}

export default ImportWizard
