import React, { useState, useRef, useEffect } from 'react'
import Person from '../Person'
import ActionDialog from '../ActionDialog'
import InfiniteScroll from 'react-infinite-scroll-component'

const Persons = ({
  persons,
  setView,
  duplicatePersons,
  getAllPersons,
  PERSONS_IN_PAGE,
}) => {
  const nextPage = () => {
    console.log('nextPage')
    getAllPersons({ skip: persons.length })
  }

  return (
    <div className="persons-container">
      {/* no persons */}
      {persons?.length === 0 && (
        <div className="persons-empty-state">
          <div className="h1">No People here yet</div>
          <div className="h2 light">Upload more assets with people in it</div>
        </div>
      )}
      {/* persons */}
      {!!persons?.length > 0 && (
        <div className="persons-content-container" id="scollablePersons">
          <div className="persons-header">
            <h1 className="h1">People in images</h1>
          </div>
          <div className="persons-content">
            <InfiniteScroll
              className="persons-grid"
              dataLength={persons?.length}
              scrollThreshold={0.5}
              next={nextPage}
              hasMore={
                !!persons?.length && persons?.length % PERSONS_IN_PAGE === 0
              }
              loader={<p className="infinite-scroll-message">Loading...</p>}
              /*endMessage={
								persons?.length > PERSONS_IN_PAGE && (
									<p className="infinite-scroll-message">
										Yay! You have seen it all
									</p>
								)
							}*/
              scrollableTarget="scollablePersons"
              style={{ overflow: 'hidden' }}
            >
              {persons
                //.sort((a, b) => a.name?.localeCompare(b.name))
                .sort((a, b) => b.count - a.count)
                .sort((a, b) =>
                  a._id.toString().localeCompare(a._id.toString())
                )
                .map((person, idx) => (
                  <Person
                    key={`person-${person._id}`}
                    person={person}
                    itemId={`${person._id}-k`}
                    setView={setView}
                  ></Person>
                ))}
            </InfiniteScroll>
          </div>
        </div>
      )}
    </div>
  )
}

export default Persons
