import React, { useState, useEffect, useStyle } from 'react'
import DialogContent from '@material-ui/core/DialogContent'
import { MyButton } from '../_MyComponents'
import { isEmail } from 'validator'
import { filter } from 'lodash'
import ChipInput from 'material-ui-chip-input'
import FormControl from '@material-ui/core/FormControl'
import Select from '@material-ui/core/Select'
import MenuItem from '@material-ui/core/MenuItem'
import Tooltip from '@material-ui/core/Tooltip'
import TextField from '@material-ui/core/TextField'
import ActionDialog from '../ActionDialog'
import { RULES } from '../../store/OrgsWorkspacesStore/permissions'

const maxInvitations = 1

const CollectionPermissionsDialog = ({
  ORG_ROLES,
  open,
  setOpen,
  currentCollection,
  updateCollectionLocation,
  getCurrentCollection,
  ownUsername,
  inviteToCollection,
  removeFromCollection,
  updateUserRoleInCollection,
  workspaceUsers,
  orgPlan,
  orgPlanLimits,
  setSettingsDialogView,
  isAllowed,
}) => {
  const collectionROLES = Object.values(ORG_ROLES)
    .filter((role) => role.toLowerCase().startsWith('collection'))
    .map((role) => role.replace('collection', '').toLowerCase())
  const orgGROUPS = Object.values(ORG_ROLES)
    .filter((role) => !role.toLowerCase().startsWith('collection'))
    .map((role) => role.toLowerCase() + 's')
  const [emails, setEmails] = useState([])
  const [errorMessage, setErrorMessage] = useState('')
  const [upgradeDialog, setUpgradeDialog] = useState(false)
  const [role, setRole] = useState(collectionROLES[2])

  const handleLocation = async (location) => {
    await updateCollectionLocation({ id: currentCollection._id, location })
    getCurrentCollection(currentCollection._id)
  }

  const addEmail = (email) => {
    //if (emails.length > maxInvitations - 1) {
    //  setErrorMessage(`Max emails for invite is ${maxInvitations} emails`)
    if (!isEmail(email) && !orgGROUPS.includes(email)) {
      setErrorMessage(`Invalid email '${email}'`)
    } else {
      //if this is a new email and not already in the list check user limit
      if (
        isEmail(email) &&
        !workspaceUsers.find((user) => user.userId.username === email) &&
        orgPlanLimits.userCountLimit > 0 &&
        workspaceUsers.length >= orgPlanLimits.userCountLimit
      ) {
        setUpgradeDialog(true)
      } else {
        setErrorMessage('')
        setEmails([...emails, email])
      }
    }
  }

  const deleteEmail = (email) => {
    setErrorMessage('')
    if (email) {
      setEmails(filter(emails, (e) => e !== email))
    }
  }

  const handleChangeRole = (ev) => {
    setRole(ev.target.value)
  }

  const handleUserRoles = ({ username, role }) => {
    if (role === 'Remove access') {
      removeUser(username)
    } else {
      updateUserRole(username, role)
    }
  }

  const invite = async ({ emails, role }) => {
    console.log({ emails, role })
    await inviteToCollection({
      collectionId: currentCollection._id,
      usernames: emails,
      role,
    })
    getCurrentCollection(currentCollection._id)
    return
  }

  const removeUser = async (username) => {
    console.log({ username })
    await removeFromCollection({
      collectionId: currentCollection._id,
      usernames: [username],
    })
    getCurrentCollection(currentCollection._id)
    return
  }

  const updateUserRole = async (username, role) => {
    console.log({ username, role })
    if (username.includes('@')) {
      await updateUserRoleInCollection({
        collectionId: currentCollection._id,
        username,
        role,
      })
      getCurrentCollection(currentCollection._id)
      return
    } else {
      await inviteToCollection({
        collectionId: currentCollection._id,
        usernames: [username],
        role,
      })
      getCurrentCollection(currentCollection._id)
      return
    }
  }

  return (
    <DialogContent className="share-collection-section">
      <div className={'h2'}>Share by permission</div>
      {/* ADD NEW USER */}
      <div className="add-user-box">
        <ChipInput
          placeholder="Add people by email"
          value={emails}
          className="chip-input"
          color="primary"
          variant="standard"
          disableUnderline="true"
          onAdd={(email) => addEmail(email.toLowerCase())}
          onDelete={(email, index) => deleteEmail(email.toLowerCase(), index)}
        />
        {errorMessage && <div className="error-message">{errorMessage}</div>}
        <FormControl variant="outlined" size="small">
          <Select
            className="select-input"
            value={role}
            onChange={handleChangeRole}
          >
            {collectionROLES.map((role) => (
              <MenuItem key={role} value={role}>
                <span style={{ textTransform: 'capitalize' }}>{role}</span>
              </MenuItem>
            ))}
          </Select>
        </FormControl>
        <MyButton
          variant="contained"
          color="primary"
          className="invite-button"
          disabled={!emails.length}
          onClick={() => {
            invite({ emails, role }).then(() => {
              setEmails([])
            })
          }}
        >
          Send
        </MyButton>
      </div>

      {/* workspace access */}
      <div className="members-list">
        <div key={'everyone'} className="members-list-names">
          <div className={'name'}>Everyone at workspace</div>
          <FormControl
            className="members-select-input"
            variant="standard"
            size="small"
          >
            <TextField
              select
              disabled={false} //need to update to admin or owner
              value={currentCollection.location}
              onChange={(event) => {
                handleLocation(event.target.value)
              }}
            >
              <MenuItem key={`have-access`} value={'workspace'}>
                <span>Have access</span>
              </MenuItem>
              <MenuItem key={`have-no-access`} value={'private'}>
                <span>Have no access</span>
              </MenuItem>
            </TextField>
          </FormControl>
        </div>
      </div>

      {/*groups*/}
      <div className="members-list">
        {currentCollection.sharedWithGroups &&
          Object.keys(currentCollection.sharedWithGroups).map(
            (group, index) => (
              <div key={index} className="members-list-names">
                <div className={'name'}>{group}</div>
                <Tooltip title={''}>
                  <FormControl
                    className="members-select-input"
                    variant="outlined"
                  >
                    <TextField
                      select
                      value={currentCollection.sharedWithGroups[group]} //this is the role
                      onChange={(event) => {
                        handleUserRoles({
                          role: event.target.value,
                          username: group,
                        })
                      }}
                    >
                      {collectionROLES.map((role) => (
                        <MenuItem key={`${group}-${role}`} value={role}>
                          <span style={{ textTransform: 'capitalize' }}>
                            {role}
                          </span>
                        </MenuItem>
                      ))}
                      <MenuItem key={`${group}-remove`} value={'Remove access'}>
                        <span style={{ textTransform: 'capitalize' }}>
                          Remove access
                        </span>
                      </MenuItem>
                    </TextField>
                  </FormControl>
                </Tooltip>
              </div>
            )
          )}
      </div>

      {/*owner*/}
      <div className="members-list">
        <div key={currentCollection.owner._id} className="members-list-names">
          <div className={'name'}>{currentCollection.owner.username}</div>
          <FormControl
            className="members-select-input"
            variant="outlined"
            size="small"
          >
            <TextField select disabled={true} value={'owner'}>
              <MenuItem
                key={`${currentCollection.owner.username}-owner`}
                value={'owner'}
              >
                <span style={{ textTransform: 'capitalize' }}>owner</span>
              </MenuItem>
            </TextField>
          </FormControl>
        </div>
      </div>

      {/*all other members*/}
      <div className="members-list">
        {currentCollection.sharedWithUsers.map(
          ({ _id, username, role }, index) => (
            <div key={index} className="members-list-names">
              <div className={'name'}>{username}</div>
              <Tooltip
                title={
                  username === ownUsername
                    ? 'You can’t change your own permissions'
                    : ''
                }
              >
                <FormControl
                  className="members-select-input"
                  variant="outlined"
                >
                  <TextField
                    select
                    disabled={username === ownUsername}
                    value={role}
                    onChange={(event) => {
                      handleUserRoles({ role: event.target.value, username })
                    }}
                  >
                    {collectionROLES.map((role) => (
                      <MenuItem key={`${username}-${role}`} value={role}>
                        <span style={{ textTransform: 'capitalize' }}>
                          {role}
                        </span>
                      </MenuItem>
                    ))}
                    <MenuItem
                      key={`${currentCollection.owner.username}-remove`}
                      value={'Remove access'}
                    >
                      <span style={{ textTransform: 'capitalize' }}>
                        Remove access
                      </span>
                    </MenuItem>
                  </TextField>
                </FormControl>
              </Tooltip>
            </div>
          )
        )}
      </div>
      <ActionDialog
        actionName={'Upgrade'}
        action={() => {
          setUpgradeDialog(false)
          setSettingsDialogView('account')
        }}
        disabled={!isAllowed(RULES.ADDING_USERS)}
        cancel={() => setUpgradeDialog(false)}
        question={`Upgrade plan?`}
        comment={
          "You've used all of the users in current plan. Would you like to upgrade to the Company plan?"
        }
        disabledText={'Please contact your organization admin to upgrade plan'}
        //color={"warning"}
        isOpen={upgradeDialog}
      />
    </DialogContent>
  )
}

export default CollectionPermissionsDialog
