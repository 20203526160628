import { inject, observer } from 'mobx-react'
import Settings from './Settings'
import './mobile.scss'

export default inject(({ orgsWorkspacesStore, authenticationStore }) => ({
  ORG_ROLES: orgsWorkspacesStore.ORG_ROLES,
  invite: orgsWorkspacesStore.inviteUser,
  workspaceUsers: orgsWorkspacesStore.workspaceUsers,
  updateUserIsRole: orgsWorkspacesStore.updateUserIsRole,
  removeMember: orgsWorkspacesStore.removeMember,
  username: authenticationStore.username,
  workspaces: orgsWorkspacesStore.workspaces,
  deleteWorkspace: orgsWorkspacesStore.deleteWorkspace,
  createPaymentLink: orgsWorkspacesStore.createPaymentLink,
  savePaymentsInfo: orgsWorkspacesStore.savePaymentsInfo,
  cancelPayments: orgsWorkspacesStore.cancelPayments,
  updatePaymentsPlan: orgsWorkspacesStore.updatePaymentsPlan,
  organization: orgsWorkspacesStore.organization,
  orgType: orgsWorkspacesStore.orgType,
  orgPayment: orgsWorkspacesStore.orgPayment,
  orgPlan: orgsWorkspacesStore.orgPlan,
  orgPaymentInfo: orgsWorkspacesStore.orgPaymentInfo,
  featureFlags: orgsWorkspacesStore.featureFlags,
  orgPlanLimits: orgsWorkspacesStore.orgPlanLimits,
  workspaceId: orgsWorkspacesStore.workspaceId,
  logo: orgsWorkspacesStore.logo,
}))(observer(Settings))
