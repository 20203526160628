import '@draft-js-plugins/mention/lib/plugin.css'
import React, { useState, useEffect, useCallback, useRef } from 'react'
import { MyButton } from '../_MyComponents'
import { getUserId } from '../../services/apis/util'
import Comment from './Comment'
import {
  getComments,
  addComment,
  deleteComment,
} from '../../services/apis/comments'
import Editor from '@draft-js-plugins/editor'
import ActionDialog from '../ActionDialog'
import {
  EditorState,
  convertToRaw,
  ContentState,
  SelectionState,
  Modifier,
} from 'draft-js'
import createMentionPlugin, {
  defaultSuggestionsFilter,
} from '@draft-js-plugins/mention'
import './style.scss'
import { getDefaultKeyBinding } from 'draft-js'
import { RULES } from '../../store/OrgsWorkspacesStore/permissions'

const mentionPlugin = createMentionPlugin({
  mentionTrigger: ['@' /* , "#" */],
})
const { MentionSuggestions } = mentionPlugin

const plugins = [mentionPlugin]

if (typeof String.prototype.trimLeft !== 'function') {
  String.prototype.trimLeft = function () {
    return this.replace(/^\s+/, '')
  }
}

if (typeof String.prototype.trimRight !== 'function') {
  String.prototype.trimRight = function () {
    return this.replace(/\s+$/, '')
  }
}

const Comments = ({
  fileId,
  workspaceUsers,
  workspaceId,
  orgId,
  isAllowed,
  username,
}) => {
  const ref = useRef(null)

  const [comments, setComments] = useState([])
  const [editorState, setEditorState] = useState(EditorState.createEmpty())
  const [disabledButton, setDisabledButton] = useState(true)
  const [suggestions, setSuggestions] = useState([])
  const [open, setOpen] = useState(false)
  const [searchValue, setSearchValue] = useState('')
  const [mentions, setMentions] = useState(undefined)
  const [focus, setFocus] = useState(false)
  const [deleteCommentId, setDeleteCommentId] = useState(null)

  useEffect(() => {
    async function getFileComments(fileId, workspaceUsers) {
      if (workspaceUsers !== undefined) {
        const res = await getComments(fileId, workspaceId)
        setComments(() => res)
      }
    }
    getFileComments(fileId, workspaceUsers)

    async function getUsersInfo(workspaceUsers) {
      if (workspaceUsers !== undefined) {
        const arrUsers = await workspaceUsers.map((user) => {
          return {
            name: user.userId?.name ? user.userId.name : user.userId.username,
            id: user.userId.username,
            link: user.userId.username,
          }
        })
        setMentions(() => {
          return { '@': [...arrUsers] }
        })
      }
    }
    getUsersInfo(workspaceUsers)
    console.log(fileId, workspaceUsers, workspaceId)
  }, [fileId, workspaceUsers, workspaceId])

  useEffect(() => {
    if (mentions !== undefined) {
      setSuggestions(() => mentions['@'])
    }
  }, [mentions])

  const handleValueChange = (_editorState) => {
    setEditorState(() => _editorState)
    if (_editorState.getCurrentContent().hasText()) {
      setDisabledButton(false)
    } else {
      setDisabledButton(true)
    }
  }

  function myKeyBindingFn(e) {
    if (e.key === 'Enter') {
      if (!e.nativeEvent.shiftKey) {
        return 'on-enter'
      }
    }
    if (e.key === '@') {
      onOpenChange(!open)
    }
    if (open) {
      //handling mention search
      var newSearchValue = searchValue
      if (e.key === 'Backspace') {
        //closing mention Suggestions on backspace if last key
        if (searchValue.length === 0) {
          onOpenChange(!open)
        }
        newSearchValue = searchValue.slice(0, searchValue.length - 1)
      } else {
        newSearchValue = searchValue + e.key
      }
      setSearchValue(newSearchValue)
      onSearchChange({ value: newSearchValue })
    }
    return getDefaultKeyBinding(e)
  }

  function handleKeyCommand(command) {
    if (command === 'on-enter') {
      onSubmit()
      return 'handled'
    }
    return 'not-handled'
  }

  const onOpenChange = useCallback((_open) => {
    setOpen(_open)
    //reset search value on open/close
    setSearchValue('')
  }, [])

  const onSearchChange = ({ value }) => {
    setSuggestions(defaultSuggestionsFilter(value, mentions['@']))
  }

  const onSubmit = async () => {
    const processedContent = await trimContent()
    if (processedContent.getCurrentContent().hasText()) {
      const rawData = convertToRaw(processedContent.getCurrentContent())
      const mentionsArray = getMentions(rawData.entityMap)
      const contentCleared = await trimEmptyBlocks(rawData.blocks)
      if (contentCleared.length > 0) {
        const content = JSON.stringify({
          blocks: contentCleared,
          entityMap: rawData.entityMap,
        })
        await addComment(
          content,
          fileId,
          orgId,
          getUserId(),
          workspaceId,
          mentionsArray
        )
        const res = await getComments(fileId, workspaceId)

        setComments(() => res)
      }
      setEditorState(
        EditorState.push(editorState, ContentState.createFromText(''))
      )
      setDisabledButton(true)
      setOpen(false)
    }
  }

  const onDeleteComment = async (id) => {
    setDeleteCommentId(null)
    await deleteComment({ id })
    const res = await getComments(fileId, workspaceId)
    setComments(() => res)
  }

  const getAvatar = (username) => {
    const currentUser = workspaceUsers.filter(
      (user) => user.userId.username === username
    )
    let name
    if (currentUser[0]) {
      name = currentUser[0]?.userId.displayName
        ? currentUser[0]?.userId.displayName
        : currentUser[0]?.userId.username
    } else {
      name = name ? name : 'Anonymous'
    }
    const url = `https://ui-avatars.com/api/?name=${name}&background=fff&bold=true`
    return { name: name, image: url }
  }

  function getMentions(entityArr) {
    const mentArr = []
    for (var key in entityArr) {
      const link = entityArr[key].data.mention.link
      mentArr.push(link)
    }
    return mentArr
  }

  const trimContent = async () => {
    let currentContent = editorState.getCurrentContent()
    const firstBlock = currentContent.getBlockMap().first()
    const lastBlock = currentContent.getBlockMap().last()
    const firstBlockKey = firstBlock.getKey()
    const lastBlockKey = lastBlock.getKey()
    const firstAndLastBlockIsTheSame = firstBlockKey === lastBlockKey

    const textStart = firstBlock.getText()
    const trimmedTextStart = textStart.trimLeft()
    const lengthOfTrimmedCharsStart = textStart.length - trimmedTextStart.length

    let newSelection = new SelectionState({
      anchorKey: firstBlockKey,
      anchorOffset: 0,
      focusKey: firstBlockKey,
      focusOffset: lengthOfTrimmedCharsStart,
    })

    currentContent = Modifier.replaceText(currentContent, newSelection, '')

    let newEditorState = EditorState.push(editorState, currentContent)

    let offset = 0

    if (firstAndLastBlockIsTheSame) {
      offset = lengthOfTrimmedCharsStart
    }

    const textEnd = lastBlock.getText()
    const trimmedTextEnd = textEnd.trimRight()

    newSelection = new SelectionState({
      anchorKey: lastBlockKey,
      anchorOffset: trimmedTextEnd.length - offset,
      focusKey: lastBlockKey,
      focusOffset: textEnd.length - offset,
    })

    currentContent = Modifier.replaceText(currentContent, newSelection, '')

    newEditorState = EditorState.push(editorState, currentContent)

    setEditorState(() => newEditorState)

    return newEditorState
  }

  const trimEmptyBlocks = async (blocks) => {
    const newBlocks = []
    blocks.forEach((block) => {
      if (block.text !== '') {
        newBlocks.push(block)
      }
    })
    return newBlocks
  }

  const editable = (tUsername) => {
    return isAllowed(RULES.EDIT_COMMENT) || tUsername === username
  }

  return (
    <>
      <div className={'root'}>
        <div
          className={`${'comment-input'} ${focus === true && 'onFocus'}`}
          onClick={() => {
            if (ref.current) ref.current.focus()
          }}
        >
          <Editor
            className={'comment-editor'}
            editorState={editorState}
            plugins={plugins}
            onChange={handleValueChange}
            placeholder={'Write a comment'}
            handleKeyCommand={handleKeyCommand}
            keyBindingFn={myKeyBindingFn}
            onFocus={() => setFocus(true)}
            onBlur={() => setFocus(false)}
          />
          <MentionSuggestions
            open={open}
            onOpenChange={onOpenChange}
            onSearchChange={onSearchChange}
            suggestions={suggestions}
          />
        </div>
        <MyButton
          className={'send-button'}
          variant="contained"
          color="primary"
          //size="small"
          onClick={onSubmit}
          disabled={disabledButton}
        >
          Send
        </MyButton>
      </div>

      {comments?.map((comment) => (
        <Comment
          toggleDialog={() => setDeleteCommentId(comment._id)}
          comment={comment}
          key={Math.random()}
          avatar={getAvatar(comment.username)}
          editable={editable(comment.username)}
        />
      ))}
      <ActionDialog
        actionName={'Delete'}
        action={() => onDeleteComment(deleteCommentId)}
        cancel={() => setDeleteCommentId(null)}
        question={`Delete comment?`}
        comments={['Are you sure you want to delete this comment?']}
        color={'warning'}
        isOpen={deleteCommentId}
      />
    </>
  )
}

export default Comments
