import { observable, decorate, action, configure, runInAction } from 'mobx'
import { subscribe, dispatch } from '../../services/pubsub'
import Actions from '../../util/actions'
import history from '../../services/history'
configure({ enforceActions: 'observed' })

class DropboxImportStore {
  constructor() {
    this.getAccounts = this.getAccounts.bind(this)
    this.verifyAccountExist = this.verifyAccountExist.bind(this)

    this.assignWorkspace = this.assignWorkspace.bind(this)
    this.browseTo = this.browseTo.bind(this)
    this.toggleAddFolder = this.toggleAddFolder.bind(this)
    this.removeFolder = this.removeFolder.bind(this)

    subscribe(Actions.VERIFY_ACCOUNT_REQUESTED, this.verifyAccountExist)
    subscribe(Actions.ACCOUNTS_REQUESTED, this.getAccounts)
  }

  folderHierarchy = {}
  folders = {}
  parent = null
  parentHistory = []
  loadingHierarchy = false
  folderSelected = null
  breadcrumb = {}

  verifyAccountExist() {
    dispatch(Actions.GET_FILES, {})
    dispatch(Actions.GET_TAGS, {})
    dispatch(Actions.GET_FILES_USERS, {})
    dispatch(Actions.GET_POPULAR_TAGS, {})
    dispatch(Actions.GET_POPULAR_USERS, {})
  }

  getAccounts() {
    return this.rootStore.apis.getAccounts().then(({ accounts }) => {
      runInAction(() => {
        this.accounts = accounts
      })
      return accounts
    })
  }

  getAccountWorkspaces() {
    return this.rootStore.apis
      .getAccountWorkspaces()
      .then(({ accountWorkspaces }) => {
        runInAction(() => {
          this.accountWorkspaces = accountWorkspaces
          this.currentWorkingWorkspace = accountWorkspaces[0]
        })
        return accountWorkspaces
      })
  }

  async assignWorkspace({ token }) {
    runInAction(() => {
      this.loadingHierarchy = true
      history.push('/')
    })
    const { folderHierarchy } =
      await this.rootStore.apis.get3rdPartyFolderHierarchy({ token })
    runInAction(() => {
      this.loadingHierarchy = false
      this.folderHierarchy = folderHierarchy
      this.folders = folderHierarchy
      this.parent = null
      this.folderSelected = new Map()
    })
  }

  getFoldersToRemove(folder) {
    return [...this.folderSelected.values()].filter(
      ({ path_lower }) =>
        !path_lower.indexOf(folder.path_lower) ||
        !folder.path_lower.indexOf(path_lower)
    )
  }

  toggleAddFolder(folder, name) {
    runInAction(() => {
      if (this.folderSelected.has(folder)) {
        this.folderSelected.delete(folder)
        return
      }
      const foldersToRemove = this.getFoldersToRemove(folder)
      foldersToRemove.forEach((folder) => {
        this.folderSelected.delete(folder)
      })
      this.folderSelected.set(folder, folder)
      console.log(
        [...this.folderSelected.values()].map(JSON.stringify).map(JSON.parse)
      )
    })
  }

  getPath(folders) {
    const [, { path_display }] = Object.entries(folders)[0]
    const [, ...parts] = path_display.split('/')
    parts.pop()
    return { parts, folders }
  }

  removeFolder(folder) {
    runInAction(() => {
      if (this.folderSelected.has(folder)) {
        this.folderSelected.delete(folder)
      }
    })
  }

  browseTo(folders) {
    runInAction(() => {
      this.breadcrumb = this.getPath(folders)
      if (folders === this.parent) {
        this.parentHistory.pop()
        console.log(Object.keys(this.parent || {}))
      } else {
        this.parentHistory.push(this.folders)
        console.log(Object.keys(this.parent || {}))
      }
      this.parent = this.parentHistory[this.parentHistory.length - 1]

      this.folders = folders
    })
  }
}

decorate(DropboxImportStore, {
  accounts: observable,
  currentWorkingWorkspace: observable,
  folderHierarchy: observable,
  loadingHierarchy: observable,
  folders: observable,
  getWorkingAccount: observable,
  parent: observable,
  folderSelected: observable,
  breadcrumb: observable,

  getAccounts: action,
  browseTo: action,
  toggleAddFolder: action,
  removeFolder: action,
})

export default DropboxImportStore
