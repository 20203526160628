import { inject, observer } from 'mobx-react'
import CreateCollectionDialog from './CreateCollectionDialog'

export default inject(({ filesStore }) => ({
  createCollection: filesStore.createCollection,
  getAllCollections: filesStore.getAllCollections,
  editCollection: filesStore.editCollection,
  collection: filesStore.currentCollection,
  getCurrentCollection: filesStore.getCurrentCollection,
  updateCollectionRules: filesStore.updateCollectionRules,
  updateCollectionType: filesStore.updateCollectionType,
  collectionsRulesToSimpleTags: filesStore.collectionsRulesToSimpleTags,
  allTags: filesStore.allTags,
  allPersons: filesStore.allPersons,
  runCollectionRules: filesStore.runCollectionRules,
}))(observer(CreateCollectionDialog))
