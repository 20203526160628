import React, { useState, useRef, useEffect } from 'react'
import ActionDialog from '../../ActionDialog'
import { MyKeyword, MyButton } from '../../_MyComponents'
import { default as MuiMI } from '@material-ui/core/MenuItem' // alias due to conflict with szhsin/react-menu
import { Menu, MenuItem } from '@szhsin/react-menu'
import DownloadIcon from '../../../assets/download-icon.svg'
import WaitingIcon from '../../../assets/waiting.svg'
import TranscriptIcon from '../../../assets/no-transcript-icon.svg'
import { ms2Duration } from '../../../util/dateTimes'
import Select from '@material-ui/core/Select'
import FormControl from '@material-ui/core/FormControl'
import Flag from 'react-world-flags'
import { getTranscription } from '../../../services/apis/files'
import { langToCountry } from '../../../util/langToCountry'

const TranscriptTab = ({
  // from parent
  setTranscription,
  transcription,
  userState,
  updateUserState,
  file,
  fileId,
}) => {
  const [lang, setLang] = useState(file?.transcription?.language)
  const [entPopup, setEntPopup] = useState(false)

  const [highlightedLine, setHighlightedLine] = React.useState('trans-line-0')
  const itemsRef = useRef([])

  useEffect(() => {
    console.log('userState', userState)

    //set state preferred language if possible
    if (userState && userState?.transcription?.language) {
      var isTranscriptionLang =
        file?.transcription?.language === userState?.transcription?.language
      var isInTranslationsLang = file?.transcriptions?.find(
        (t) => t.language === userState?.transcription?.language
      )

      if (isTranscriptionLang) {
        setLang(file?.transcription?.language)
        setTranscription(file?.transcription)
      } else if (isInTranslationsLang) {
        getTranscription({ id: isInTranslationsLang.transcription }).then(
          (newTranscription) => {
            setLang(isInTranslationsLang.language)
            setTranscription(newTranscription)
          }
        )
      } else {
        setLang(file?.transcription?.language)
        setTranscription(file?.transcription)
      }
    } else {
      setLang(file?.transcription?.language)
      setTranscription(file?.transcription)
    }
  }, [file])

  const handleGoToSecoundInVideo = (seconds) => {
    var player = document.getElementById('myVideo')
    if (!player) {
      player = document.getElementById('myAudio')
    }

    if (player) {
      player.currentTime = seconds
    }
    var currentTimeseconds = seconds.toFixed(0)
    setHighlightedLine('trans-line-' + currentTimeseconds)
  }

  useEffect(() => {
    itemsRef.current = itemsRef.current.slice(
      0,
      transcription?.segments?.length || 0
    )
    setHighlightedLine('trans-line-0')
    if (transcription?.text) {
      var element = itemsRef[0]
      if (element) {
        var item = itemsRef.current.find((i) => i.id === element.id)
        item.scrollIntoView({ behavior: 'smooth' })
      }
    }
  }, [fileId, transcription])

  useEffect(() => {
    const transcriptionScroller = setInterval(function () {
      if (transcription?.text) {
        var player = document.getElementById('myVideo')
        if (!player) {
          player = document.getElementById('myAudio')
        }
        if (player) {
          var currentTimeseconds = player.currentTime.toFixed(0)
          if (currentTimeseconds != 0) {
            var element = document.getElementById(
              'trans-line-' + currentTimeseconds
            )
            if (element) {
              setHighlightedLine('trans-line-' + currentTimeseconds)
              var previousSibling = element.previousSibling
              var item = itemsRef.current.find(
                (item) => item.id === previousSibling.id
              )
              item.scrollIntoView({ behavior: 'smooth' })
            }
          }
        }
      }
    }, 1000)
    return () => {
      clearInterval(transcriptionScroller)
    }
  }, [fileId, transcription])

  //////////////////////////////////////
  ///// Transcription
  //////////////////////////////////////

  const handleLangChange = async (e) => {
    //set state preferred language
    updateUserState({ transcription: { language: e.target.value } })

    setLang(e.target.value)
    var localTranscription = file?.transcriptions?.find(
      (t) => t.language === e.target.value
    )
    if (localTranscription) {
      var newTranscription = await getTranscription({
        id: localTranscription.transcription,
      })
      setTranscription(newTranscription)
    } else {
      setTranscription(file?.transcription)
    }
  }

  const changeLanguage = (
    <div className={'language-wrapper'}>
      {file?.transcriptions?.length ? (
        <FormControl variant="outlined" className="select-input">
          <Select
            value={lang}
            label="Language"
            onChange={handleLangChange}
            style={{ fontSize: '13px' }}
          >
            <MuiMI
              key={file?.transcription?.language}
              value={file?.transcription?.language}
            >
              <Flag code={langToCountry(file?.transcription?.language)} />
            </MuiMI>
            {file?.transcriptions?.map((transcription) => {
              return (
                <MuiMI
                  key={transcription.language}
                  value={transcription.language}
                >
                  <Flag code={langToCountry(transcription.language)} />
                </MuiMI>
              )
            })}
          </Select>
        </FormControl>
      ) : (
        <MyButton
          className="language-ent"
          style={{
            backgroundColor: 'white',
            height: '40px',
          }}
          onClick={() => setEntPopup(true)}
        >
          <Flag code={langToCountry(file?.transcription?.language)} />
        </MyButton>
      )}
    </div>
  )

  const onDownloadSubtitles = (subtitlesS3Location) => {
    window.open(subtitlesS3Location, '_blank')
  }

  const downloadSubtitles = (
    <div className={'download-subtitles-wrapper'}>
      <Menu
        direction="left"
        menuButton={
          <div className="subs-dd">
            <img src={DownloadIcon} />
            <p className="p">Subtitles</p>
          </div>
        }
      >
        <MenuItem
          onClick={() => {
            onDownloadSubtitles(transcription?.srt)
          }}
        >
          <p className="p">Download .SRT</p>
        </MenuItem>
        <MenuItem onClick={() => onDownloadSubtitles(transcription?.vtt)}>
          <p className="p">Download .VTT</p>
        </MenuItem>
      </Menu>
    </div>
  )

  const mapTrans = transcription?.text
    ? transcription?.segments.map((segment) => {
        return {
          timestamp: segment.start * 1000,
          time: ms2Duration(segment.start * 1000),
          type: 'transcription',
          text: segment.text,
        }
      })
    : []

  const transTexts = mapTrans.map((line, i) => {
    return (
      <div
        id={'trans-line-' + (line.timestamp / 1000).toFixed(0)}
        className={`trans-line ${
          highlightedLine ===
            'trans-line-' + (line.timestamp / 1000).toFixed(0) && 'highlighted'
        }`}
        key={i}
        ref={(el) => (itemsRef.current[i] = el)}
      >
        <MyKeyword
          text={line.time}
          onClick={() => handleGoToSecoundInVideo(line.timestamp / 1000)}
        />
        <p className="p">{line.text}</p>
      </div>
    )
  })

  const transPending = (
    <div className="trans-empty-wrapper">
      <img src={WaitingIcon} className="trans-empty-icon" alt="search-icon" />
      <p className="p">
        We've sent your file to our AI overlords. They should get back to us
        with a searchable transcription within the hour.
      </p>
    </div>
  )

  const transEmptyState = (
    <div className="trans-empty-wrapper">
      <img
        src={TranscriptIcon}
        className="trans-empty-icon"
        alt="search-icon"
      />
      <p className="p">We couldn't make out a transcript for this file.</p>
    </div>
  )

  const transObjects = (
    <div className="trans-container">
      <div className="actions-bar flex-row">
        {transcription?.vtt && transcription?.srt && downloadSubtitles}
        {changeLanguage}
      </div>
      <div className="trans-content">{transTexts}</div>
    </div>
  )

  const trans = (
    <div className="trans-wrapper">
      {file?.transcriptionStatus !== 'complete'
        ? transPending
        : !mapTrans.length
        ? transEmptyState
        : transObjects}
    </div>
  )

  return (
    <>
      {trans}

      <ActionDialog
        actionName={'Okay'}
        action={() => setEntPopup(false)}
        question={`Need video translation?`}
        comment={
          "Automatic video tranlation is a premium feature and isn't part of your current plan. Contact us at hello@tagbox.io to learn more and add it to your plan."
        }
        //color={'warning'}
        isOpen={entPopup}
      />
    </>
  )
}

export default TranscriptTab
