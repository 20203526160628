import { inject, observer } from 'mobx-react'
import UploadWizard from './UploadWizard'
import './mobile.scss'

export default inject(({ filesStore, orgsWorkspacesStore }) => ({
  filesInQ: filesStore.filesInQ,
  isUpload: filesStore.isUpload,
  closeUpload: filesStore.closeUpload,
  getAllTags: filesStore.getAllTags,
  getAllPersons: filesStore.getAllPersons,
  uploadQIndex: filesStore.uploadQIndex,
  analysisQIndex: filesStore.analysisQIndex,
  isUploadFinished: filesStore.isUploadFinished,
  bulkAddCollections: filesStore.bulkAddCollections,
  recentlyUploadedFilesIds: filesStore.recentlyUploadedFilesIds,
  updateFilters: filesStore.updateFilters,
  getAllFilesUsers: filesStore.getAllFilesUsers,
  getAllFilesExtensions: filesStore.getAllFilesExtensions,
  getOrgLimits: orgsWorkspacesStore.getOrgLimits,
  uploadToS3: filesStore.uploadToS3,
  bulkTag: filesStore.bulkTag,
}))(observer(UploadWizard))
