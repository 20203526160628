import React, { useState, useEffect } from 'react'
import Board from 'react-trello'
import TagCard from '../../TagsManagment/TagCard/TagCard'
import MyLaneHeader from '../../TagsManagment/MyLaneHeader/MyLaneHeader'
import MyAddCardLink from '../../TagsManagment/MyAddCardLink'
import MyNewLaneSection from '../../TagsManagment/MyNewLaneSection'
import MyNewCardForm from '../../TagsManagment/MyNewCardForm'
import MyNewLane from '../../TagsManagment/MyNewLaneForm'
import {
  GlobalStyle,
  Section,
  BoardWrapper,
  ScrollableLane,
} from '../../TagsManagment/MyGlobalStyle'
import ActionDialog from '../../ActionDialog'
import MAGIC from '../../../assets/magic-wand.svg'
import UP from '../../../assets/thumbs-up-icon.svg'
import DOWN from '../../../assets/thumbs-down-icon.svg'
import REDO from '../../../assets/restart-icon.svg'
import { MyButton } from '../../_MyComponents'

const Organize = ({
  // from store
  username,
  driveFoldersNames,
  categories,
  setCategories,

  // from parent
  walkthrough,
  setWalkthrough,
}) => {
  //const [categories, setCategories] = useState([])

  const [showDialogMisc, setShowDialogMisc] = useState(false)

  ///////////////////
  // Sample Data
  ///////////////////

  const sampleCategories = {
    categoriesArrays: [
      {
        _id: '2',
        name: 'category1',
        order: 4,
        tags: [
          {
            _id: 'tag1-1',
            name: 'tag1-1',
          },
          {
            _id: 'tag1-2',
            name: 'tag1-2',
          },
        ],
      },
      {
        _id: '3',
        name: 'category2',
        order: 3,
        tags: [
          {
            _id: 'tag2-1',
            name: 'tag2-1',
          },
          {
            _id: 'tag2-2',
            name: 'tag2-2',
          },
          {
            _id: 'tag2-3',
            name: 'tag2-3',
          },
        ],
      },
    ],
    otherCategory: [
      {
        _id: '1',
        name: 'misc',
        order: 0,
        tags: [
          {
            _id: 'misc1',
            name: 'misc1',
          },
        ],
      },
    ],
  }

  useEffect(() => {
    if (driveFoldersNames) {
      setCategories({
        categoriesArrays: driveFoldersNames?.categories
          ?.filter((cat) => {
            return cat.name !== 'Miscellaneous'
          })
          .map((cat, index) => {
            var category = {
              _id: index,
              name: cat.name,
              order: index,
              tags: cat.tags.map((tag, index) => {
                return {
                  _id: index,
                  name: tag,
                }
              }),
            }
            return category
          }),
        otherCategory: driveFoldersNames?.categories
          ?.filter((cat) => {
            return cat.name === 'Miscellaneous'
          })
          .map((cat, index) => {
            var category = {
              _id: index,
              name: cat.name,
              order: index,
              tags: cat.tags.map((tag, index) => {
                return {
                  _id: index,
                  name: tag,
                }
              }),
            }
            return category
          }),
      })
    }
  }, [driveFoldersNames])

  ///////////////////
  // End Sample Data
  ///////////////////

  // useEffect that saved the categories to state
  /*
  useEffect(() => {
    setCategories(sampleCategories)
  }, [])
  */

  const dismissWalkthrough = () => {
    setWalkthrough({ ...walkthrough, import: false })
  }

  const components = {
    GlobalStyle,
    Section,
    BoardWrapper,
    ScrollableLane,
    Card: TagCard,
    LaneHeader: MyLaneHeader,
    AddCardLink: MyAddCardLink,
    NewLaneSection: MyNewLaneSection,
    NewCardForm: MyNewCardForm,
    NewLaneForm: MyNewLane,
    //Loader,
    //LaneFooter,
  }

  const OTHER_CATEGORY_LANE_ID = '1'
  const WALKTHROUGH_CATEGORY_LANE_ID = '0'
  const FORBIDDEN_IDS = [OTHER_CATEGORY_LANE_ID, WALKTHROUGH_CATEGORY_LANE_ID]

  const { categoriesArrays = [], otherCategory = [] } = categories

  let walkthroughLane = {
    id: WALKTHROUGH_CATEGORY_LANE_ID,
    title: 'Tutorial',
    cards: [
      {
        walkthrough: true,
        dismissWalkthrough,
        id: 0,
        title: 'none',
        laneId: 0,
      },
    ],
    order: -2,
    isRemovable: false,
    disallowAddingCard: true,
    style: {
      backgroundColor: 'rgb(253, 244, 230)',
      color: '#fff',
      width: 280,
    },
  }

  //map categoriesArrays to board layout
  let data = {}

  //map other tags:
  let otherLane = {
    id: OTHER_CATEGORY_LANE_ID,
    title: 'Miscellaneous',
    cards: [],
    order: -1,
    isRemovable: false,
    laneId: OTHER_CATEGORY_LANE_ID,
  }
  otherLane.cards = otherCategory[0]?.tags
    .sort((a, b) => a.name.localeCompare(b.name))
    .map((tag) => {
      const { _id, name } = tag
      return { id: _id, title: name, laneId: _id }
    })
    .slice(0, 100)

  //map real Categories
  let realCategoriesLanes = categoriesArrays
    .sort((a, b) => a.order - b.order)
    .map((category) => {
      const { _id, name, order } = category

      const cards = category.tags.map((tag) => {
        const { _id, name } = tag
        return { id: _id, title: name, laneId: _id }
      })

      return {
        id: _id,
        title: name,
        cards,
        order,
        isRemovable: true,
      }
    })

  //make sure it's always on the most left
  otherLane.order = realCategoriesLanes.length
    ? realCategoriesLanes[0].order - 1
    : -1

  // undefined for new users
  if (walkthrough.import || walkthrough.import === undefined) {
    data.lanes = [walkthroughLane, otherLane].concat(realCategoriesLanes)
  } else {
    data.lanes = [otherLane].concat(realCategoriesLanes)
  }

  // add new category to local state
  const onCreateCategory = (name) => {
    const newCat = {
      name,
      order: data.lanes.length,
      _id: data.lanes.length,
      tags: [],
    }
    setCategories({
      ...categories,
      categoriesArrays: [...categories.categoriesArrays, newCat],
    })
  }

  // remove category from local state
  const onDeleteCategory = (id) => {
    if (id != OTHER_CATEGORY_LANE_ID && id != WALKTHROUGH_CATEGORY_LANE_ID) {
      //not other category
      const newCategories = categories.categoriesArrays.filter(
        (cat) => cat._id !== id
      )
      setCategories({ ...categories, categoriesArrays: newCategories })
    }
  }

  const onRenameCategory = async ({ id, name }) => {
    if (id != OTHER_CATEGORY_LANE_ID && id != WALKTHROUGH_CATEGORY_LANE_ID) {
      // change local state
      const newCategories = categories.categoriesArrays.map((cat) => {
        if (cat._id === id) {
          return { ...cat, name }
        }
        return cat
      })
      setCategories({ ...categories, categoriesArrays: newCategories })
    } else {
      setShowDialogMisc(true)
    }
    //TODO: disable lane rename
  }

  const onCreateTagOnCategory = async ({ tagName, categoryId }) => {
    if (categoryId != OTHER_CATEGORY_LANE_ID) {
      //add to category in local state
      const newCategories = categories.categoriesArrays.map((cat) => {
        if (cat._id === categoryId) {
          return {
            ...cat,
            tags: [...cat.tags, { _id: `new ${tagName}`, name: tagName }],
          }
        }
        return cat
      })
      setCategories({ ...categories, categoriesArrays: newCategories })
    } else {
      //add to other category
      const newCategories = categories.otherCategory.map((cat) => {
        return {
          ...cat,
          tags: [...cat.tags, { _id: `new ${tagName}`, name: tagName }],
        }
      })
      setCategories({ ...categories, otherCategory: newCategories })
    }
  }

  const onChangeTagCategory = async ({
    cardId,
    sourceLaneId,
    targetLaneId,
    position,
    cardDetails,
  }) => {
    //don't do this on walktrough lane
    if (
      sourceLaneId === WALKTHROUGH_CATEGORY_LANE_ID ||
      targetLaneId === WALKTHROUGH_CATEGORY_LANE_ID
    ) {
      return
    }

    if (sourceLaneId != targetLaneId) {
      if (
        sourceLaneId != OTHER_CATEGORY_LANE_ID &&
        targetLaneId != OTHER_CATEGORY_LANE_ID
      ) {
        //handle change category in local state
        setTimeout(() => {
          const newCategories = categories.categoriesArrays.map((cat) => {
            if (cat._id === sourceLaneId) {
              return {
                ...cat,
                tags: cat.tags.filter((tag) => tag._id !== cardId),
              }
            }
            if (cat._id === targetLaneId) {
              return {
                ...cat,
                tags: [...cat.tags, { _id: cardId, name: cardDetails.title }],
              }
            }
            return cat
          })
          setCategories({ ...categories, categoriesArrays: newCategories })
        }, 0)
      } else if (
        sourceLaneId == OTHER_CATEGORY_LANE_ID &&
        targetLaneId != OTHER_CATEGORY_LANE_ID
      ) {
        //add to a category in local state and remove from other category
        setTimeout(() => {
          const newCategories = categories.categoriesArrays.map((cat) => {
            if (cat._id === targetLaneId) {
              return {
                ...cat,
                tags: [...cat.tags, { _id: cardId, name: cardDetails.title }],
              }
            }
            return cat
          })
          const newOtherCategories = categories.otherCategory.map((cat) => {
            return {
              ...cat,
              tags: cat.tags.filter((tag) => tag._id !== cardId),
            }
          })
          setCategories({
            categoriesArrays: newCategories,
            otherCategory: newOtherCategories,
          })
        }, 0)
      } else if (
        sourceLaneId != OTHER_CATEGORY_LANE_ID &&
        targetLaneId == OTHER_CATEGORY_LANE_ID
      ) {
        //remove from a category in local state and add to other category
        setTimeout(() => {
          const newCategories = categories.categoriesArrays.map((cat) => {
            if (cat._id === sourceLaneId) {
              return {
                ...cat,
                tags: cat.tags.filter((tag) => tag._id !== cardId),
              }
            }
            return cat
          })
          const newOtherCategories = categories.otherCategory.map((cat) => {
            return {
              ...cat,
              tags: [...cat.tags, { _id: cardId, name: cardDetails.title }],
            }
          })
          setCategories({
            categoriesArrays: newCategories,
            otherCategory: newOtherCategories,
          })
        }, 0)
      }
    } else {
      //TODO handle reorder tag in the same category
    }
  }

  const handleMoveCat = async ({ removedIndex, addedIndex, categoryId }) => {
    let newOrder

    if (addedIndex == 0) {
      //first category
      newOrder = data.lanes[addedIndex].order - 1
    } else if (addedIndex == data.lanes.length - 1) {
      //last category
      newOrder = data.lanes[addedIndex].order + 1
    } else {
      //somewhere in between - be in the middle
      if (removedIndex > addedIndex) {
        //left
        newOrder =
          (data.lanes[addedIndex].order + data.lanes[addedIndex - 1].order) / 2
      } else {
        //right
        newOrder =
          (data.lanes[addedIndex].order + data.lanes[addedIndex + 1].order) / 2
      }
    }

    //this is the other category and walktrough category
    if (
      FORBIDDEN_IDS.includes(data.lanes[addedIndex].id) ||
      FORBIDDEN_IDS.includes(data.lanes[removedIndex].id)
    ) {
      setShowDialogMisc(true)
    } else {
      //assign the new order value to the category and save to local state
      const newCategories = categories.categoriesArrays.map((cat) => {
        if (cat._id === categoryId) {
          return { ...cat, order: newOrder }
        }
        return cat
      })
      setCategories({ ...categories, categoriesArrays: newCategories })
    }
  }

  const onDeleteTag = (id) => {
    //local delete tag
    const newCategories = categories.categoriesArrays.map((cat) => {
      return { ...cat, tags: cat.tags.filter((tag) => tag._id !== id) }
    })
    const newOtherCategories = categories.otherCategory.map((cat) => {
      return { ...cat, tags: cat.tags.filter((tag) => tag._id !== id) }
    })
    setCategories({
      categoriesArrays: newCategories,
      otherCategory: newOtherCategories,
    })
  }

  const onRenameTag = async (tagName, tagId) => {
    //local rename tag
    const newCategories = categories.categoriesArrays.map((cat) => {
      return {
        ...cat,
        tags: cat.tags.map((tag) => {
          if (tag._id === tagId) {
            return { ...tag, name: tagName }
          }
          return tag
        }),
      }
    })
    const newOtherCategories = categories.otherCategory.map((cat) => {
      return {
        ...cat,
        tags: cat.tags.map((tag) => {
          if (tag._id === tagId) {
            return { ...tag, name: tagName }
          }
          return tag
        }),
      }
    })
    setCategories({
      categoriesArrays: newCategories,
      otherCategory: newOtherCategories,
    })
  }

  return (
    <div className="tag-mgmt-container organize-import-container">
      <div className="tag-mgmt-header">
        <h1 className="h1">{'Let’s Organize!'}</h1>
        {/* <p className="p light"></p> */}
        <p className="p">
          {
            'Create categories, add tags, and let us organize your files for simple, speedy access.'
          }
        </p>
      </div>
      <div className="magic-box">
        <div className="magic-box-icon">
          <img src={MAGIC} alt="magic" />
        </div>
        <div className="magic-box-content">
          <div className="magic-texts">
            <h1 className="h2">{'Smart Suggestions for You'}</h1>
            <p className="p">
              {
                'Create categories, add tags, and let us organize your files for simple, speedy access.'
              }
            </p>
          </div>
          <div className="magic-buttons">
            <MyButton
              variant="contained"
              color="secondary"
              startIcon={<img src={UP} style={{ width: '16px' }} />}
              // on click open link in new tab
              onClick={() =>
                window.open(
                  'https://airtable.com/appFP71Jxsq3jhViA/pagBtxu5rCQs8iQYn/form' +
                    '?prefill_feedback=Satisfied&prefill_Email=' +
                    username
                )
              }
              size={'small'}
            />
            <MyButton
              variant="contained"
              color="secondary"
              startIcon={<img src={DOWN} style={{ width: '16px' }} />}
              onClick={() =>
                window.open(
                  'https://airtable.com/appFP71Jxsq3jhViA/pagBtxu5rCQs8iQYn/form' +
                    '?prefill_feedback=Dissatisfied&prefill_Email=' +
                    username
                )
              }
              size={'small'}
            />
            <MyButton
              variant="contained"
              color="secondary"
              startIcon={<img src={REDO} style={{ width: '16px' }} />}
              size={'small'}
            />
          </div>
        </div>
      </div>
      <div className="tag-mgmt-content-wrapper">
        <Board
          components={components}
          //style={{backgroundColor: "#F1EFEE"}}
          addCardLink="ADD CARD"
          data={data}
          draggable
          //laneDraggable={false}
          editable
          canAddLanes
          editLaneTitle
          onLaneAdd={(event) => onCreateCategory(event.title)}
          onLaneDelete={(id) => onDeleteCategory(id)}
          onLaneUpdate={(laneId, data) =>
            onRenameCategory({ id: laneId, name: data.title })
          }
          onCardAdd={(card, laneId) =>
            onCreateTagOnCategory({ tagName: card.title, categoryId: laneId })
          }
          handleDragEnd={(
            cardId,
            sourceLaneId,
            targetLaneId,
            position,
            cardDetails
          ) =>
            onChangeTagCategory({
              cardId,
              sourceLaneId,
              targetLaneId,
              position,
              cardDetails,
            })
          } //check if it's a new lane and update categories
          handleLaneDragEnd={(removedIndex, addedIndex, payload) =>
            handleMoveCat({ removedIndex, addedIndex, categoryId: payload.id })
          }
          onCardDelete={(cardId, laneId) => {
            onDeleteTag(cardId)
          }}
          onCardUpdate={(laneId, card) => {
            onRenameTag(card.title, card.id)
          }}
        ></Board>
      </div>

      <ActionDialog
        actionName={'Okay'}
        action={() => setShowDialogMisc(false)}
        //cancel={() => setShowDialogMisc(false)}
        question={`Cannot change "Miscellaneous"`}
        comment={
          'This column will hold any new tag you create until you assign it to a category. If empty, it will not show up in the filters.'
        }
        isOpen={showDialogMisc}
      />
    </div>
  )
}

export default Organize
