import { inject, observer } from 'mobx-react'
import SimpleUpload from './SimpleUpload'

export default inject(({ filesStore }) => ({
  simpleUploadFileToS3: filesStore.simpleUploadFileToS3,
  closeUpload: filesStore.closeUpload,
  filesInQ: filesStore.filesInQ,
  isUploadFinished: filesStore.isUploadFinished,
  isUpload: filesStore.isUpload,
  cleanUploadQueue: filesStore.cleanUploadQueue,
}))(observer(SimpleUpload))
