import React from 'react'
import '../cleanup-wizard.scss'
import Dog from '../../../assets/dog_small.png'

const EmptyStateCleanup = ({ currentScreen }) => {
  let msg = ''
  switch (currentScreen) {
    case 'split':
      msg = 'No split suggestions found.'
      break
    case 'merge':
      msg = 'No merge suggestions found.'
      break
    case 'remove':
      msg = 'No remove suggestions found.'
      break
    default:
      break
  }
  return (
    <div className="empty-state-cleanup">
      <img alt="add more files" src={Dog}></img>
      <div>
        {msg}
        <br />
        Maybe add more files?
      </div>
    </div>
  )
}

export default EmptyStateCleanup
