import React from 'react'
import { makeStyles } from '@material-ui/core/styles'
//import SearchByUsers from "../SearchByUsers"
//import SearchByUpload from "../SearchByUpload"
import SearchByType from '../SearchByType'
import SearchByPeople from '../SearchByPeople'
import './FiltersBar.scss'
import './mobile.scss'

const FiltersBar = (props) => {
  const { filterOpen, closeNav, handleShowAll, persons, files } = props

  return (
    <>
      {!!files?.length ? ( //OZ is there a way to know if there are files in ws
        <div className="filters-bar-content">
          <SearchByType partialView />

          {!!persons?.length && (
            <SearchByPeople handleShowAll={handleShowAll} partialView />
          )}
        </div>
      ) : (
        <div>What can we help you find?...</div>
      )}
      {filterOpen && <div className="black-bg-mobile" onClick={closeNav}></div>}
    </>
  )
}

export default FiltersBar
