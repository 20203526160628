import { inject, observer } from 'mobx-react'
import ImportWizard from './ImportWizard'
import './import-wizard.scss'
import './mobile.scss'

export default inject(({ filesStore, orgsWorkspacesStore }) => ({
  closeUpload: filesStore.closeUpload,
  getAllTags: filesStore.getAllTags,
  getAllPersons: filesStore.getAllPersons,
  onReset: filesStore.onReset,
  uploadQIndex: filesStore.uploadQIndex,
  analysisQIndex: filesStore.analysisQIndex,
  isUploadFinished: filesStore.isUploadFinished,
  isAnalsisFinished: filesStore.isAnalsisFinished,
  updateFilters: filesStore.updateFilters,
  getAllFilesUsers: filesStore.getAllFilesUsers,
  getAllFilesExtensions: filesStore.getAllFilesExtensions,
  getOrgLimits: orgsWorkspacesStore.getOrgLimits,
  driveFoldersNames: filesStore.driveFoldersNames,
  createDriveCategories: filesStore.createDriveCategories,
  SyncChosenFiles: filesStore.SyncChosenFiles,
}))(observer(ImportWizard))
