import { inject, observer } from 'mobx-react'
import TagList from './TagList'

export default inject(({ orgsWorkspacesStore, filesStore }) => ({
  isAllowed: orgsWorkspacesStore.isAllowed,
  addOrgTag: filesStore.addOrgTag,
  //allTags: filesStore.allTags,
  tagsList: filesStore.tags,
  allCollections: filesStore.collections,
  createCollection: filesStore.createCollection,

  categories: filesStore.categories,
  preSearchTagResults: filesStore.preSearchTagResults,
  textToPreSearch: filesStore.textToPreSearch,
  onPreSearchChanged: filesStore.onPreSearchChanged,
  resetTagPresearch: filesStore.resetTagPresearch,

  isJustCollectionUser: orgsWorkspacesStore.isJustCollectionUser,
}))(observer(TagList))
