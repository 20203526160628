import React, { useState, useEffect } from 'react'
import UPLOAD from '../../../assets/upload.svg'
import { MyButton } from '../../_MyComponents'
import LinearProgress from '@mui/material/LinearProgress'
import IMAGE from '../../../assets/image.svg'
import { set } from 'mobx'

const Upload = ({
  // from store
  uploadToS3,
  filesInQ = [],
  storeFiles,

  // from parent
  type,
  setMenuState,
}) => {
  const minHashProgress = 0
  const maxHashProgress = 10
  const minAnalysisProgress = 90
  const maxAnalysisProgress = 100

  const [hashProgress, setHashProgress] = useState(minHashProgress)
  const [anlysisProgress, setAnalysisProgress] = useState(minAnalysisProgress)
  const [initial, setInitial] = useState(true)

  useEffect(() => {
    const timer = setInterval(() => {
      setHashProgress((oldProgress) => {
        if (oldProgress === maxHashProgress) {
          return minHashProgress
        }
        const diff = Math.random() * 2
        return Math.min(oldProgress + diff, maxHashProgress)
      })
    }, 1000)

    return () => {
      clearInterval(timer)
    }
  }, [])

  useEffect(() => {
    const timer = setInterval(() => {
      setAnalysisProgress((oldProgress) => {
        if (oldProgress === maxAnalysisProgress) {
          return minAnalysisProgress
        }
        const diff = Math.random() * 2
        return Math.min(oldProgress + diff, maxAnalysisProgress)
      })
    }, 1000)

    return () => {
      clearInterval(timer)
    }
  }, [])

  useEffect(() => {
    if (storeFiles?.length > 0) {
      setInitial(false)
    }
  }, [])

  // input select upload
  const handleUpload = () => {
    const fileInput = document.getElementById('upload-input')
    uploadToS3([...fileInput.files])
    setMenuState(1)
  }

  const formatFileSize = (size) => {
    const newSize = size / 1000
    if (newSize < 1000) {
      return `${newSize.toFixed(2)}KB`
    } else {
      return `${(newSize / 1000).toFixed(2)}MB`
    }
  }

  const files = filesInQ.map((file, idx) => (
    <div className="uploading-file-container" key={file.uniqId}>
      <img src={IMAGE} />
      <div className="content">
        <div className="text">
          <p className="file-name light">{file.name}</p>
          {!file.failed && !file.duplicate && file.progress < 100 && (
            <p className="file-size">
              {formatFileSize(Math.min(file.uploadedSize, file.size)) +
                ' / ' +
                formatFileSize(file.size)}
            </p>
          )}
          {!file.failed && file.duplicate && (
            <p className="file-status">Duplicate (merged)</p>
          )}
          {!file.failed &&
            !file.duplicate &&
            file.progress >= 100 &&
            (!file.analysisProgress || file.analysisProgress < 100) && (
              <p className="file-status">analyzing...</p>
            )}
          {!file.failed &&
            !file.duplicate &&
            file.progress >= 100 &&
            file.analysisProgress >= 100 && (
              <p className="file-status">Done!</p>
            )}
          {file.failed && <p className="file-status">Failed</p>}
        </div>
        {!file.failed && file.progress === 0 && (
          <LinearProgress
            variant="determinate"
            style={{ color: '#FBC02D' }}
            color="inherit"
            value={hashProgress}
          />
        )}
        {!file.failed && file.progress > 0 && file.progress < 100 && (
          <LinearProgress
            variant="determinate"
            style={{ color: '#FBC02D' }}
            color="inherit"
            value={Math.min(
              file.progress > maxHashProgress ? file.progress : maxHashProgress,
              minAnalysisProgress
            )}
          />
        )}
        {!file.failed && file.progress >= 100 && (
          <LinearProgress
            variant="determinate"
            style={{ color: '#FBC02D' }}
            color="inherit"
            value={file.analysisProgress || anlysisProgress}
          />
        )}
        {file.failed && (
          <LinearProgress
            variant="determinate"
            style={{ color: '#CF232E' }}
            color="inherit"
            value={100}
          />
        )}
      </div>
    </div>
  ))

  return (
    <div style={{ width: '100%', height: '100%' }}>
      <div className="upload-container">
        <h1 className="h1">
          Start Uploading {type === 'folders' ? 'Folders' : 'Files'}
        </h1>
        {initial && (
          <p className="p light">
            🚀 We recommend starting with at least 5 files 🚀
          </p>
        )}

        <div className={'dropzone-area'}>
          <div className="flex-row">
            <img src={UPLOAD} alt="upload" />
            <div className="dropzone-text">
              <p className="p">
                Upload {type === 'folders' ? 'folders' : 'files'} or drag and
                drop here
              </p>
              <p className="p light">
                JPG, PNG, or any other format (no size limit)
              </p>
            </div>
          </div>
          <label htmlFor="upload-input">
            <MyButton variant="contained" component="span" color="primary">
              Upload
            </MyButton>
          </label>
        </div>

        <div className="uploading-files-container">{files}</div>
        <input
          accept="*"
          style={{ display: 'none' }}
          webkitdirectory={type === 'folders' ? ' ' : null}
          id="upload-input"
          multiple
          name="file"
          type="file"
          onClick={(e) => {
            e.target.value = ''
          }}
          onChange={(e) => handleUpload(e.target.files)}
        />
      </div>
    </div>
  )
}

export default Upload
