import { inject, observer } from 'mobx-react'
import StartSurvey from './StartSurvey'
import './StartSurvey.scss'
import './mobile.scss'

export default inject(({ filesStore, orgsWorkspacesStore }) => ({
  updateOrganization: orgsWorkspacesStore.updateOrganization,
  addWorkspaceTemplate: orgsWorkspacesStore.addWorkspaceTemplate,
  setWorkspaceDefault: orgsWorkspacesStore.setWorkspaceDefault,
}))(observer(StartSurvey))
