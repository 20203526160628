import React, { useState, useEffect } from 'react'
import '../cleanup-wizard.scss'
import Chip from '@material-ui/core/Chip'
import ArrowForwardIcon from '@material-ui/icons/ArrowForward'
import FormControlLabel from '@material-ui/core/FormControlLabel'
import Checkbox from '@material-ui/core/Checkbox'
import CircularProgress from '@material-ui/core/CircularProgress'
import EmptyStateCleanup from '../EmptyStateCleanup'

const Splits = ({
  tagsToSplit,
  setTagsToSplit,
  getSplitSuggestions,
  currentScreen,
}) => {
  const [isCheckAll, setIsCheckAll] = useState(false)
  const [isLoading, setIsLoading] = useState(true)

  useEffect(() => {
    const getTags = async () => {
      getSplitSuggestions().then((res) => {
        const modifiedData = res
        modifiedData.map((suggestion) => {
          suggestion.id = suggestion.ids[0]
          suggestion.checked = false
        })
        setTagsToSplit(modifiedData)
        setIsLoading(false)
      })
    }

    getTags()
  }, [])

  const handleSelectAll = (e) => {
    setIsCheckAll(!isCheckAll)
    const newList = [...tagsToSplit]
    if (isCheckAll) {
      newList.map((tag) => (tag.checked = false))
    } else {
      newList.map((tag) => (tag.checked = true))
    }
    setTagsToSplit(newList)
  }

  const handleClick = (e) => {
    const { id, checked } = e.target
    const newList = tagsToSplit.map((tag) =>
      tag.id === id ? { ...tag, checked: checked } : tag
    )
    newList.some((tag) => !tag.checked)
      ? setIsCheckAll(false)
      : setIsCheckAll(true)
    setTagsToSplit(newList)
  }

  const splits = tagsToSplit.map(({ id, name, checked, suggestedSplits }) => {
    return (
      <div className="cleanup-row">
        <FormControlLabel
          control={
            <Checkbox
              key={id}
              type="checkbox"
              id={id}
              onChange={handleClick}
              checked={checked}
            />
          }
        />
        <div className="splitter-wrapper">
          <Chip
            className="left-tags"
            style={{
              margin: '2px',
              background: '#bfc4c8' && '1.5px solid #00aceb',
            }}
            size="small"
            label={name}
          />
        </div>
        <ArrowForwardIcon className="cleanup-arrow" />
        <div class="break-column"></div>
        <div class="break-margin-mobile"></div>
        {suggestedSplits.map((suggestion) => {
          return (
            <Chip
              className="right-tags"
              style={{
                margin: '2px',
                background: '#bfc4c8' && '1.5px solid #00aceb',
              }}
              size="small"
              label={suggestion}
            />
          )
        })}
      </div>
    )
  })
  return (
    <div className="split-form">
      {isLoading && (
        <div className="cleanup-spinner">
          <CircularProgress />
        </div>
      )}
      {!isLoading && !splits.length && (
        <EmptyStateCleanup currentScreen={currentScreen} />
      )}
      {!isLoading && splits.length ? (
        <div className="options-container">
          <div className="step-description">
            Here are a few suggestions for folder names you might want to split
            into multiple tags:
          </div>
          <FormControlLabel
            control={
              <Checkbox
                checked={isCheckAll}
                onChange={handleSelectAll}
                name="checkedB"
                color="primary"
              />
            }
            label="Select all"
          />
          <div className="form-options">{splits}</div>
        </div>
      ) : null}
    </div>
  )
}

export default Splits
