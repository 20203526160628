import { observable, decorate, action, configure, runInAction } from 'mobx'
import isEmail from 'validator/lib/isEmail'
import { REGISTER } from '../../util/routes'
import { subscribe } from '../../services/pubsub'
import { dispatch } from '../../services/pubsub'
import Actions from '../../util/actions'
configure({ enforceActions: 'observed' })

class RegisterStore {
  constructor({
    doesUserRegistered,
    token,
    username,
    displayName,
    displayNameErrorMessage,
    password,
    message,
    whenRegisterRouteUrl,
    registerOnProcess,
    showPassword,
    confirmPassword,
    passwordErrorMessage,
    credentialsError,
    usernameErrorMessage,
    confirmPasswordErrorMessage,
  }) {
    this.doesUserRegistered = doesUserRegistered
    this.token = token
    this.username = username
    this.displayName = displayName
    this.password = password
    this.confirmPassword = confirmPassword
    this.message = message
    this.whenRegisterRouteUrl = whenRegisterRouteUrl
    this.registerOnProcess = registerOnProcess
    this.showPassword = showPassword
    this.credentialsError = credentialsError
    this.passwordErrorMessage = passwordErrorMessage
    this.confirmPasswordErrorMessage = confirmPasswordErrorMessage
    this.displayNameErrorMessage = displayNameErrorMessage
    this.usernameErrorMessage = usernameErrorMessage

    this.credentialChange = this.credentialChange.bind(this)
    this.register = this.register.bind(this)
    this.toggleShowPassword = this.toggleShowPassword.bind(this)
    this.doesCredentialsValid = this.doesCredentialsValid.bind(this)
    this.onSignUp = this.onSignUp.bind(this)

    subscribe(REGISTER, ({ email }) => {
      runInAction(() => {
        if (email && isEmail(email)) {
          this.username = email
          this.emailFromInvitation = true
        }
      })
    })
  }

  emailFromInvitation = false

  doesCredentialsValid() {
    const { username, password, confirmPassword, displayName } = this
    //console.log({ username, password, confirmPassword, displayName } )
    if (!username || !isEmail(username)) {
      return {
        valid: false,
        usernameErrorMessage: 'Invalid email.',
      }
    }

    if (!displayName) {
      return {
        valid: false,
        displayNameErrorMessage: 'Missing Full Name',
      }
    } else if (!password || !`${password}`.trim()) {
      return {
        passwordErrorMessage: 'Invalid password.',
        valid: false,
      }
    } else if (password !== confirmPassword) {
      return {
        confirmPasswordErrorMessage: `Confirm password doesn't equal to password.`,
        valid: false,
      }
    }

    return { valid: true }
  }

  onSignUp() {
    this.confirmPasswordErrorMessage = ''
    this.passwordErrorMessage = ''
    this.usernameErrorMessage = ''
    this.displayNameErrorMessage = ''

    const {
      valid,
      confirmPasswordErrorMessage,
      passwordErrorMessage,
      usernameErrorMessage,
      displayNameErrorMessage,
    } = this.doesCredentialsValid()
    if (!valid) {
      runInAction(() => {
        this.confirmPasswordErrorMessage = confirmPasswordErrorMessage
        this.passwordErrorMessage = passwordErrorMessage
        this.usernameErrorMessage = usernameErrorMessage
        this.displayNameErrorMessage = displayNameErrorMessage
      })
      return
    }
    this.register()
  }

  /* @action */
  register() {
    const { username, password, displayName } = this
    runInAction(() => {
      this.registerOnProcess = true
    })
    this.rootStore.apis
      .register({ username, password, displayName })
      .then(async (user) => {
        if (!user) {
          throw new Error('Register failed!')
        }
        const token = await user.getIdToken(false)
        this.rootStore.authenticationStore.storeUserDetails({
          username: user.email,
          token,
          refreshToken: user.refreshToken,
          isAnonymous: false,
        })

        this.rootStore.apis.registerUser({ token }).then((res) => {
          this.rootStore.authenticationStore.getUserState()
          runInAction(() => {
            this.username = ''
            this.displayName = ''
            this.password = ''
            this.confirmPassword = ''
            this.registerOnProcess = false
            this.doesUserRegistered = true
            this.emailFromInvitation = false
            dispatch(Actions.GET_CURRENT_USER)
          })
        })
      })
      .catch((error) => {
        console.error(error)
        runInAction(() => {
          console.log('register store register')
          console.log({ error })
          if (error.code === 'auth/email-already-in-use') {
            this.message =
              "There's already a user under this email address. Try logging in instead using the button below."
          } else {
            this.message = error.message || 'Login failed!'
          }
          this.registerOnProcess = false
        })
      })
  }

  /* @action */
  credentialChange({ username, password, confirmPassword, displayName }) {
    //console.log({ username, password, confirmPassword, displayName } )
    runInAction(() => {
      this.username = username
      this.password = password
      this.confirmPassword = confirmPassword
      this.displayName = displayName
    })
  }

  toggleShowPassword() {
    runInAction(() => {
      this.showPassword = !this.showPassword
    })
  }
}

decorate(RegisterStore, {
  doesUserRegistered: observable,
  username: observable,
  displayName: observable,
  password: observable,
  confirmPassword: observable,
  userInfo: observable,
  message: observable,
  usernameErrorMessage: observable,
  passwordErrorMessage: observable,
  registerOnProcess: observable,
  showPassword: observable,
  userDetails: observable,
  emailFromInvitation: observable,

  /* Actions */
  credentialChange: action,
  register: action,
  toggleShowPassword: action,
  onSignUp: action,
})

export default RegisterStore
