import React, { useEffect } from 'react'
import { MyKeyword } from '../_MyComponents'
import { EXT } from '../SearchByFileType/filesExt'

import linkIcon from '../../assets/link.svg'
import videoIcon from '../../assets/video.svg'
import imageIcon from '../../assets/image.svg'
import vectorIcon from '../../assets/vector.svg'
import docIcon from '../../assets/document.svg'
import audioIcon from '../../assets/audio.svg'

import landscapeIcon from '../../assets/landscape.svg'
import portraitIcon from '../../assets/portrait.svg'
import squareIcon from '../../assets/square.svg'
import NOTAG from '../../assets/no-tag.svg'

const SearchByType = ({
  exts,
  selectedExt,
  selectedFileType,
  onExtSelected,
  onFileTypeSelected,
  partialView,
  selectedDuration,
  onDurationSelected,
  selectedFileSize,
  onFileSizeSelected,
  selectedOrientationMode,
  onOrientationModeSelected,
  selectedResolution,
  onResolutionSelected,
  onUnTaggedChanged,
  unTagged,
}) => {
  const extList = exts.map((ext) => ext._id)
  const types = []
  const images = EXT.images.filter((value) => extList.includes(value))
  const videos = EXT.videos.filter((value) => extList.includes(value))
  const audio = EXT.audio.filter((value) => extList.includes(value))
  const links = EXT.links.filter((value) => extList.includes(value))
  const vectors = EXT.vectors.filter((value) => extList.includes(value))
  // to find 'docs' which are actually 'else'
  const allTypes = EXT.images.concat(
    EXT.videos,
    EXT.audio,
    EXT.links,
    EXT.vectors
  )
  const docs = extList.filter((elem) => !allTypes.includes(elem))

  const onExtClicked = (extension) => {
    const selected = selectedExt.find((ext) => ext === extension)

    if (selected) {
      const newSelectedExt = selectedExt.filter((ext) => ext != extension)
      onExtSelected(newSelectedExt)
    } else {
      const newSelectedExt = selectedExt.concat(extension)
      onExtSelected(newSelectedExt)
    }
  }

  const onFormatClicked = ({ type, extensions }) => {
    const selected = selectedFileType?.name === type.name

    if (selected) {
      onFileTypeSelected([], { name: '' })
    } else {
      onFileTypeSelected(extensions, type)
    }
  }

  const onDurationClicked = ({ name, lte, gte }) => {
    const selected = selectedDuration?.name === name

    if (selected) {
      onDurationSelected({})
    } else {
      onDurationSelected({ name, lte, gte })
    }
  }

  const onFileSizeClicked = ({ name, lte, gte }) => {
    const selected = selectedFileSize?.name === name

    if (selected) {
      onFileSizeSelected({})
    } else {
      onFileSizeSelected({ name, lte, gte })
    }
  }

  const onOrientationModeClicked = (mode) => {
    const selected = selectedOrientationMode.name === mode.name

    if (selected) {
      onOrientationModeSelected({ name: null })
    } else {
      onOrientationModeSelected(mode)
    }
  }

  const onResolutionClicked = (resolution) => {
    const selected = selectedResolution === resolution

    if (selected) {
      onResolutionSelected(null)
    } else {
      onResolutionSelected(resolution)
    }
  }

  const SearchByExt = () => {
    return (
      <div className="filter-section">
        {!!exts.length && (
          <div className="filter-title">
            <h2 className="h2">Format</h2>
          </div>
        )}
        <div className="filter-tags">
          {/* {!!exts?.length && <div className={"title-2"}>File Type</div>} */}
          {exts
            .slice()
            .sort((a, b) => (a.count > b.count ? -1 : 1))
            .slice(0, 9)
            .map((ext, idx) => {
              const { _id, count } = ext
              const selected = selectedExt.find((ext) => ext === _id)
              return (
                <MyKeyword
                  onClick={() => {
                    onExtClicked(_id)
                  }}
                  selected={selected}
                  text={`${_id}`}
                  key={`${_id}`}
                />
              )
            })}
        </div>
      </div>
    )
  }

  const SearchByFormat = () => {
    if (!!images.length) {
      types.push({ name: 'Image', icon: imageIcon, exts: images })
    }
    if (!!videos.length) {
      types.push({ name: 'Video', icon: videoIcon, exts: videos })
    }
    if (!!audio.length) {
      types.push({ name: 'Audio', icon: audioIcon, exts: audio })
    }
    if (!!docs.length) {
      console.log({ name: 'Doc', icon: docIcon, exts: docs })
      types.push({ name: 'Doc', icon: docIcon, exts: docs })
    }
    if (!!links.length) {
      types.push({ name: 'Link', icon: linkIcon, exts: links })
    }
    if (!!vectors.length) {
      types.push({ name: 'Vector', icon: vectorIcon, exts: vectors })
    }

    return (
      <div className="filter-section">
        {!!exts.length && (
          <div className="filter-title">
            <h2 className="h2">Type</h2>
          </div>
        )}
        <div className="filter-tags">
          {types
            .slice()
            .sort((a, b) => (a.count > b.count ? -1 : 1))
            .slice(0, 9)
            .map((format) => {
              //const selected = selectedExt.find((ext) => ext === _id)
              return (
                <MyKeyword
                  onClick={() => {
                    onFormatClicked({
                      type: { name: format.name, icon: format.icon },
                      extensions: format.exts,
                    })
                  }}
                  selected={selectedFileType.name === format.name}
                  text={`${format.name}`}
                  key={`${format.name}`}
                  icon={format.icon}
                />
              )
            })}
        </div>
      </div>
    )
  }

  const SearchByDuration = () => {
    return (
      <div className="filter-section">
        {!!exts.length && (
          <div className="filter-title">
            <h2 className="h2">Video duration</h2>
          </div>
        )}
        <div className="filter-tags">
          <MyKeyword
            onClick={() =>
              onDurationClicked({
                name: 'Up to 1m',
                lte: 1 * 60 * 1000,
                gte: 50,
              })
            }
            selected={selectedDuration?.name === 'Up to 1m'}
            text={`Up to 1m`}
            key={`1m`}
          />
          <MyKeyword
            onClick={() =>
              onDurationClicked({ name: '1m+', gte: 1 * 60 * 1000 })
            }
            selected={selectedDuration?.name === '1m+'}
            text={`1m+`}
            key={`1m+`}
          />
          <MyKeyword
            onClick={() =>
              onDurationClicked({ name: '5m+', gte: 5 * 60 * 1000 })
            }
            selected={selectedDuration?.name === '5m+'}
            text={`5m+`}
            key={`5m+`}
          />
          <MyKeyword
            onClick={() =>
              onDurationClicked({ name: '15m+', gte: 15 * 60 * 1000 })
            }
            selected={selectedDuration?.name === '15m+'}
            text={`15m+`}
            key={`15m+`}
          />
          <MyKeyword
            onClick={() =>
              onDurationClicked({ name: '1h+', gte: 60 * 60 * 1000 })
            }
            selected={selectedDuration?.name === '1h+'}
            text={`1h+`}
            key={`1h+`}
          />
        </div>
      </div>
    )
  }

  const SearchByOrientation = () => {
    return (
      <div className="filter-section">
        {!!exts.length && (
          <div className="filter-title">
            <h2 className="h2">Orientation</h2>
          </div>
        )}

        <div className="filter-tags">
          <MyKeyword
            onClick={() =>
              onOrientationModeClicked({
                name: 'landscape',
                icon: landscapeIcon,
              })
            }
            selected={selectedOrientationMode?.name === 'landscape'}
            text={`Landscape`}
            key={`Landscape`}
            icon={landscapeIcon}
          />
          <MyKeyword
            onClick={() =>
              onOrientationModeClicked({ name: 'portrait', icon: portraitIcon })
            }
            selected={selectedOrientationMode?.name === 'portrait'}
            text={`Portrait`}
            key={`Portrait`}
            icon={portraitIcon}
          />
          <MyKeyword
            onClick={() =>
              onOrientationModeClicked({ name: 'square', icon: squareIcon })
            }
            selected={selectedOrientationMode.name === 'square'}
            text={`Square`}
            key={`Square`}
            icon={squareIcon}
          />
        </div>
      </div>
    )
  }

  const SearchByResolution = () => {
    return (
      <div className="filter-section">
        {!!exts.length && (
          <div className="filter-title">
            <h2 className="h2">Resolution</h2>
          </div>
        )}
        <div className="filter-tags">
          <MyKeyword
            onClick={() => onResolutionClicked('Thumbnail')}
            selected={selectedResolution === 'Thumbnail'}
            text={`Thumbnail`}
            key={`thumbnail`}
          />
          <MyKeyword
            onClick={() => onResolutionClicked('Low-res')}
            selected={selectedResolution === 'Low-res'}
            text={`Low-res`}
            key={`low-res`}
          />
          <MyKeyword
            onClick={() => onResolutionClicked('Medium-res')}
            selected={selectedResolution === 'Medium-res'}
            text={`Medium-res`}
            key={`Medium-res`}
          />
          <MyKeyword
            onClick={() => onResolutionClicked('High-res')}
            selected={selectedResolution === 'High-res'}
            text={`High-res`}
            key={`High-res`}
          />
        </div>
      </div>
    )
  }

  const SearchBySize = () => {
    return (
      <div className="filter-section">
        {!!exts.length && (
          <div className="filter-title">
            <h2 className="h2">File size</h2>
          </div>
        )}
        <div className="filter-tags">
          <MyKeyword
            onClick={() =>
              onFileSizeClicked({
                name: 'Up to 1MB',
                lte: 1 * 1024 * 1024,
                gte: 1,
              })
            }
            selected={selectedFileSize?.name === 'Up to 1MB'}
            text={`Up to 1MB`}
            key={`1MB`}
          />
          <MyKeyword
            onClick={() =>
              onFileSizeClicked({ name: '1MB+', gte: 1 * 1024 * 1024 })
            }
            selected={selectedFileSize?.name === '1MB+'}
            text={`1MB+`}
            key={`1MB+`}
          />
          <MyKeyword
            onClick={() =>
              onFileSizeClicked({ name: '10MB+', gte: 10 * 1024 * 1024 })
            }
            selected={selectedFileSize?.name === '10MB+'}
            text={`10MB+`}
            key={`10MB+`}
          />
          <MyKeyword
            onClick={() =>
              onFileSizeClicked({ name: '100MB+', gte: 100 * 1024 * 1024 })
            }
            selected={selectedFileSize?.name === '100MB+'}
            text={`100MB+`}
            key={`100MB+`}
          />
          <MyKeyword
            onClick={() =>
              onFileSizeClicked({ name: '1GB+', gte: 1 * 1024 * 1024 * 1024 })
            }
            selected={selectedFileSize?.name === '1GB+'}
            text={`1GB+`}
            key={`1GB+`}
          />
        </div>
      </div>
    )
  }

  const SearchByNoTags = () => {
    return (
      <div className="filter-section">
        <h2 className="h2">Tags</h2>
        <div className="filter-tags">
          <MyKeyword
            onClick={onUnTaggedChanged}
            selected={unTagged}
            text="Untagged files"
            icon={NOTAG}
          />
        </div>
      </div>
    )
  }

  return (
    <div className="filter-content-wrapper">
      <SearchByFormat />
      <SearchByExt />
      {!partialView &&
        (!!videos.length || !!selectedDuration?.name?.length) && (
          <SearchByDuration />
        )}
      {!partialView &&
        (!!videos.length ||
          !!images.length ||
          !!selectedOrientationMode?.name?.length) && <SearchByOrientation />}
      {!partialView &&
        (!!videos.length ||
          !!images.length ||
          !!selectedResolution?.length) && <SearchByResolution />}
      {!partialView && <SearchBySize />}
      <SearchByNoTags />
    </div>
  )
}

export default SearchByType
