import UploadTagging from './UploadTagging'
import { inject, observer } from 'mobx-react'

export default inject(({ filesStore, authenticationStore }) => ({
  recentlyUploadedFiles: filesStore.recentlyUploadedFiles,
  allTags: filesStore.allTags,
  onRemoveTag: filesStore.removeFileTag,
  bulkTag: filesStore.bulkTag,
  bulkRemoveTags: filesStore.bulkRemoveTags,
  userState: authenticationStore.userState,
  updateUserState: authenticationStore.updateUserState,
  isAnonymous: authenticationStore.isAnonymous,
}))(observer(UploadTagging))
