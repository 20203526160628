import React, { useState, useRef, useEffect } from 'react'

import Grow from '@mui/material/Grow'
import Paper from '@mui/material/Paper'
import Popper from '@mui/material/Popper'

import ClickAwayListener from '@mui/material/ClickAwayListener'
import KeyboardArrowDownIcon from '@material-ui/icons/KeyboardArrowDown'

import Tooltip from '@material-ui/core/Tooltip'

import { MyButton, MyKeyword } from '../_MyComponents'

import { sortCategory } from '../../util/tags'

import { Steps } from 'intro.js-react'
import { steps3, steps4 } from '../_intro-js'

const TagsFilter = ({
  // from store
  tags,
  files,
  selectedTags,
  onTagSelected,
  onPersonSelected,
  isSearchMode,
  categories,
  querySearch,
  selectedUsers,
  onUserSelected,
  onDateSelected,
  onIsFavoriteChanged,
  onExtSelected,
  onSearchChanged,
  onImageSearch,
  onRemovePeopleFilter,
  userState,
  updateUserState,
  filesInQ,
  isAnonymous,
  onFileTypeSelected,
  onDurationSelected,
  onFileSizeSelected,
  onOrientationModeSelected,
  selectedColors,
  onColorSelected,
  onResolutionSelected,
  onAdvanceColorFilterSelected,
  onUnTaggedChanged,

  // from parent
}) => {
  // intro.js walkthrough
  const [introJS, setIntroJS] = useState(false)

  useEffect(() => {
    if (!filesInQ.length && !userState?.onboarding?.tagsFilter) {
      setTimeout(() => {
        setIntroJS(true)
      }, 2000)
    }
  }, [filesInQ])

  const onCloseIntro = () => {
    setIntroJS(false)
    updateUserState({
      'onboarding.tagsFilter': true,
      'onboarding.upload': true,
    })
  }

  let categoriesArrays = categories?.categoriesArrays
    ? [...categories.categoriesArrays].filter((cat) => {
        return cat.tags.length
      })
    : []
  const otherCategory = categories?.otherCategory
    ? [...categories.otherCategory]
    : []

  otherCategory.length &&
    categoriesArrays.length &&
    categoriesArrays.push({
      name: 'Miscellaneous',
      tags: otherCategory,
    })

  //add tag to category

  const TagChip = ({ tag, idx }) => {
    const { name, _id } = tag
    const selected = !!selectedTags.find((value) => value._id === _id)

    return (
      <MyKeyword
        onClick={(e) => onTagSelected(tag)}
        selected={selected}
        text={`${name}`}
      />
    )
  }

  const moreTags = isSearchMode() ? tags : otherCategory

  const localIsSearchMode = isSearchMode()

  const searchTags = moreTags
    .filter(({ type }) => type !== 'CURATED')
    .slice()
    .filter(
      (tag) =>
        !!tag.count && (localIsSearchMode ? tag.count !== files.length : true)
    )
    .sort(
      (a, b) =>
        b.selected - a.selected ||
        b.count - a.count ||
        a.name.localeCompare(b.name)
    )
    .filter((tag) => !querySearch['queryTags']?.includes(tag.name))
    .map((tag, idx) => <TagChip tag={tag} key={idx} />)

  const removeFilter = (filter) => {
    if (filter.type === 'queryUsers') {
      const tempUsers = selectedUsers.filter((userId) => {
        const tempName = userId.name || userId.username
        return tempName !== filter.name
      })
      onUserSelected(tempUsers)
    } else if (filter.type === 'queryDates') {
      onDateSelected({
        dateRangeType: null,
        endDate: null,
        startDate: null,
      })
    } else if (filter.type === 'queryIsFavorite') {
      onIsFavoriteChanged()
    } else if (filter.type === 'queryExts') {
      onExtSelected([])
    } else if (filter.type === 'queryTags') {
      const clicked = selectedTags.find((value) => value.name === filter.name)
      onTagSelected(clicked)
    } else if (filter.type === 'queryPersons') {
      onPersonSelected(filter.fullFilter)
    } else if (filter.type === 'queryText') {
      onSearchChanged('')
    } else if (filter.type === 'queryImage') {
      onImageSearch('')
    } else if (filter.type === 'queryPeople') {
      onRemovePeopleFilter(filter.name)
    } else if (filter.type === 'queryType') {
      onFileTypeSelected([], { name: '' })
    } else if (filter.type === 'queryDuration') {
      onDurationSelected({})
    } else if (filter.type === 'queryFileSize') {
      onFileSizeSelected({})
    } else if (filter.type === 'queryOrientationMode') {
      onOrientationModeSelected({ name: null })
    } else if (filter.type === 'queryColor') {
      let newSelectedColors = selectedColors.filter(
        (color) => color.name != filter.name
      )
      onColorSelected(newSelectedColors)
    } else if (filter.type === 'queryResolution') {
      onResolutionSelected(null)
    } else if (filter.type === 'queryAdvanceColorFilter') {
      onAdvanceColorFilterSelected({ name: null })
    } else if (filter.type === 'queryIsUnTagged') {
      onUnTaggedChanged()
    }
  }

  let appliedFilters = []
  for (const filterType in querySearch) {
    const tempFilter = querySearch[filterType]?.map((filter, idx) => {
      const displayFilter = {
        name: typeof filter === 'object' ? filter?.name || 'Someone' : filter,
        type: filterType,
        fullFilter: filter,
      }
      return (
        <MyKeyword
          text={displayFilter.name}
          icon={filter?.icon}
          avatar={filter?.defaultFaceLocation || filter?.avatar}
          avatarColor={filter?.avatarColor}
          selected={true}
          key={idx}
          onClick={() => removeFilter(displayFilter)}
        />
      )
    })
    if (tempFilter.length) {
      appliedFilters.push(tempFilter)
    }
  }

  const FilterDropdown = (props) => {
    const [open, setOpen] = useState(false)
    const anchorRef = useRef(null)

    const onTagSelected = props.onTagSelected

    const handleClose = (event) => {
      if (anchorRef.current && anchorRef.current.contains(event.target)) {
        return
      }
      setOpen(false)
    }

    const handleToggle = () => {
      setOpen((prevOpen) => !prevOpen)
    }

    const categoryTagsSorted = sortCategory({ category: props.category }).map(
      (tag) => {
        const selected = !!selectedTags.find((value) => value._id === tag._id)

        const text = `${tag.name}\u00A0\u00A0\u00A0${tag.realCount}`

        return (
          <Tooltip
            placement="top"
            key={`keyword-${text}`}
            enterDelay={1000}
            enterNextDelay={1000}
            title={text}
          >
            <div className="dropdown-option">
              <MyKeyword
                text={tag.name}
                count={tag.realCount}
                disabled={tag.realCount === 0}
                selected={selected}
                onClick={() => onTagSelected(tag)}
              />
            </div>
          </Tooltip>
        )
      }
    )

    return (
      <div style={{ position: 'relative' }}>
        <MyButton
          variant={props.variant || 'contained'}
          ref={anchorRef}
          size="small"
          aria-controls={open ? 'split-button-menu' : undefined}
          aria-expanded={open ? 'true' : undefined}
          aria-label="select merge strategy"
          aria-haspopup="menu"
          onClick={handleToggle}
        >
          {props.category.name}
          <KeyboardArrowDownIcon />
        </MyButton>
        <Popper
          open={open}
          myc
          role={undefined}
          transition
          disablePortal
          className={'tag-popup'}
          style={{ position: 'absolute', top: '40px' }}
        >
          {({ TransitionProps, placement }) => (
            <Grow
              {...TransitionProps}
              style={{
                transformOrigin:
                  placement === 'bottom' ? 'center top' : 'center bottom',
              }}
            >
              <Paper
                style={{
                  boxShadow: '0px 2px 10px rgba(0, 0, 0, 0.25)',
                }}
              >
                <ClickAwayListener onClickAway={handleClose}>
                  <div className="dropdown-options-container">
                    {categoryTagsSorted}
                  </div>
                </ClickAwayListener>
              </Paper>
            </Grow>
          )}
        </Popper>
      </div>
    )
  }

  const categoryItems = categoriesArrays
    ?.slice(0, 20)
    .map((category, idx) => (
      <FilterDropdown
        onTagSelected={onTagSelected}
        category={category}
        variant="outlined"
        color="rgba(0, 0, 0, 0.12)"
        key={idx}
      />
    ))

  //var appliedNames = new Set(searchTags.map(filter => filter.props.tag.name));
  //var suggestions = [...appliedFilters, ...searchTags.filter(filter => !appliedNames.has(filter.props.tag.name))];

  const suggestions = [...appliedFilters, ...searchTags].slice(0, 10)

  return (
    <>
      {!categoriesArrays.length && !suggestions.length ? (
        <></>
      ) : (
        <div className={'tags-filter-root'}>
          <div className="tags-filter-wrapper">
            {/* categories */}
            {categoriesArrays?.length > 0 && (
              <div className={'categories-container'}>{categoryItems}</div>
            )}

            {/* all tags */}
            {(isSearchMode() || !categoriesArrays?.length) &&
              !!suggestions.length && (
                <div className={'frequently-used-container'}>
                  <div className="all-tags">
                    <div className="tags-show">{suggestions}</div>
                  </div>
                </div>
              )}
            {!isAnonymous &&
              userState &&
              !userState?.onboarding?.tagsFilter &&
              !filesInQ.length &&
              !!tags.length && (
                <Steps
                  enabled={introJS}
                  steps={userState?.onboarding?.upload ? steps3 : steps4}
                  initialStep={0}
                  onExit={onCloseIntro}
                  options={{
                    exitOnOverlayClick: false,
                  }}
                />
              )}
          </div>
        </div>
      )}
    </>
  )
}

export default TagsFilter
