import { inject, observer } from 'mobx-react'
import TagsFilter from './TagsFilter'
import './tagsFilter.scss'

export default inject(
  ({ filesStore, authenticationStore, orgsWorkspacesStore }) => ({
    tags: filesStore.tags,
    files: filesStore.files,
    selectedTags: filesStore.selectedTags,
    onTagSelected: filesStore.onTagSelected,
    onPersonSelected: filesStore.onPersonSelected,
    isSearchMode: filesStore.isSearchMode,
    categories: filesStore.categories,
    querySearch: filesStore.querySearch,
    selectedUsers: filesStore.selectedUsers,
    onUserSelected: filesStore.onUserSelected,
    onDateSelected: filesStore.onDateSelected,
    onIsFavoriteChanged: filesStore.onIsFavoriteChanged,
    onExtSelected: filesStore.onExtSelected,
    onSearchChanged: filesStore.onSearchChanged,
    onRemovePeopleFilter: filesStore.onRemovePeopleFilter,
    userState: authenticationStore.userState,
    updateUserState: authenticationStore.updateUserState,
    filesInQ: filesStore.filesInQ,
    isAnonymous: authenticationStore.isAnonymous,
    onFileTypeSelected: filesStore.onFileTypeSelected,
    onDurationSelected: filesStore.onDurationSelected,
    onFileSizeSelected: filesStore.onFileSizeSelected,
    onOrientationModeSelected: filesStore.onOrientationModeSelected,
    selectedColors: filesStore.selectedColors,
    onColorSelected: filesStore.onColorSelected,
    onResolutionSelected: filesStore.onResolutionSelected,
    onAdvanceColorFilterSelected: filesStore.onAdvanceColorFilterSelected,
    onImageSearch: filesStore.onImageSearch,
    onUnTaggedChanged: filesStore.onUnTaggedChanged,
  })
)(observer(TagsFilter))
