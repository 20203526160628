import RootStore from './RootStore'
import AuthenticationStore from '../AuthenticationStore'
import ThirdPartyStore from '../ThirdPartyStore'
import FilesStore from '../FilesStore'
import RegisterStore from '../RegisterStore'
import DropboxImportStore from '../DropboxImportStore'
import OrgsWorkspacesStore from '../OrgsWorkspacesStore'

import storage from '../../services/storage'
import apis from '../../services/apis'
import router from '../../services/router'

export default new RootStore({
  apis,
  router,
  storage,
  window,
  authenticationStore: new AuthenticationStore({
    doesUserAuthenticated: false,
    token: null,
    username: '',
    password: '',
    message: '',
    whenLoginRouteUrl: '/',
    loginOnProcess: false,
    logoutUrl: '/login',
    showPassword: false,
    isAnonymous: false,
  }),
  thirdPartyStore: new ThirdPartyStore(),
  filesStore: new FilesStore({
    widthPercents: 0.8,
    fileContainerWidth: 235,
  }),
  registerStore: new RegisterStore({
    doesUserRegistered: false,
    username: '',
    password: '',
    confirmPassword: '',
    message: '',
    whenRegisterRouteUrl: '/',
    registerOnProcess: false,
    showPassword: false,
    credentialsError: false,
    passwordErrorMessage: '',
    confirmPasswordErrorMessage: '',
    usernameErrorMessage: '',
  }),
  dropboxImportStore: new DropboxImportStore(),
  orgsWorkspacesStore: new OrgsWorkspacesStore(),
})
