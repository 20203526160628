import Home from './Home'
import { inject, observer } from 'mobx-react'
import './style.scss'

export default inject(
  ({ filesStore, orgsWorkspacesStore, authenticationStore }) => ({
    textToSearch: filesStore.textToSearch,
    selectedUsers: filesStore.selectedUsers,
    files: filesStore.files,
    isSearchMode: filesStore.isSearchMode,
    organization: orgsWorkspacesStore.organization,
    orgName: orgsWorkspacesStore.orgName,
    orgId: orgsWorkspacesStore.orgId,
    workspace: orgsWorkspacesStore.workspace,
    workspaceName: orgsWorkspacesStore.workspaceName,
    workspaceId: orgsWorkspacesStore.workspaceId,
    workspaces: orgsWorkspacesStore.workspaces,
    onSearchChanged: filesStore.onSearchChanged,
    onReset: filesStore.onReset,
    uploadToS3: filesStore.uploadToS3,
    username: authenticationStore.username,
    deleteBulkFiles: filesStore.deleteBulkFiles,
    getUsersFromWorkspace: orgsWorkspacesStore.getUsersFromWorkspace,
    getFilesUsers: filesStore.getFilesUsers,
    isAllowed: orgsWorkspacesStore.isAllowed,
    snackbar: filesStore.foundFilesSnackbar,
    tagIsSuggest: filesStore.tagIsSuggest,
    tagSuggestion: filesStore.tagSuggestion,
    tagSuggestIsExist: filesStore.tagSuggestIsExist,
    tagSuggestionCount: filesStore.tagSuggestionCount,
    lastTag: filesStore.lastTag,
    renameTag: filesStore.renameTag,
    cancelTagSuggestions: filesStore.cancelTagSuggestions,
    addToFileViewed: filesStore.addToFileViewed,
    currentCollection: filesStore.currentCollection,
    deleteCollection: filesStore.deleteCollection,
    getCurrentCollection: filesStore.getCurrentCollection,
    getUserState: authenticationStore.getUserState,
    selectedList: filesStore.selectedList,
    setSelectedList: filesStore.setSelectedList,
    planResolvedPercent: orgsWorkspacesStore.planResolvedPercent,
    orgPlan: orgsWorkspacesStore.orgPlan,
    isAnonymous: authenticationStore.isAnonymous,
    isBulkDownloading: filesStore.isBulkDownloading,
    mergePersons: filesStore.mergePersons,
    duplicatePersons: filesStore.duplicatePersons,
    view: filesStore.mainView,
    setView: filesStore.setMainView,
    isSettingsDialogOpen: filesStore.settingsDialogView,
    setIsSettingsDialogOpen: filesStore.setSettingsDialogView,
    selectAllFilteredFiles: filesStore.selectAllFilteredFiles,
    syncJob: filesStore.syncJob,
    closeSyncJob: filesStore.closeSyncJob,
    driveFilesCount: filesStore.driveFilesCount,
    orgInfo: orgsWorkspacesStore.orgInfo,
    orgPlanLimits: orgsWorkspacesStore.orgPlanLimits,

    // to remove
    driveFoldersNames: filesStore.driveFoldersNames,
  })
)(observer(Home))
