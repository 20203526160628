import React, { useState, useEffect } from 'react'
import '../cleanup-wizard.scss'
import Chip from '@material-ui/core/Chip'
import FormControlLabel from '@material-ui/core/FormControlLabel'
import Checkbox from '@material-ui/core/Checkbox'
import Switch from '@material-ui/core/Switch'
import EmptyStateCleanup from '../EmptyStateCleanup'
import CircularProgress from '@material-ui/core/CircularProgress'

const Removes = ({
  getAllTags,
  tagsToRemove,
  setTagsToRemove,
  currentScreen,
}) => {
  const [isCheckAll, setIsCheckAll] = useState(false)
  const [isLoading, setIsLoading] = useState(true)
  const [allTags, setAllTags] = useState(true)

  useEffect(() => {
    getAllTags({}).then((tags) => {
      setTags(tags, 3)
      setAllTags(tags)
      setIsLoading(false)
    })
  }, [])

  const setTags = (tags, limit = false) => {
    setTagsToRemove(
      tags
        .filter((tag) => (limit ? tag.count <= limit : tag))
        .map((tag) => {
          return {
            name: tag.name,
            count: tag.count,
            id: tag._id,
            checked: false,
          }
        })
        .sort((tagA, tagB) => tagB.count - tagA.count)
    )
  }

  const handleSelectAll = (e) => {
    setIsCheckAll(!isCheckAll)
    const newList = [...tagsToRemove]
    if (isCheckAll) {
      newList.map((tag) => (tag.checked = false))
    } else {
      newList.map((tag) => (tag.checked = true))
    }
    setTagsToRemove(newList)
  }

  const handleClick = (e) => {
    const { id, checked } = e.target
    const newList = tagsToRemove.map((tag) =>
      tag.id === id ? { ...tag, checked: checked } : tag
    )
    newList.some((tag) => !tag.checked)
      ? setIsCheckAll(false)
      : setIsCheckAll(true)
    setTagsToRemove(newList)
  }

  const switchAllTags = (e) =>
    e.target.checked ? setTags(allTags) : setTags(allTags, 3)

  const removes = tagsToRemove.map(({ id, name, count, checked }) => {
    return (
      <div className="cleanup-row">
        <FormControlLabel
          control={
            <Checkbox
              key={id}
              type="checkbox"
              id={id}
              onChange={handleClick}
              checked={checked}
            />
          }
        />
        <div className="remover-wrapper">
          <Chip
            className="left-tags"
            style={{
              margin: '2px',
              background: '#bfc4c8' && '1.5px solid #00aceb',
            }}
            size="small"
            label={name + ` (${count})`}
          />
        </div>
      </div>
    )
  })
  return (
    <div className="remove-form">
      {isLoading && (
        <div className="cleanup-spinner">
          <CircularProgress />
        </div>
      )}
      {!isLoading && !tagsToRemove.length && (
        <EmptyStateCleanup currentScreen={currentScreen} />
      )}
      {!isLoading && tagsToRemove.length ? (
        <div className="options-container">
          <div className="step-description">
            <div>
              Tags with under 3 files often lead to clutter. We suggest only
              keeping them if you expect to have more files under the same tag
              soon.
            </div>
            <FormControlLabel
              value="all"
              control={<Switch color="primary" />}
              label="All tags"
              labelPlacement="bottom"
              onChange={switchAllTags}
            />
          </div>
          <FormControlLabel
            control={
              <Checkbox
                checked={isCheckAll}
                onChange={handleSelectAll}
                name="checkedB"
                color="primary"
              />
            }
            label="Select all"
          />
          <div className="form-options">{removes}</div>
        </div>
      ) : null}
    </div>
  )
}

export default Removes
