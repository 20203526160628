import './services'
import React, { Suspense } from 'react'
import ReactDOM from 'react-dom'
import { Provider } from 'mobx-react'
import './services/fetchInterceptService'
import './index.css'
import App from './components/App'
import * as serviceWorker from './serviceWorker'
import appState from './store/RootStore'
import { subscribe } from './services/pubsub'
import logger from './services/logger'
import * as Sentry from '@sentry/react'
import { BrowserTracing } from '@sentry/tracing'

subscribe('*', logger.log)

const PROD = process.env.REACT_APP_NODE_ENV === 'production'

if (PROD) {
  Sentry.init({
    dsn: 'https://9aa3eca617d34c7e8f22701c8bab0ae8@o4504197122883584.ingest.sentry.io/4504197125046272',
    integrations: [new BrowserTracing()],

    // Set tracesSampleRate to 1.0 to capture 100%
    // of transactions for performance monitoring.
    // We recommend adjusting this value in production
    tracesSampleRate: 1.0,
  })
}

ReactDOM.render(
  <>
    <Suspense fallback={<div>Loading...</div>}>
      <Provider {...appState}>
        <App />
      </Provider>
    </Suspense>
  </>,
  document.getElementById('root')
)

serviceWorker.unregister()
