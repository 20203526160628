import { inject, observer } from 'mobx-react'
import CollectionPermissionsDialog from './CollectionPermissionsDialog'
import './CollectionPermissionsDialog.scss'
import './mobile.scss'

export default inject(
  ({ filesStore, orgsWorkspacesStore, authenticationStore }) => ({
    ORG_ROLES: orgsWorkspacesStore.ORG_ROLES,
    currentCollection: filesStore.currentCollection,
    updateCollectionLocation: filesStore.updateCollectionLocation,
    getCurrentCollection: filesStore.getCurrentCollection,
    ownUsername: authenticationStore.username,
    inviteToCollection: filesStore.inviteToCollection,
    removeFromCollection: filesStore.removeFromCollection,
    updateUserRoleInCollection: filesStore.updateUserRoleInCollection,
    workspaceUsers: orgsWorkspacesStore.workspaceUsers,
    orgPlan: orgsWorkspacesStore.orgPlan,
    orgPlanLimits: orgsWorkspacesStore.orgPlanLimits,
    setSettingsDialogView: filesStore.setSettingsDialogView,
    isAllowed: orgsWorkspacesStore.isAllowed,
  })
)(observer(CollectionPermissionsDialog))
