import { inject, observer } from 'mobx-react'
import SearchByPeople from './SearchByPeople'
import './SearchByPeople.scss'

export default inject(
  ({ filesStore, authenticationStore, orgsWorkspacesStore }) => ({
    people: filesStore.people,
    persons: filesStore.persons,
    onPeopleSelected: filesStore.onPeopleSelected,
    onPersonSelected: filesStore.onPersonSelected,
    selectedPersons: filesStore.selectedPersons,
    isAnonymous: authenticationStore.isAnonymous,
    workspaceType: orgsWorkspacesStore.workspaceType,
  })
)(observer(SearchByPeople))
