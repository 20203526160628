//https://github.com/rcdexta/react-trello/blob/31023778e4e1369b9223800411060427f387fb0a/src/components/Card.js
import React, { Component } from 'react'
import PropTypes from 'prop-types'
import './TagCard.scss'
import DeleteOutlineIcon from '@mui/icons-material/DeleteOutline'
import { MyButton } from '../../_MyComponents'

import { MovableCardWrapper, CardTitle } from '../MyGlobalStyle'
import InlineInput from 'react-trello/dist/widgets/InlineInput'

class TagCard extends Component {
  onDelete = (e) => {
    this.props.onDelete()
    e.stopPropagation()
  }

  render() {
    const {
      showDeleteButton,
      style,
      onClick,
      onDelete,
      onChange,
      className,
      id,
      title,
      count,
      label,
      description,
      cardDraggable,
      editable,
      walkthrough,
      dismissWalkthrough,
      t,
    } = this.props

    const updateCard = (card) => {
      onChange({ ...card, id })
    }

    return (
      <MovableCardWrapper
        data-id={id}
        onClick={onClick}
        style={style}
        className={className}
      >
        {!walkthrough && (
          <CardTitle draggable={cardDraggable}>
            {editable ? (
              <InlineInput
                value={title}
                border
                placeholder={t('placeholder.title')}
                resize="vertical"
                onSave={(value) => updateCard({ title: value })}
              />
            ) : (
              title
            )}
            <p className="card-tag-count">{count}</p>
          </CardTitle>
        )}
        {!walkthrough && (
          <DeleteOutlineIcon
            className="card-delete-icon"
            fontSize="small"
            onClick={this.onDelete}
          />
        )}
        {walkthrough && (
          <div className="walkthrough-container">
            <iframe
              src="https://www.youtube.com/embed/FYxih9C37Vk"
              title="YouTube video player"
              frameBorder="0"
              allow="fullscreen; accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
              allowFullScreen
            ></iframe>
            <p className="p" style={{ margin: '16px 0 8px 0' }}>
              Tags you create will show up here. Group your tags using drag and
              drop or change them one by one.
            </p>
            <MyButton
              variant="contained"
              color="primary"
              onClick={dismissWalkthrough}
              sx={{ alignSelf: 'flex-start' }}
              size="small"
            >
              Got it!
            </MyButton>
          </div>
        )}
      </MovableCardWrapper>
    )
  }
}

TagCard.propTypes = {
  showDeleteButton: PropTypes.bool,
  onDelete: PropTypes.func,
  onClick: PropTypes.func,
  style: PropTypes.object,
  className: PropTypes.string,
  id: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
  count: PropTypes.number.isRequired,
  label: PropTypes.string,
  description: PropTypes.string,
  walkthrough: PropTypes.bool,
  dismissWalkthrough: PropTypes.func,
}

TagCard.defaultProps = {
  showDeleteButton: true,
  onDelete: () => {},
  onClick: () => {},
  style: {},
  //title: 'Bob',
  count: 0,
  description: '',
  label: '',
  className: '',
  walkthrough: false,
  dismissWalkthrough: () => {},
}

export default TagCard
