import React from 'react'
import { makeStyles } from '@material-ui/core/styles'
import Button from '@material-ui/core/Button'
import TextField from '@material-ui/core/TextField'
import './style.scss'

const useStyles = makeStyles((theme) => ({
  formControl: {
    width: '300px',
    display: 'block',
    padding: '15px',
    margin: '50px auto',
    background: '#f3f3f3',
    border: '1px solid #d6d6d6',
    borderRadius: '3px',
  },
  button: {
    display: 'block',
    width: '100%',
    marginTop: '20px',
  },
  input: {
    width: '100%',
    background: 'white',
  },
  title: {
    paddingBottom: '15px',
    fontWeight: 'bold',
    fontSize: '22px',
  },
  register: {
    textAlign: 'center',
    padding: '10px 0',
    textDecoration: 'underline',
    cursor: 'pointer',
  },
  logo: {
    textAlign: 'center',
    fontSize: '32px',
    color: 'grey',
    marginTop: '20px',
  },
}))

const ResetPassword = ({ resetPassword, history }) => {
  const classes = useStyles()
  const [email, setEmail] = React.useState({ value: '', valid: false })
  const [emailError, setEmailError] = React.useState('')
  const [successReset, setSuccessReset] = React.useState(false)

  const handleChange = (e) => {
    const data = { value: e.target.value, valid: e.target.checkValidity() }
    setEmail({ ...data })
  }

  const reset = () => {
    if (email?.valid) {
      resetPassword(email.value)
        .then((res) => {
          setSuccessReset(true)
        })
        .catch((error) => {
          setEmailError('This email does not have an existing account.')
        })
    } else {
      setEmailError('Email is invalid')
    }
  }

  const goToLogin = () => {
    history.push('/login')
  }

  return (
    <div className={classes.login + ' login-form'}>
      <div className={classes.logo}>Tagbox</div>
      {successReset ? (
        <div className={classes.formControl}>
          <div className="success-reset-title">
            Check your inbox for the link to reset your password.
          </div>
          <div className="back-button" onClick={goToLogin}>
            back to login
          </div>
        </div>
      ) : (
        <div className={classes.formControl}>
          <div className={classes.title}>Forgot password</div>
          <TextField
            className={classes.input}
            id="outlined-basic"
            label="Email"
            type="email"
            variant="outlined"
            value={email.value}
            onChange={handleChange}
          />
          {emailError && <div style={{ color: 'red' }}>{emailError}</div>}
          <Button
            variant="contained"
            color="primary"
            disabled={!email.valid}
            className={classes.button}
            onClick={reset}
          >
            Reset password
          </Button>
        </div>
      )}
    </div>
  )
}

export default ResetPassword
