import { inject, observer } from 'mobx-react'
import BulkEdit from './BulkEdit'

export default inject(({ filesStore, orgsWorkspacesStore }) => ({
  isAllowed: orgsWorkspacesStore.isAllowed,
  role: orgsWorkspacesStore.role,
  allTags: filesStore.allTags,
  bulkTag: filesStore.bulkTag,
  bulkAddCollections: filesStore.bulkAddCollections,
  getAllTags: filesStore.getAllTags,
  getAllCollections: filesStore.getAllTags,
  bulkRemoveTags: filesStore.bulkRemoveTags,
  setSelectedList: filesStore.setSelectedList,
}))(observer(BulkEdit))
