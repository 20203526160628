import React, { useEffect } from 'react'

const CreateWorkspace = ({ workspaceName, addWorkspace }) => {
  useEffect(() => {
    addWorkspace({ workspaceName })
  }, [])
  return (
    <></>
    // <div className={classes.root}>
    //   <TopBar disable={true} />
    //   <div className="title"></div>
    //   <div className="formControl">
    //     {/* <TextField className="input" label="Full name" variant="outlined" /> */}
    //     <TextField className="input" value={workspaceName} label="Workspace name" variant="outlined" onChange={(event) => {
    //       workspaceNameChange(event.target.value)
    //     }} />
    //     <Button className="button" variant="contained" color="primary" disabled={workspaceName.length < 2} onClick={() => {
    //       addWorkspace({ workspaceName })
    //     }}>Continue</Button>
    //   </div>
    // </div>
  )
}

export default CreateWorkspace
