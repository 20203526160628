import React, { useEffect } from 'react'
import { makeStyles } from '@material-ui/core/styles'
import MuTabs from '@material-ui/core/Tabs'
import Tab from '@material-ui/core/Tab'
import ToggleButton from '@material-ui/lab/ToggleButton'
import { RULES } from '../../store/OrgsWorkspacesStore/permissions'

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    justifyItems: 'center',
    justifyContent: 'center',
    alignItems: 'center',
    flexGrow: 0,
    flexShrink: 0,
    backgroundColor: '#474747',
    color: 'white',
    width: '100%',
    zIndex: 2,
    minHeight: '40px',
  },
  tabs: {
    minHeight: '40px',
    width: '100%',
  },
  rootTab: {
    padding: 0,
    minHeight: '40px',
  },
  editToggle: {
    position: 'absolute',
    right: '24px',
    color: 'white',
    border: '1px solid white',
    padding: '4px 10px',
    '&:hover': {
      backgroundColor: '#666',
    },
  },
}))

const Tabs = (props) => {
  const {
    orgName,
    changeView,
    onReset,
    changeValue,
    editMode,
    setEditMode,
    isAllowed,
    fileCount,
  } = props
  const classes = useStyles()
  const [value, setValue] = React.useState(0)
  const [tabs, setTabs] = React.useState([])

  useEffect(() => {
    setTabs(
      orgName === 'israaid' ? ['Collections', 'All'] : ['All', 'Collections']
    )
  }, [orgName])

  useEffect(() => {
    setValue(0)
  }, [changeValue])

  const handleChange = (event, newValue) => {
    onReset()
    changeView(tabs[newValue])
    setValue(newValue)
  }

  return (
    <div className={classes.root + ' tabs-mobile'}>
      <MuTabs
        value={value}
        onChange={handleChange}
        className={classes.tabs}
        centered
        aria-label="simple tabs example"
      >
        {tabs.map((tab) => (
          <Tab key={tab} label={tab} className={classes.rootTab} />
        ))}
      </MuTabs>
      {!!isAllowed(RULES.EDIT_TAGS) && !!fileCount && (
        <ToggleButton
          value={editMode}
          onChange={() => setEditMode(!editMode)}
          size="small"
          className={`edit-toggle ${classes.editToggle}`}
        >
          {editMode ? 'Edit mode' : 'View mode'}
        </ToggleButton>
      )}
    </div>
  )
}

export default Tabs
