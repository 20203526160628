import DriveFolders from './DriveFolders'
import { inject, observer } from 'mobx-react'
import './style.scss'

export default inject(({ filesStore }) => ({
  SyncChosenDirectories: filesStore.SyncChosenDirectories,
  SyncChosenFiles: filesStore.SyncChosenFiles,
  getFilesFiltered: filesStore.getFilesFiltered,
  getAllTags: filesStore.getAllTags,
  access_token: filesStore.driveAccessToken,
  drives: filesStore.drives,
}))(observer(DriveFolders))
