import React, { useEffect, useState } from 'react'
import { useLocation } from 'react-router-dom'
import clsx from 'clsx'
import { makeStyles } from '@material-ui/core/styles'
import FormControl from '@material-ui/core/FormControl'
import Typography from '@material-ui/core/Typography'
import IconButton from '@material-ui/core/IconButton'
import OutlinedInput from '@material-ui/core/OutlinedInput'
import InputLabel from '@material-ui/core/InputLabel'
import InputAdornment from '@material-ui/core/InputAdornment'
import TextField from '@material-ui/core/TextField'
import Visibility from '@material-ui/icons/Visibility'
import VisibilityOff from '@material-ui/icons/VisibilityOff'
import history from '../../services/history'
import logo from '../../assets/logo.svg'
import { MyButton } from '../_MyComponents'
import { MuiThemeProvider } from '@material-ui/core/styles'
import { theme } from '../_MyComponents'
import SIGNUP from '../../assets/signup.jpg'
import './Login.scss'
import GOOGLE from '../../assets/google-icon.svg'

const communityclipsLogo =
  'https://s3.eu-west-1.amazonaws.com/tagbox.public.bucket/whitelabel/Community_Clips_Logo2.jpg'

const communityclipsCoverImage =
  'https://s3.eu-west-1.amazonaws.com/tagbox.public.bucket/whitelabel/community_clips_1x1_login_page_image.jpg'

const useStyles = makeStyles((theme) => ({
  input: {
    width: '100%',
    background: 'white',
  },
  title: {
    paddingBottom: '15px',
    fontWeight: 'bold',
    fontSize: '22px',
  },
  register: {
    textAlign: 'center',
    padding: '10px 0',
    textDecoration: 'underline',
    marginTop: '20px',
    cursor: 'pointer',
  },
  logo: {
    textAlign: 'center',
    fontSize: '32px',
    color: 'grey',
    marginTop: '20px',
  },
}))

// A custom hook that builds on useLocation to parse
// the query string for you.
function useQuery() {
  //console.log("location" ,useLocation())
  return new URLSearchParams(useLocation().search)
}

const Login = ({
  login,
  sso_login,
  credentialChange,
  password,
  message,
  username,
  showPassword,
  toggleShowPassword,
  loginOnProcess,
  emailFromInvitation,
  googleLogin,
  HITLogin,
  travelcounsellorsLogin,
  bonareaLogin,
}) => {
  const classes = useStyles()
  const [isReady, setIsReady] = useState(false)
  const [isSSOLogin, setIsSSOLogin] = useState(false)
  const [isTCsso, setIsTCsso] = useState(false)
  const [isBonareasso, setIsBonareasso] = useState(false)
  const [isHIT, setIsHIT] = useState(false)
  const [isCommunityClips, setIsCommunityClips] = useState(false)

  let query = useQuery()

  useEffect(() => {
    let iv = query.get('iv')
    let content = query.get('content')
    let saml = query.get('saml')

    var location_origin = window.location.origin

    const getSSO = () => {
      if (iv) {
        sso_login({ iv, content })
      } else if (location_origin.includes('tc.tagbox.io')) {
        //  travelcounsellorsLogin()
        setIsSSOLogin(true)
        setIsTCsso(true)
      } else if (location_origin.includes('bonarea.tagbox.io')) {
        //bonareaLogin()
        setIsSSOLogin(true)
        setIsBonareasso(true)
      } else if (location_origin.includes('hit.tagbox.io')) {
        //HITLogin()
        setIsSSOLogin(true)
        setIsHIT(true)
      } else if (location_origin.includes('communityclips.com')) {
        setIsCommunityClips(true)
      } else if (saml) {
        if (saml === 'hit') {
          setIsSSOLogin(true)
          setIsHIT(true)
          //HITLogin()
        } else if (saml === 'travelcounsellors') {
          setIsSSOLogin(true)
          setIsTCsso(true)
          //travelcounsellorsLogin()
        } else if (saml === 'bonarea') {
          setIsSSOLogin(true)
          setIsBonareasso(true)
          //bonareaLogin()
        } else if (saml === 'communityclips') {
          setIsCommunityClips(true)
        } else {
          setIsReady(true)
        }
      } else {
        setIsReady(true)
      }
    }

    getSSO()
  }, [])

  const handleEnter = (e) => {
    if (e.keyCode == 13) {
      login()
    }
  }

  const ssoLogin = () => {
    if (isTCsso) {
      travelcounsellorsLogin()
    } else if (isBonareasso) {
      bonareaLogin()
    } else if (isHIT) {
      HITLogin()
    }
  }

  return (
    <MuiThemeProvider theme={theme}>
      <div className="signup-wrapper">
        {!isCommunityClips && <img src={SIGNUP} className="signup-image" />}
        {!!isCommunityClips && (
          <img src={communityclipsCoverImage} className="signup-image" />
        )}
        <div className={'login-form'}>
          {!!isReady && <img src={logo} className="logo" />}
          {!!isReady && (
            <div className="form-container">
              <h1 className="h1">Login</h1>
              <TextField
                className="login-input"
                id="outlined-basic"
                label="Email"
                variant="outlined"
                value={username}
                disabled={emailFromInvitation}
                onChange={(e) => {
                  credentialChange({ username: e.target.value, password })
                }}
                onKeyDown={(e) => handleEnter(e)}
              />
              <FormControl
                className={clsx(classes.margin, classes.input)}
                variant="outlined"
              >
                <InputLabel htmlFor="outlined-adornment-password">
                  Password
                </InputLabel>
                <OutlinedInput
                  id="outlined-adornment-password"
                  type={showPassword ? 'text' : 'password'}
                  value={password}
                  onChange={(e) => {
                    credentialChange({ password: e.target.value, username })
                  }}
                  onKeyDown={(e) => handleEnter(e)}
                  endAdornment={
                    <InputAdornment position="end">
                      <IconButton
                        aria-label="toggle password visibility"
                        onClick={toggleShowPassword}
                        onMouseDown={toggleShowPassword}
                        edge="end"
                      >
                        {showPassword ? <Visibility /> : <VisibilityOff />}
                      </IconButton>
                    </InputAdornment>
                  }
                  labelWidth={70}
                />
              </FormControl>
              <div className="login-error">
                {' '}
                {message && <Typography color="error">{message}</Typography>}
              </div>
              <div
                className="forgot-password-button"
                onClick={() => history.push('/reset-password')}
              >
                Forgot password?
              </div>
              <MyButton
                variant="contained"
                color="primary"
                className="button"
                onClick={login}
              >
                login
              </MyButton>
              {loginOnProcess && <div>Login...</div>}
              <div className="other-login-title">
                <span>or</span>
              </div>
              <MyButton
                variant="contained"
                color="secondary"
                className="button"
                startIcon={<img src={GOOGLE} style={{ width: '20px' }} />}
                onClick={googleLogin}
              >
                Sign in with Google
              </MyButton>
              <div
                className={classes.register}
                onClick={() => {
                  history.push('/register' + history.location.search)
                }}
              >
                No account yet? Sign up
              </div>
            </div>
          )}
          {!!isSSOLogin && <img src={logo} className="logo" />}
          {!!isSSOLogin && (
            <div className="form-container">
              {!!isTCsso && (
                <h1 className="h1">Login with Travel Counsellors</h1>
              )}
              {!!isBonareasso && <h1 className="h1">Login with Bonarea</h1>}
              {!!isHIT && <h1 className="h1">Login with HIT</h1>}
              <div className="login-error">
                {' '}
                {message && <Typography color="error">{message}</Typography>}
              </div>
              <MyButton
                variant="contained"
                color="primary"
                className="button"
                onClick={ssoLogin}
              >
                login
              </MyButton>
              {loginOnProcess && <div>Login...</div>}
            </div>
          )}
          {!!isCommunityClips && (
            <img src={communityclipsLogo} className="logo" />
          )}
          {!!isCommunityClips && (
            <div className="form-container">
              <h1 className="h1">Login</h1>
              <TextField
                className="login-input"
                id="outlined-basic"
                label="Email"
                variant="outlined"
                value={username}
                disabled={emailFromInvitation}
                onChange={(e) => {
                  credentialChange({ username: e.target.value, password })
                }}
                onKeyDown={(e) => handleEnter(e)}
              />
              <FormControl
                className={clsx(classes.margin, classes.input)}
                variant="outlined"
              >
                <InputLabel htmlFor="outlined-adornment-password">
                  Password
                </InputLabel>
                <OutlinedInput
                  id="outlined-adornment-password"
                  type={showPassword ? 'text' : 'password'}
                  value={password}
                  onChange={(e) => {
                    credentialChange({ password: e.target.value, username })
                  }}
                  onKeyDown={(e) => handleEnter(e)}
                  endAdornment={
                    <InputAdornment position="end">
                      <IconButton
                        aria-label="toggle password visibility"
                        onClick={toggleShowPassword}
                        onMouseDown={toggleShowPassword}
                        edge="end"
                      >
                        {showPassword ? <Visibility /> : <VisibilityOff />}
                      </IconButton>
                    </InputAdornment>
                  }
                  labelWidth={70}
                />
              </FormControl>
              <div className="login-error">
                {' '}
                {message && <Typography color="error">{message}</Typography>}
              </div>
              <div
                className="forgot-password-button"
                onClick={() => history.push('/reset-password')}
              >
                Forgot password?
              </div>
              <MyButton
                variant="contained"
                color="primary"
                className="button"
                onClick={login}
              >
                login
              </MyButton>
              {loginOnProcess && <div>Login...</div>}
              <div
                className={classes.register}
                onClick={() => {
                  history.push('/register' + history.location.search)
                }}
              >
                No account yet? Sign up
              </div>
            </div>
          )}
        </div>
      </div>
    </MuiThemeProvider>
  )
}

export default Login
