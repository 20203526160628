import { inject, observer } from 'mobx-react'
import TableView from './TableView'
import './style.scss'

export default inject(({ filesStore }) => ({
  categories: filesStore.categories,
  setSelectedList: filesStore.setSelectedList,
  selectedList: filesStore.selectedList,
  files: filesStore.files,
  filesCount: filesStore.filesCount,
  onAddTag: filesStore.addFileTag,
  onRemoveTag: filesStore.removeFileTag,
  allTags: filesStore.allTags,
  addToFileViewed: filesStore.addToFileViewed,
  updateFileName: filesStore.updateFileName,
  FILES_IN_PAGE: filesStore.FILES_IN_PAGE,
  getFilesFiltered: filesStore.getFilesFiltered,
  updateFileDescription: filesStore.updateFileDescription,
  currentFilesPage: filesStore.currentFilesPage,
  setTablePage: filesStore.setTablePage,
}))(observer(TableView))
