import { inject, observer } from 'mobx-react'
import Persons from './Persons'
import './Persons.scss'

export default inject(({ filesStore, authenticationStore }) => ({
  persons: filesStore.persons,
  duplicatePersons: filesStore.duplicatePersons,
  PERSONS_IN_PAGE: filesStore.PERSONS_IN_PAGE,
  getAllPersons: filesStore.getAllPersons,
}))(observer(Persons))
