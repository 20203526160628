import { inject, observer } from 'mobx-react'
import SearchByUpload from './SearchByUpload'
import './SearchByUpload.scss'

export default inject(({ filesStore }) => ({
  users: filesStore.users,
  selectedUsers: filesStore.selectedUsers,
  onUserSelected: filesStore.onUserSelected,
  popularUsers: filesStore.popularUsers,

  onDateSelected: filesStore.onDateSelected,
  startDate: filesStore.startDate,
  endDate: filesStore.endDate,
  dateRangeType: filesStore.dateRangeType,
}))(observer(SearchByUpload))
