import Drive from './Drive'
import { inject, observer } from 'mobx-react'
import './style.scss'

export default inject(({ orgsWorkspacesStore, filesStore }) => ({
  org: orgsWorkspacesStore.org,
  isSearchMode: filesStore.isSearchMode,
  syncFiles: filesStore.syncFiles,
  reset: filesStore.onReset,
}))(observer(Drive))
