import { inject, observer } from 'mobx-react'
import PlanProgress from './PlanProgress'

export default inject(({ orgsWorkspacesStore, filesStore }) => ({
  planResolvedPercent: orgsWorkspacesStore.planResolvedPercent,
  totalFileSize: orgsWorkspacesStore.totalFileSize,
  totalFileCount: orgsWorkspacesStore.totalFileCount,
  orgPlanLimits: orgsWorkspacesStore.orgPlanLimits,
  orgPlan: orgsWorkspacesStore.orgPlan,
}))(observer(PlanProgress))
