import { inject, observer } from 'mobx-react'
import Login from './Login'

export default inject(({ authenticationStore }) => ({
  login: authenticationStore.login,
  anonymouseLogin: authenticationStore.anonymouseLogin,
  sso_login: authenticationStore.sso_login,
  credentialChange: authenticationStore.credentialChange,
  username: authenticationStore.username,
  password: authenticationStore.password,
  resetPassword: authenticationStore.resetPassword,
  message: authenticationStore.message,
  loginOnProcess: authenticationStore.loginOnProcess,
  showPassword: authenticationStore.showPassword,
  toggleShowPassword: authenticationStore.toggleShowPassword,
  emailFromInvitation: authenticationStore.emailFromInvitation,
  googleLogin: authenticationStore.googleLogin,
  HITLogin: authenticationStore.HITLogin,
  travelcounsellorsLogin: authenticationStore.travelcounsellorsLogin,
  bonareaLogin: authenticationStore.bonareaLogin,
}))(observer(Login))
