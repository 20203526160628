import React, { useEffect } from 'react'
import Tooltip from '@material-ui/core/Tooltip'
import { MyKeyword } from '../_MyComponents'
import Avatar from '@mui/material/Avatar'

import positiveIcon from '../../assets/positive.svg'
import neutralIcon from '../../assets/neutral.svg'
import negativeIcon from '../../assets/negative.svg'

const SearchByPeople = ({
  people,
  persons,
  onPeopleSelected,
  onPersonSelected,
  handleShowAll,
  selectedPersons,
  partialView,
  isAnonymous,
  workspaceType,
}) => {
  const { age } = people

  const onPeopleClicked = (newPeople) => {
    onPeopleSelected(newPeople)
  }

  const SearchByFace = () => {
    return (
      <div className="filter-section">
        <div className="filter-title">
          <div className="h2">People in images</div>
          <p className="p all-people" onClick={handleShowAll}>
            View all
          </p>
        </div>

        <div className="filter-tags faces">
          {persons
            .slice()
            .sort((a, b) => b.count - a.count)
            .slice(0, 12)
            .map((person, idx) => {
              const { name, defaultFaceLocation } = person
              return (
                <Tooltip
                  key={`person-${person._id}`}
                  title={name || 'Unnamed person'}
                >
                  <Avatar
                    alt={name || 'Unnamed person'}
                    src={defaultFaceLocation}
                    onClick={() => onPersonSelected(person)}
                    sx={
                      selectedPersons.find((one) => one._id === person._id)
                        ? { outline: '2px solid #FBC02D' }
                        : null
                    }
                  />
                </Tooltip>
              )
            })}
        </div>
      </div>
    )
  }

  const SearchByEmotion = () => {
    return (
      <div className="filter-section">
        <div className="h2">Emotions</div>
        <div className="filter-tags">
          <MyKeyword
            onClick={() => {
              onPeopleClicked({
                ...people,
                emotion: {
                  positive: !people.emotion.positive,
                  neutral: false,
                  negative: false,
                },
              })
            }}
            selected={people.emotion.positive}
            text={'Positive'}
            icon={positiveIcon}
          />
          <MyKeyword
            onClick={() => {
              onPeopleClicked({
                ...people,
                emotion: {
                  positive: false,
                  neutral: !people.emotion.neutral,
                  negative: false,
                },
              })
            }}
            selected={people.emotion.neutral}
            text={'Neutral'}
            icon={neutralIcon}
          />
          <MyKeyword
            onClick={() => {
              onPeopleClicked({
                ...people,
                emotion: {
                  positive: false,
                  neutral: false,
                  negative: !people.emotion.negative,
                },
              })
            }}
            selected={people.emotion.negative}
            text={'Negative'}
            icon={negativeIcon}
          />
        </div>
      </div>
    )
  }

  const SearchByGender = () => {
    return (
      <div className="filter-section">
        <div className="h2">Gender</div>
        <div className="filter-tags">
          <MyKeyword
            onClick={() => {
              onPeopleClicked({
                ...people,
                gender: {
                  male: !people.gender.male,
                  female: false,
                  both: false,
                },
              })
            }}
            selected={people.gender.male}
            text={'Male'}
          />
          <MyKeyword
            onClick={() => {
              onPeopleClicked({
                ...people,
                gender: {
                  male: false,
                  female: !people.gender.female,
                  both: false,
                },
              })
            }}
            selected={people.gender.female}
            text={'Female'}
          />
          <MyKeyword
            onClick={() => {
              onPeopleClicked({
                ...people,
                gender: {
                  male: false,
                  female: false,
                  both: !people.gender.both,
                },
              })
            }}
            selected={people.gender.both}
            text={'Both'}
          />
        </div>
      </div>
    )
  }

  const SearchByCount = () => {
    return (
      <div className="filter-section">
        <div className="h2">Number of people</div>
        <div className="filter-tags">
          <MyKeyword
            onClick={() => {
              onPeopleClicked({
                ...people,
                number: people.number == 0 ? -1 : 0,
              })
            }}
            selected={people.number === 0}
            text={'None'}
          />
          <MyKeyword
            onClick={() => {
              onPeopleClicked({
                ...people,
                number: people.number == 1 ? -1 : 1,
              })
            }}
            selected={people.number === 1}
            text={'1'}
          />
          <MyKeyword
            onClick={() => {
              onPeopleClicked({
                ...people,
                number: people.number == 2 ? -1 : 2,
              })
            }}
            selected={people.number === 2}
            text={'2'}
          />
          <MyKeyword
            onClick={() => {
              onPeopleClicked({
                ...people,
                number: people.number == 3 ? -1 : 3,
              })
            }}
            selected={people.number === 3}
            text={'3+'}
          />
        </div>
      </div>
    )
  }

  const SearchByAge = () => {
    return (
      <div className="filter-section">
        <div className="h2">Age</div>
        <div className="filter-tags">
          <MyKeyword
            onClick={() => {
              onPeopleClicked({
                ...people,
                age: { ...age, infants: !people.age.infants },
              })
            }}
            selected={people.age.infants}
            text={'Infants'}
          />
          <MyKeyword
            onClick={() => {
              onPeopleClicked({
                ...people,
                age: { ...age, children: !people.age.children },
              })
            }}
            selected={people.age.children}
            text={'Children'}
          />
          <MyKeyword
            onClick={() => {
              onPeopleClicked({
                ...people,
                age: { ...age, teenagers: !people.age.teenagers },
              })
            }}
            selected={people.age.teenagers}
            text={'Teens'}
          />
          <MyKeyword
            onClick={() => {
              onPeopleClicked({
                ...people,
                age: { ...age, twenties: !people.age.twenties },
              })
            }}
            selected={people.age.twenties}
            text={'20s'}
          />
          <MyKeyword
            onClick={() => {
              onPeopleClicked({
                ...people,
                age: { ...age, thirties: !people.age.thirties },
              })
            }}
            selected={people.age.thirties}
            text={'30s'}
          />
          <MyKeyword
            onClick={() => {
              onPeopleClicked({
                ...people,
                age: { ...age, forties: !people.age.forties },
              })
            }}
            selected={people.age.forties}
            text={'40s'}
          />
          <MyKeyword
            onClick={() => {
              onPeopleClicked({
                ...people,
                age: { ...age, fifties: !people.age.fifties },
              })
            }}
            selected={people.age.fifties}
            text={'50s'}
          />
          <MyKeyword
            onClick={() => {
              onPeopleClicked({
                ...people,
                age: { ...age, sixties: !people.age.sixties },
              })
            }}
            selected={people.age.sixties}
            text={'60s'}
          />
          <MyKeyword
            onClick={() => {
              onPeopleClicked({
                ...people,
                age: { ...age, older: !people.age.older },
              })
            }}
            selected={people.age.older}
            text={'Older'}
          />
        </div>
      </div>
    )
  }

  const SearchByHeadPose = () => {
    return (
      <div className="filter-section">
        <div className="h2">Head pose</div>
        <div className="filter-tags">
          <MyKeyword
            onClick={() => {
              onPeopleClicked({
                ...people,
                pose: {
                  straight: !people.pose.straight,
                  sideways: false,
                  up: false,
                  down: false,
                  tilted: false,
                },
              })
            }}
            selected={people.pose.straight}
            text={'Straight'}
          />
          <MyKeyword
            onClick={() => {
              onPeopleClicked({
                ...people,
                pose: {
                  straight: false,
                  sideways: !people.pose.sideways,
                  up: false,
                  down: false,
                  tilted: false,
                },
              })
            }}
            selected={people.pose.sideways}
            text={'Sideways'}
          />
          <MyKeyword
            onClick={() => {
              onPeopleClicked({
                ...people,
                pose: {
                  straight: false,
                  sideways: false,
                  up: !people.pose.up,
                  down: false,
                  tilted: false,
                },
              })
            }}
            selected={people.pose.up}
            text={'Up'}
          />
          <MyKeyword
            onClick={() => {
              onPeopleClicked({
                ...people,
                pose: {
                  straight: false,
                  sideways: false,
                  up: false,
                  down: !people.pose.down,
                  tilted: false,
                },
              })
            }}
            selected={people.pose.down}
            text={'Down'}
          />
          <MyKeyword
            onClick={() => {
              onPeopleClicked({
                ...people,
                pose: {
                  straight: false,
                  sideways: false,
                  up: false,
                  down: false,
                  tilted: !people.pose.tilted,
                },
              })
            }}
            selected={people.pose.tilted}
            text={'Tilted'}
          />
        </div>
      </div>
    )
  }

  return (
    <div className="filter-content-wrapper">
      {!!persons?.length && (!isAnonymous || workspaceType == 'public') && (
        <SearchByFace />
      )}
      {!partialView && <SearchByEmotion />}
      {!partialView && <SearchByGender />}
      {!partialView && <SearchByCount />}
      {!partialView && <SearchByAge />}
      {!partialView && <SearchByHeadPose />}
    </div>
  )
}

export default SearchByPeople
