import React, { useState, useRef, useEffect } from 'react'
import { makeStyles } from '@material-ui/core/styles'
import Paper from '@material-ui/core/Paper'
import IconButton from '@material-ui/core/IconButton'
import COLORS from '../Colors/colors.js'
import TextField from '@material-ui/core/TextField'
import { Menu, MenuItem } from '@szhsin/react-menu'
import '@szhsin/react-menu/dist/index.css'
import Tooltip from '@material-ui/core/Tooltip'
import { getInitToken } from '../../services/apis/util'
import { host } from '../../services/host'
import { ROLES, RULES } from '../../store/OrgsWorkspacesStore/permissions'
import FilePreview from '../FilePreview'
import ActionDialog from '../ActionDialog'
import { debounce } from 'lodash'
import Checkbox from '@material-ui/core/Checkbox'
import Resizer from 'react-image-file-resizer'
import TagList from '../TagList'
import { useLongPress } from '../_MyComponents'
import { EXT2TYPE } from '../SearchByFileType/filesExt'
import { ms2Duration } from '../../util/dateTimes'

//graphics
import DELETE from '../../assets/delete-icon.svg'
import PREVIEW from '../../assets/preview.svg'
import STAR from '../../assets/star-icon-small-white.svg'
import BLACKSTAR from '../../assets/star-icon-small-black.svg'
import WhiteCollections from '../../assets/collections-small-white.svg'
import MoreVertIcon from '@material-ui/icons/MoreVert'
import InsertDriveFileSharpIcon from '@material-ui/icons/InsertDriveFileSharp'
import ImageIcon from '@material-ui/icons/Image'
import DOWNLOAD from '../../assets/download-icon.svg'
import TAGS from '../../assets/tags.svg'
import CircularProgress from '@material-ui/core/CircularProgress'
import SUCCESS from '../../assets/success-check-circled.svg'
import Skeleton from '@mui/material/Skeleton'

const useStyles = makeStyles((theme) => ({
  root: {
    color: 'white',
  },
  uploadWizard: {
    height: '150px',
    borderRadius: '12px 12px 0px 0px',
  },
  title: {
    textOverflow: 'ellipsis',
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    position: 'relative',
    marginRight: '5px',
  },
  menuButton: {
    position: 'absolute',
    right: '0',
    top: '0',
    transition: 'all 0.2s ease',
    padding: '10px',
    zIndex: 0,
    color: 'white',
  },
  curated: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
  },
  moreIcon: {
    width: '0.75em',
    height: '0.75em',
  },
  actionButtons: {
    display: 'flex',
    gap: '5px',
  },
  actionIcon: {
    width: '25px',
    height: '25px',
    padding: '5px',
    boxSizing: 'border-box',
    backgroundColor: 'white',
    borderRadius: '6px',
    '&:hover': {
      backgroundColor: '#eee',
    },
  },
  selected: {
    backgroundColor: '#FBC02D',
    outline: '2px solid #FBC02D',
    borderRadius: '12px',
  },
  input: {
    color: 'white',
    fontSize: '14px',
  },
}))

const getFilePreview = ({
  thumbnail,
  fileId,
  file,
  setImageError,
  height,
  width,
}) => {
  const src =
    thumbnail || file?.Thumbnail
      ? thumbnail || file?.Thumbnail
      : `${host}/files/${fileId}/thumbnail?token=${getInitToken()}`
  return (
    <img
      src={src}
      alt={'ext'}
      style={{
        width: width || undefined, //getting NaN in upload page
        height: height || undefined,
        display: 'block',
        objectFit: 'cover',
      }}
      onError={(e) => {
        e.target.onerror = null
        setImageError(true)
      }}
    />
  )
}

const File = ({
  // from store
  allTags,
  addToFileViewed,
  isAllowed,
  deleteFile,
  updateFileName,
  updateThumbnail,
  onAddTag,
  onRemoveTag,
  addCollectionToFile,
  removeCollectionToFile,
  isSelectedFile,
  selectedList,
  selectFile,
  unselectFile,
  downloadFile,
  isAnonymous,
  getSimilarImages,

  // from parent
  fullFile,
  addToFavorite,
  previewDialog,
  width,
  viewMode = false,
  isUploadWizard = false,
  isSimilar = false, // used for similar photos in file preview
  previewFile, // used for similar photos in file preview
  uploadMode,
}) => {
  const {
    file,
    name,
    tags,
    status,
    collections = [],
    ext,
    fileId,
    isFavorite,
    thumbnail,
    hasThumbnail,
    thumbnail_metadata = { height: 1, width: 1 },
    _id,
  } = fullFile

  const allowedToEdit = isAllowed(RULES.EDIT_DOCUMENTS) && viewMode == false
  const allowedActions =
    isAllowed(RULES.ADD_TAG) ||
    isAllowed(RULES.DELETE_DOC) ||
    isAllowed(RULES.ADD_TO_COLLECTION)

  const thumbnail_width = thumbnail_metadata.width || 1
  const thumbnail_hight = thumbnail_metadata.height || 1

  const classes = useStyles()
  const [anchor, setAnchor] = useState(null)
  const [taglistOpen, setTaglistOpen] = useState(false)
  const [fileName, setFileName] = useState(name)

  const [type, setType] = useState('REGULAR')

  const [anchorOrigin, setAnchorOrigin] = useState({
    vertical: 'top',
    horizontal: 'left',
  })

  const [transformOrigin, setTransformOrigin] = useState({
    vertical: 'top',
    horizontal: 'center',
  })

  const [openPreview, setOpenPreview] = useState()
  const [selected, setSelected] = useState(false)
  const [showDialog, setShowDialog] = useState(false)
  const [imageError, setImageError] = useState(false)
  const itemRef = useRef()
  const inputFile = useRef(null)

  const arr = EXT2TYPE()
  const isImage = arr[ext] === 'images'

  // preload the success icon
  useEffect(() => {
    if (uploadMode) {
      const img = new Image()
      img.src = SUCCESS
    }
  }, [])

  useEffect(() => {
    if (isSelectedFile(fullFile?._id) !== selected) {
      setSelected(isSelectedFile(fullFile?._id))
    }
  }, [selectedList])

  const filePreview = () => {
    previewDialog(true)
    addToFileViewed({ fileId })
  }

  const closeDialog = () => {
    setOpenPreview(null)
  }

  const fileDelete = async (_id) => {
    setShowDialog(false)
    await deleteFile(_id)
    !!previewFile && getSimilarImages(previewFile) // deleting a file from the similar photos in preview
  }

  function openTagList(type, currentTarget) {
    switch (type) {
      case 'REGULAR':
        setType('REGULAR')
        setAnchor(currentTarget)
        setAnchorOrigin({
          vertical: 'bottom',
          horizontal: 'center',
        })
        setTaglistOpen(true)
        break
      case 'COLLECTION':
        setType('COLLECTION')
        setAnchor(itemRef.current)
        setAnchorOrigin({
          vertical: 'top',
          horizontal: 'right',
        })
        setTransformOrigin({
          vertical: 'top',
          horizontal: 'left',
        })
        setTaglistOpen(true)
        break

      default:
        break
    }
  }

  function handleClose() {
    setAnchor(null)
    setTaglistOpen(false)
  }

  const delayedHandleChange = debounce((val) => {
    const newName = val
    updateFileName({ fileId: _id, name: newName })
  }, 1500)

  const handleChange = (e) => {
    setFileName(e.target.value)
    delayedHandleChange(e.target.value)
  }

  const toggleSelectFile = () => {
    if (selected) {
      unselectFile(fullFile)
    } else {
      selectFile(fullFile)
    }
  }

  const handleClick = (event, file) => {
    if (!!viewMode || !!uploadMode) {
      return
    } else if (event.ctrlKey === true) {
      toggleSelectFile()
      //workaround to prevent selections when clicking an action
    } else if (event.target.nodeName === 'DIV') {
      filePreview(fileId)
    }
  }

  const handleCheck = (e) => {
    if (selected) {
      unselectFile(fullFile)
    } else {
      selectFile(fullFile)
    }
  }

  // helper to get dimensions of an image
  const imageDimensions = (file) =>
    new Promise((resolve, reject) => {
      const img = new Image()

      // the following handler will fire after a successful loading of the image
      img.onload = () => {
        const { naturalWidth: width, naturalHeight: height } = img
        resolve({ width, height })
      }

      // and this handler will fire if there was an error with the image (like if it's not really an image or a corrupted one)
      img.onerror = () => {
        reject('There was some problem with the image.')
      }

      img.src = URL.createObjectURL(file)
    })

  // here's how to use the helper
  const getInfo = async (file) => {
    try {
      const dimensions = await imageDimensions(file)
      console.info(dimensions)
      return dimensions
    } catch (error) {
      console.error(error)
    }
  }

  const onThumbnailUpdate = async (thumbnail) => {
    try {
      const { width, height } = await getInfo(thumbnail[0])
      const encodedThumbnail = await getBase64Image(thumbnail[0])
      //remove data:image/jpeg;base64, from the response
      await updateThumbnail(
        encodedThumbnail.split(',')[1],
        fileId,
        width,
        height
      )
      //ugly ugly workaround to refetch the thumbnail, I appologize to all future developers
      setImageError(true)
      setImageError(false)
    } catch (err) {
      console.log(err)
    }
  }

  const getBase64Image = async (file) => {
    const myPromise = new Promise((resolve) => {
      Resizer.imageFileResizer(
        file,
        256,
        256,
        'JPEG',
        100,
        0,
        (uri) => {
          resolve(uri)
        },
        'base64'
      )
    })

    const res = await myPromise
    return res
  }

  const menu = (
    <Menu
      className="file-menu"
      direction="right"
      menuButton={
        <IconButton
          aria-controls={`file-menu-${fileId}`}
          className={classes.menuButton + ' popup'}
        >
          <MoreVertIcon className={classes.moreIcon} />
        </IconButton>
      }
    >
      <MenuItem onClick={() => filePreview()} className="p">
        <img src={PREVIEW} className="menu-icon" /> Preview
      </MenuItem>
      {!isSimilar ? (
        <MenuItem onClick={() => addToFavorite({ fileId })} className="p">
          {isFavorite ? (
            <img src={BLACKSTAR} className="menu-icon" />
          ) : (
            <img src={STAR} className="menu-icon" />
          )}
          {!isFavorite ? ' Add to Favorites' : ' Remove from Favorites'}
        </MenuItem>
      ) : (
        ''
      )}
      {isAllowed(RULES.ADD_TO_COLLECTION) && !isSimilar ? (
        <MenuItem onClick={() => openTagList('COLLECTION')} className="p">
          <div className={classes.curated}>
            <img src={WhiteCollections} className="menu-icon" /> Add/remove from
            collections
          </div>
        </MenuItem>
      ) : (
        ''
      )}
      {isAllowed(RULES.EDIT_DOCUMENTS) && !isImage ? (
        <MenuItem
          onClick={() => {
            inputFile.current.click()
          }}
          className="p"
        >
          <ImageIcon className={'menu-icon'} /> Change cover image{' '}
        </MenuItem>
      ) : (
        ''
      )}
      {isAllowed(RULES.DELETE_DOC) ? (
        <MenuItem onClick={() => setShowDialog(true)} className="p red">
          <img src={DELETE} className="menu-icon red" /> Delete
        </MenuItem>
      ) : (
        ''
      )}
    </Menu>
  )

  const onLongPress = () => {
    window.mobileCheck() && toggleSelectFile()
  }

  //workaround for the longpress I got from Stackoverflow
  const onClick = () => {}

  const longPressEvent = useLongPress(onLongPress, onClick, {
    shouldPreventDefault: false, // without this you can't select a file
  })

  // it shows 40 for jpg for some reason
  const fileDuration =
    fullFile?.metadata?.duration_ms > 40
      ? ms2Duration(fullFile?.metadata?.duration_ms)
      : null

  // status: pending, uploading, uploaded, analaysing, complete, duplicate
  const uploadStatus = () => {
    switch (status) {
      case 'uploading':
      case 'pending':
        return (
          <>
            <CircularProgress size="18px" color="inherit" />
            <p className="p">Uploading...</p>
          </>
        )
      case 'uploaded':
      case 'analaysing':
      case 'analysis-faces':
      case 'analysis':
        return (
          <>
            <CircularProgress size="18px" color="inherit" />
            <p className="p">Analyzing...</p>
          </>
        )
      case 'complete':
        return (
          <>
            <img src={SUCCESS} />
            <p className="p">Done</p>
          </>
        )
      case 'duplicate':
        return <p className="p">Duplicate</p>
      case 'failed':
        return <p className="p">Failed</p>
      default:
        break
    }
  }

  return (
    <>
      {/* data is a workaround for drag select. should probably refactor */}
      <div
        className={`file-wrapper ${classes.root} ${
          selected && classes.selected
        }`}
        {...longPressEvent}
      >
        <input
          accept="image/png, image/jpeg"
          id="change-thumbnail"
          name="image"
          type="file"
          ref={inputFile}
          style={{ display: 'none' }}
          onChange={(event) => onThumbnailUpdate(event.target.files)}
        />
        {openPreview && (
          <FilePreview previewUrl={openPreview} closeDialog={closeDialog} />
        )}
        <Paper
          className={
            //if uploadMode, add class upload-file
            'file mobile-file on-hover ' +
            (isUploadWizard && classes.uploadWizard) +
            ' ' +
            (uploadMode ? ' upload-file' : '')
          }
          data-id={fullFile._id}
          style={{ paddingTop: '0%' }} //height/width + '%'}}
          elevation={3}
          ref={itemRef}
        >
          <div className="file-img">
            <div
              aria-describedby={`pop_${fileId}`}
              className={`file-click ${viewMode == true && 'view-mode'}`}
            >
              {hasThumbnail && !imageError ? (
                getFilePreview({
                  thumbnail,
                  ext,
                  fileId,
                  file,
                  setImageError,
                  height: (width * thumbnail_hight) / thumbnail_width, //creates error on upload preview page
                  width,
                })
              ) : (
                <div className="file-type-icon-wrappaer">
                  <InsertDriveFileSharpIcon
                    className="file-type-icon"
                    style={{ color: COLORS[ext] }}
                  />
                  <div className="file-type-icon-caption">{ext}</div>
                </div>
              )}
            </div>
          </div>
          {!viewMode && (
            <div
              onClick={(event) => {
                setAnchor(itemRef.current)
                handleClick(event, file)
              }}
              className={'file-actions'}
            >
              <div className={'hover-background'}></div>
              {!isSimilar && !uploadMode && (
                <Checkbox
                  id={fileId + '-checkbox'}
                  name="selected"
                  className={`file-checkbox ${!!selected && 'selected'}`}
                  color="default"
                  checked={selected}
                  onClick={(e) => {
                    e.stopPropagation()
                    handleCheck(e)
                  }}
                />
              )}
              {uploadMode && (
                <div className={'upload-actions'}>
                  <IconButton
                    className={'upload-action status left'}
                    component="span"
                    size="small"
                    style={{ backgroundColor: 'white' }}
                  >
                    {uploadStatus()}
                  </IconButton>
                  {!!_id && (
                    <IconButton
                      className={'upload-action right'}
                      component="span"
                      size="small"
                      style={{ backgroundColor: 'white' }}
                      onClick={() => {
                        fileDelete(_id)
                      }}
                    >
                      {<img alt="delete" src={DELETE} />}
                    </IconButton>
                  )}
                </div>
              )}

              {fileDuration && !window.mobileCheck() && (
                <div className={'video-indicator'}>{fileDuration}</div>
              )}

              <div className="file-footer">
                {/* <InsertDriveFileSharpIcon
            className={classes.tinyFileIcon}
            style={{
              color: COLORS[ext], 
            }}
          /> */}
                <Tooltip title={fileName}>
                  <TextField
                    id={fileId}
                    value={fileName}
                    //defaultValue={fileName}
                    onChange={(e) => handleChange(e)}
                    fullWidth
                    disabled={!allowedToEdit}
                    className={classes.title}
                    InputProps={{ className: classes.input }}
                  />
                </Tooltip>

                {!isSimilar && (
                  <div className={classes.actionButtons}>
                    {isAllowed(RULES.ADD_TAG) ? (
                      <Tooltip title="Add/remove Tags">
                        <IconButton
                          className={classes.actionIcon}
                          component="span"
                          size="small"
                          disabled={uploadMode && status === 'pending'}
                          style={{ backgroundColor: '#FBC02D' }}
                          onClick={(event) => {
                            openTagList('REGULAR', event.currentTarget)
                          }}
                        >
                          {<img alt="tags" src={TAGS} />}
                        </IconButton>
                      </Tooltip>
                    ) : (
                      <Tooltip title="Download">
                        <IconButton
                          className={classes.actionIcon}
                          component="span"
                          size="small"
                          style={{ backgroundColor: '#FBC02D' }}
                          onClick={(event) => {
                            downloadFile(fullFile)
                          }}
                        >
                          {<img alt="tags" src={DOWNLOAD} />}
                        </IconButton>
                      </Tooltip>
                    )}

                    {!isAnonymous && !uploadMode && (
                      <Tooltip
                        title={
                          !isFavorite
                            ? 'Add to Favorites'
                            : 'Remove from Favorites'
                        }
                      >
                        <IconButton
                          className={classes.actionIcon}
                          component="span"
                          size="small"
                          onClick={() => {
                            addToFavorite({ fileId })
                          }}
                        >
                          {isFavorite ? (
                            <img src={BLACKSTAR} className="classes.pinIcon" />
                          ) : (
                            <img src={STAR} className="classes.pinIcon" />
                          )}
                        </IconButton>
                      </Tooltip>
                    )}
                  </div>
                )}
              </div>
            </div>
          )}
          {viewMode && (
            <div className={'file-actions'}>
              <div className={'hover-background'}></div>
              <div className="file-footer">
                <TextField
                  defaultValue={fileName}
                  disabled
                  className={classes.title}
                  InputProps={{ className: classes.input }}
                />
              </div>
            </div>
          )}
          {allowedActions && !uploadMode && !viewMode && menu}
        </Paper>
        {!!anchor && !!taglistOpen && (
          <TagList
            type={type}
            transform={transformOrigin}
            anchor={anchor}
            origin={anchorOrigin}
            id={`pop_${fileId}`}
            onClose={handleClose}
            tags={tags}
            allTags={allTags}
            collections={collections}
            fileId={_id}
            onAddTag={onAddTag}
            onRemoveTag={onRemoveTag}
            onAddCollection={addCollectionToFile}
            onRemoveCollection={removeCollectionToFile}
          />
        )}
      </div>
      {showDialog === true && (
        <ActionDialog
          actionName={'Delete'}
          action={() => {
            fileDelete(_id)
          }}
          cancel={() => setShowDialog(false)}
          question={`Delete file ${fileName}?`}
          comment={
            "You're about to permanently delete this file, its comments and related content, and all of its data."
          }
          color={'warning'}
          isOpen={showDialog}
        />
      )}
    </>
  )
}

export default File
