import { inject, observer } from 'mobx-react'
import CollectionShareDialog from './CollectionShareDialog'
import './CollectionShareDialog.scss'
import './mobile.scss'

export default inject(({ filesStore, orgsWorkspacesStore }) => ({
  currentCollection: filesStore.currentCollection,
  updateCollectionPrivacy: filesStore.updateCollectionPrivacy,
  getCurrentCollection: filesStore.getCurrentCollection,
  updateCollectionFMP: filesStore.updateCollectionFMP,
}))(observer(CollectionShareDialog))
