import { inject, observer } from 'mobx-react'
import Comments from './Comments'

export default inject(({ orgsWorkspacesStore, authenticationStore }) => ({
  orgId: orgsWorkspacesStore.orgId,
  workspaceId: orgsWorkspacesStore.workspaceId,
  workspaceUsers: orgsWorkspacesStore.workspaceUsers,
  isAllowed: orgsWorkspacesStore.isAllowed,
  username: authenticationStore.username,
}))(observer(Comments))
