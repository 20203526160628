import { inject, observer } from 'mobx-react'
import EmptyStateMain from './EmptyStateMain'

export default inject(
  ({ orgsWorkspacesStore, filesStore, authenticationStore }) => ({
    userState: authenticationStore.userState,
    updateUserState: authenticationStore.updateUserState,
    isAnonymous: authenticationStore.isAnonymous,
  })
)(observer(EmptyStateMain))
