import { inject, observer } from 'mobx-react'
import SearchByColor from './SearchByColor'
import './SearchByColor.scss'

export default inject(({ filesStore, orgsWorkspacesStore }) => ({
  selectedColors: filesStore.selectedColors,
  onColorSelected: filesStore.onColorSelected,
  selectedAdvanceColorFilter: filesStore.selectedAdvanceColorFilter,
  onAdvanceColorFilterSelected: filesStore.onAdvanceColorFilterSelected,
}))(observer(SearchByColor))
