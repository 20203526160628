import { inject, observer } from 'mobx-react'
import File from './File'
import './file.scss'
import './mobile.scss'

export default inject(
  ({ filesStore, orgsWorkspacesStore, authenticationStore }) => ({
    allTags: filesStore.allTags,
    addToFileViewed: filesStore.addToFileViewed,
    deleteFile: filesStore.deleteFile,
    isAllowed: orgsWorkspacesStore.isAllowed,
    updateFileName: filesStore.updateFileName,
    updateThumbnail: filesStore.updateThumbnail,
    onAddTag: filesStore.addFileTag,
    onRemoveTag: filesStore.removeFileTag,
    addCollectionToFile: filesStore.addCollectionToFile,
    removeCollectionToFile: filesStore.removeCollectionToFile,
    isSelectedFile: filesStore.isSelectedFile,
    selectedList: filesStore.selectedList,
    selectFile: filesStore.selectFile,
    unselectFile: filesStore.unselectFile,
    downloadFile: filesStore.downloadFile,
    isAnonymous: authenticationStore.isAnonymous,
    getSimilarImages: filesStore.getSimilarImages,
  })
)(observer(File))
