import { inject, observer } from 'mobx-react'
import CleanupWizard from './CleanupWizard'
import './mobile.scss'

export default inject(({ filesStore, orgsWorkspacesStore }) => ({
  SyncChosenFiles: filesStore.SyncChosenFiles,
  SplitTags: filesStore.SplitTags,
  MergeTags: filesStore.MergeTags,
  DeleteTags: filesStore.DeleteTags,
  onReset: filesStore.onReset,
  gettingSuggestions: filesStore.gettingSuggestions,
  workspaceId: orgsWorkspacesStore.workspaceId,
  allTags: filesStore.allTags,
}))(observer(CleanupWizard))
