import React, { useCallback, useEffect } from 'react'
import Checkbox from '@material-ui/core/Checkbox'
import Button from '@material-ui/core/Button'
import CircularProgress from '@material-ui/core/CircularProgress'
import useDrivePicker from 'react-google-drive-picker'

const CLIENT_ID =
  '684262614313-mk1fphl6grujlq8dmplhbpfgjicfusv8.apps.googleusercontent.com'
const DEVELOPER_KEY = 'AIzaSyAIqsXsgeMGOt8A-tlaCHL3R0ZZiMa0z5A'

const DriveFolders = ({
  selectedFolders,
  setSelectedFolders,
  SyncChosenDirectories,
  SyncChosenFiles,
  cleanupDialog,
  access_token,
  drives,
  getAllTags,
}) => {
  const [isSyncing, setIsSyncing] = React.useState('before')

  const [openPicker, data, authResponse] = useDrivePicker()

  const handleOpenPicker = () => {
    //console.log(access_token);

    openPicker({
      clientId: CLIENT_ID,
      developerKey: DEVELOPER_KEY,
      viewId: 'FOLDERS',
      token: access_token, // pass oauth token in case you already have one
      setSelectFolderEnabled: true,
      supportDrives: true,
      multiselect: true,
      setIncludeFolders: true,
      // customViews: customViewsArray, // custom view
    })
  }

  useEffect(() => {
    // do anything with the selected/uploaded files
    if (data) {
      const foldersIds = data.docs.map((doc) => {
        return doc.id
      })
      console.log('foldersIds', foldersIds)
      //setSelectedFolders(foldersIds)
      //syncFolders(foldersIds)
    }
  }, [data])

  const onLoad = useCallback((event) => {
    event.preventDefault()
    event.returnValue = ''
  }, [])

  useEffect(() => {
    if (isSyncing === 'syncing') {
      window.addEventListener('beforeunload', onLoad)
    } else {
      window.removeEventListener('beforeunload', onLoad)
    }
    return () => window.removeEventListener('beforeunload', onLoad)
  }, [isSyncing, onLoad])

  const handleChange = (event, id) => {
    let newFolders = [...selectedFolders]
    if (event.target.checked) {
      newFolders.push(id)
    } else {
      newFolders.splice(newFolders.indexOf(id), 1)
    }
    setSelectedFolders(newFolders)
  }

  const syncFolders = (foldersIds) => {
    setIsSyncing('syncing')
    SyncChosenDirectories({ folderIds: foldersIds }).then(() => {
      SyncChosenFiles({ folderIds: foldersIds })
        .then(() => {
          getAllTags({})
        })
        .then(() => {
          setIsSyncing('finished')
        })
    })
  }

  const syncDrives = () => {
    setIsSyncing('syncing')
    SyncChosenDirectories({ folderIds: selectedFolders }).then(() => {
      SyncChosenFiles({ folderIds: selectedFolders }).then(() => {
        setIsSyncing('finished')
      })
    })
  }

  const startTagCleanup = () => {
    cleanupDialog(true)
  }

  return (
    <div className="DriveFolders">
      {isSyncing === 'before' && (
        <div className="folders-list">
          <div className="DriveFolders-title">
            <div>We can’t wait to start</div>
            <div>helping you get organized!</div>
          </div>
          <div className="folders-list-title">
            Choose an entire Drive you’d like to sync with Tagbox
          </div>
          <div className="folders-checkbox-list">
            <div className="folder">
              <Checkbox
                color="default"
                onChange={(event) => handleChange(event, drives.root.id)}
              />
              <div className="folder-name">My Drive</div>
            </div>
            {drives.sharedDrives.map(({ name, id }, index) => (
              <div className="folder" key={index}>
                <Checkbox
                  color="default"
                  onChange={(event) => handleChange(event, id)}
                />
                <div className="folder-name">{name}</div>
              </div>
            ))}
          </div>
          <Button
            className="sync-button"
            onClick={syncDrives}
            variant="contained"
            color="primary"
            disabled={selectedFolders.length == 0}
          >
            Sync selected Drives
          </Button>
          <div className="folders-list-title2">or choose specific folders</div>
          <div>
            <Button
              className="sync-button"
              onClick={handleOpenPicker}
              variant="contained"
              color="primary"
            >
              Pick Folders
            </Button>
          </div>
        </div>
      )}
      {isSyncing !== 'before' && (
        <div className="sync-spinner">
          <div>Importing files... This might take a couple of minutes.</div>

          <iframe
            title={'intro video'}
            height="80%"
            frameBorder="0"
            src={'https://www.youtube.com/embed/GotoEAMX9Zk?autoplay=1&rel=0'}
          ></iframe>
          <div>
            {isSyncing !== 'finished' && <CircularProgress />}{' '}
            <Button
              disabled={isSyncing !== 'finished'}
              className="sync-button"
              variant="contained"
              color="primary"
              onClick={startTagCleanup}
            >
              Start Tag Cleanup
            </Button>
          </div>
        </div>
      )}
    </div>
  )
}

export default DriveFolders
