import React, { useState, useEffect } from 'react'

import { Popover } from 'react-popopo'

import {
  CustomPopoverContent,
  CustomPopoverContainer,
} from 'react-trello/dist/styles/Base'

import Dialog from '@material-ui/core/Dialog'
import DialogActions from '@material-ui/core/DialogActions'
import DialogContent from '@material-ui/core/DialogContent'
import DialogContentText from '@material-ui/core/DialogContentText'
import DialogTitle from '@material-ui/core/DialogTitle'

import Radio from '@mui/material/Radio'
import RadioGroup from '@mui/material/RadioGroup'
import FormControlLabel from '@mui/material/FormControlLabel'
import FormControl from '@mui/material/FormControl'
import FormLabel from '@mui/material/FormLabel'

import { MyButton } from '../../_MyComponents'

import { LaneMenuContent, MenuButton } from 'react-trello/dist/styles/Elements'

import { LaneMenuItem } from '../MyGlobalStyle'

const MyLaneMenu = ({
  sortBy,
  sortOrder,
  catId,
  resortCategory,
  t,
  onDelete,
  cards,
}) => {
  const [sortDialogOpen, setSortDialogOpen] = useState(false)
  const [deleteDialogOpen, setDeleteDialogOpen] = useState(false)
  const [sortByState, setSortByState] = useState('count')
  const [sortOrderState, setSortOrderState] = useState('desc')

  useEffect(() => {
    setSortByState(sortBy)
    setSortOrderState(sortOrder)
  }, [sortBy, sortOrder])

  const handleSort = () => {
    resortCategory(catId, sortByState, sortOrderState)
    setSortDialogOpen(false)
  }

  const sortDialog = (
    <Dialog
      onClose={() => setSortDialogOpen(false)}
      fullWidth={true}
      maxWidth={'xs'}
      aria-labelledby="simple-dialog-title"
      open={sortDialogOpen}
      className="sort-dialog-wrapper"
    >
      <DialogTitle id="form-dialog-title">Sort category</DialogTitle>
      <DialogContent>
        <div className="sort-cat-form">
          <FormControl>
            <FormLabel className="h3" id="demo-radio-buttons-group-label">
              Sort by
            </FormLabel>
            <RadioGroup
              aria-labelledby="demo-radio-buttons-group-label"
              value={sortByState}
              name="radio-buttons-group"
              className="radio-group"
              onChange={(e) => {
                setSortByState(e.target.value)
              }}
            >
              <FormControlLabel
                className="p"
                value="name"
                control={<Radio />}
                label="Name"
              />
              <FormControlLabel
                className="p"
                value="count"
                control={<Radio />}
                label="Count"
              />
            </RadioGroup>
          </FormControl>
          <FormControl>
            <FormLabel className="h3" id="demo-radio-buttons-group-label">
              Sort order
            </FormLabel>
            <RadioGroup
              aria-labelledby="demo-radio-buttons-group-label"
              value={sortOrderState}
              name="radio-buttons-group"
              className="radio-group"
              onChange={(e) => {
                setSortOrderState(e.target.value)
              }}
            >
              <FormControlLabel
                className="p"
                value="desc"
                control={<Radio />}
                label="Descending"
              />
              <FormControlLabel
                className="p"
                value="asc"
                control={<Radio />}
                label="Ascending"
              />
            </RadioGroup>
          </FormControl>
        </div>
      </DialogContent>
      <DialogActions>
        <MyButton variant="contained" onClick={() => setSortDialogOpen(false)}>
          Cancel
        </MyButton>
        <MyButton
          onClick={handleSort}
          variant="contained"
          color="primary"
          autoFocus
        >
          Save
        </MyButton>
      </DialogActions>
    </Dialog>
  )

  // add dialog for delete category that asks if user would like to delete all tags in category or move them to misc
  const deleteDialog = (
    <Dialog
      onClose={() => setDeleteDialogOpen(false)}
      fullWidth={true}
      maxWidth={'xs'}
      aria-labelledby="simple-dialog-title"
      open={deleteDialogOpen}
      className="delete-dialog-wrapper"
    >
      <DialogTitle id="form-dialog-title">Delete category</DialogTitle>
      <DialogContent>
        <DialogContentText>
          Would you like to delete all tags in this category, or move them to
          'Miscellaneous'?
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <MyButton
          onClick={() => {
            onDelete(catId)
            setDeleteDialogOpen(false)
          }}
          variant="contained"
        >
          Move to Misc.
        </MyButton>
        <MyButton
          onClick={() => {
            onDelete(catId, 'delete-all')
            setDeleteDialogOpen(false)
          }}
          variant="contained"
          color="primary"
          autoFocus
        >
          Delete tags
        </MyButton>
      </DialogActions>
    </Dialog>
  )

  const handleDelete = () => {
    if (cards.length === 0) {
      onDelete(catId)
      return
    } else {
      setDeleteDialogOpen(true)
    }
  }

  return (
    <>
      <Popover
        position="bottom"
        PopoverContainer={CustomPopoverContainer}
        PopoverContent={CustomPopoverContent}
        trigger={<MenuButton>⋮</MenuButton>}
      >
        <LaneMenuContent>
          {!!sortOrder && (
            <LaneMenuItem
              onClick={() => {
                setSortDialogOpen(true)
              }}
            >
              Sort category
            </LaneMenuItem>
          )}
          <LaneMenuItem onClick={handleDelete}>Delete category</LaneMenuItem>
        </LaneMenuContent>
      </Popover>
      {sortDialog}
      {deleteDialog}
    </>
  )
}
export default MyLaneMenu
