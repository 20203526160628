import React from 'react'
import { NewLaneSection, AddLaneLink } from './MyGlobalStyle'
import ADD from '../../assets/add.svg'
import './TagsManagment.scss'

export default ({ t, onClick }) => (
  <NewLaneSection className="new-lane">
    <AddLaneLink t={t} onClick={onClick}>
      <img src={ADD} className="addButton" />
      <p>Add Category</p>
    </AddLaneLink>
  </NewLaneSection>
)
