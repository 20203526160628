import React, { useEffect, useState, useRef } from 'react'
import ArrowForwardIcon from '@material-ui/icons/ArrowForward'
import { MyButton } from '../_MyComponents'
import LinearProgress from '@mui/material/LinearProgress'
import EVENTS from '../../assets/SurveyAssets/events.jpg'
import PRODUCTS from '../../assets/SurveyAssets/products.jpg'
import HOSPITALITY from '../../assets/SurveyAssets/hospitality.jpg'
import RESTAURANTS from '../../assets/SurveyAssets/restaurants.jpg'
import TRAVEL from '../../assets/SurveyAssets/travel.jpg'
import COLLECTION from '../../assets/collection-icon.svg'
import POSTER from '../../assets/SurveyAssets/clutter-free.svg'
import LOGO from '../../assets/logo.svg'
import { MuiThemeProvider } from '@material-ui/core/styles'
import { theme } from '../_MyComponents'
import CircularProgress from '@material-ui/core/CircularProgress'
import { set } from 'mobx'

const StartSurvey = ({
  // from store
  updateOrganization,
  addWorkspaceTemplate,
  setWorkspaceDefault,
  // from parent
}) => {
  const [step, setStep] = useState(0)
  const [selected, setSelected] = useState(null)
  const [answers, setAnswers] = useState([{}])
  const [runSpinner, setRunSpinner] = useState(false)

  let info = {
    orgEntity: 'personal',
    orgSize: '0',
    orgObjective: 'both',
    orgAssetsType: 'other',
  }

  const q1Options = [
    { id: 'company', text: 'For my company' },
    { id: 'nonprofit', text: 'For my nonprofit' },
    { id: 'personal', text: 'For personal use' },
  ]

  const q2Options = [
    { id: '1-10', text: '1-10' },
    { id: '11-50', text: '11-50' },
    { id: '51-150', text: '51-150' },
    { id: '151-500', text: '151-500' },
    { id: '500+', text: '500+' },
  ]

  const q3Options = [
    { id: 'internal', text: 'Organize internally' },
    { id: 'external', text: 'Share assets externally' },
    { id: 'both', text: 'Both' },
  ]

  const q4Options = [
    { id: 'events', text: 'Events & people', image: EVENTS },
    { id: 'products', text: 'products', image: PRODUCTS },
    {
      id: 'hospitality',
      text: 'Hospitality & real estate',
      image: HOSPITALITY,
    },
    { id: 'restaurants', text: 'Restaurants', image: RESTAURANTS },
    { id: 'travel', text: 'Travel', image: TRAVEL },
    { id: 'other', text: 'Other (start from scratch)', image: COLLECTION },
  ]

  const handleChange = (id) => {
    setSelected(id)
  }

  const nextStep = async () => {
    const newAnswers = [...answers]
    newAnswers[step] = selected
    setAnswers(newAnswers)
    if (step < 3) {
      if (step === 0 && selected === 'personal') {
        info.orgEntity = 'personal'
        info.orgSize = '0'
        setStep(2)
      } else {
        setStep((prev) => prev + 1)
      }
    } else {
      info.orgEntity = answers[0] || 'personal'
      info.orgSize = answers[1] || '0'
      info.orgObjective = answers[2] || 'both'
      info.orgAssetsType = selected || 'other'
      updateOrganization({ update: { info } })
      if (info.orgAssetsType != 'other') {
        setRunSpinner(true)
        await addWorkspaceTemplate(info.orgAssetsType)
        setRunSpinner(false)
      }
      setWorkspaceDefault()
    }
    setSelected(null)
  }

  return (
    <MuiThemeProvider theme={theme}>
      {runSpinner ? (
        <div className="loading-spinner">
          {/* change background color to gray */}
          <CircularProgress color="secondary" size="1rem" />
        </div>
      ) : (
        <div className="survey-wrapper">
          <div className="survey-container">
            <div className="survey-header">
              <img src={LOGO} alt="tagbox-logo" />
            </div>
            <div className="survey-content">
              <div className="progress-bar">
                <LinearProgress
                  variant="determinate"
                  style={{ color: '#FBC02D' }}
                  color="inherit"
                  value={((step + 1) / 4) * 100}
                />
              </div>

              {/* Question 1 */}

              {step === 0 && (
                <div className="question-container">
                  <div className="question-wrapper">
                    <h1 className="h1">
                      Welcome! How would you like to use Tagbox?
                    </h1>
                    <p className="p light">
                      This helps customize your experience
                    </p>
                  </div>
                  <div className="options-wrapper">
                    {q1Options.map((option) => (
                      <div
                        key={option.id}
                        className={`option ${
                          selected === option.id ? 'selected' : ''
                        }`}
                        onClick={() => handleChange(option.id)}
                      >
                        <input
                          type="radio"
                          id={`radio-${option.id}`}
                          name="image-radio"
                          value={option.id}
                          checked={selected === option.id}
                          onChange={() => handleChange(option.id)}
                          style={{ marginRight: '10px' }}
                        />
                        <label htmlFor={`radio-${option.id}`}>
                          <p className="p">{option.text}</p>
                        </label>
                      </div>
                    ))}
                  </div>
                </div>
              )}

              {/* Question 2 */}

              {step === 1 && (
                <div className="question-container">
                  <div className="question-wrapper">
                    <h1 className="h1">What’s your company’s size?</h1>
                    <p className="p light"></p>
                  </div>
                  <div className="options-wrapper vertical">
                    {q2Options.map((option) => (
                      <div
                        key={option.id}
                        className={`option vertical ${
                          selected === option.id ? 'selected' : ''
                        }`}
                        onClick={() => handleChange(option.id)}
                      >
                        <input
                          type="radio"
                          id={`radio-${option.id}`}
                          name="image-radio"
                          value={option.id}
                          checked={selected === option.id}
                          onChange={() => handleChange(option.id)}
                          style={{ marginRight: '10px' }}
                        />
                        <label htmlFor={`radio-${option.id}`}>
                          <p className="p">{option.text}</p>
                        </label>
                      </div>
                    ))}
                  </div>
                </div>
              )}

              {/* Question 3 */}

              {step === 2 && (
                <div className="question-container">
                  <div className="question-wrapper">
                    <h1 className="h1">What’s your main objective?</h1>
                    <p className="p light">
                      Your choice won’t limit your options later.
                    </p>
                  </div>
                  <div className="options-wrapper">
                    {q3Options.map((option) => (
                      <div
                        key={option.id}
                        className={`option ${
                          selected === option.id ? 'selected' : ''
                        }`}
                        onClick={() => handleChange(option.id)}
                      >
                        <input
                          type="radio"
                          id={`radio-${option.id}`}
                          name="image-radio"
                          value={option.id}
                          checked={selected === option.id}
                          onChange={() => handleChange(option.id)}
                          style={{ marginRight: '10px' }}
                        />
                        <label htmlFor={`radio-${option.id}`}>
                          <p className="p">{option.text}</p>
                        </label>
                      </div>
                    ))}
                  </div>
                </div>
              )}

              {/* Question 4 */}

              {step === 3 && (
                <div className="question-container">
                  <div className="question-wrapper">
                    <h1 className="h1">Which best describes your assets?</h1>
                    <p className="p light">
                      This helps us set up your workspace.
                    </p>
                  </div>
                  <div className="options-wrapper grid">
                    {q4Options.map((option) => (
                      <div
                        key={option.id}
                        className={`image-option`}
                        onClick={() => handleChange(option.id)}
                      >
                        <div
                          className={`image-container ${
                            selected === option.id ? 'selected' : ''
                          }`}
                        >
                          <img src={option.image} alt={option.text} />
                          <input
                            type="radio"
                            id={`radio-${option.id}`}
                            name="image-radio"
                            value={option.id}
                            checked={selected === option.id}
                            onChange={() => handleChange(option.id)}
                            style={{ marginRight: '10px' }}
                          />
                        </div>
                        <label htmlFor={`radio-${option.id}`}>
                          <p className="p">{option.text}</p>
                        </label>
                      </div>
                    ))}
                  </div>
                </div>
              )}
            </div>

            <div className="survey-footer">
              <div className="button-container">
                <MyButton
                  endIcon={<ArrowForwardIcon />}
                  variant="contained"
                  color="primary"
                  onClick={() => nextStep()}
                  disabled={!selected}
                >
                  Continue
                </MyButton>
              </div>
            </div>
          </div>
          <div className="survey-poster">
            <img src={POSTER} alt="clutter-free" />
          </div>
        </div>
      )}
    </MuiThemeProvider>
  )
}

export default StartSurvey
