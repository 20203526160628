import firebase from 'firebase/compat/app'
import 'firebase/compat/auth'
import configDevelopment from './firebaseConfig'
import {
  getAuth,
  signInWithPopup,
  SAMLAuthProvider,
  signInWithRedirect,
} from 'firebase/auth'

import logger from '../../../services/logger'

const config = configDevelopment

const firebaseApp = firebase.initializeApp(config)

export const fireAuth = firebaseApp.auth()
window.fa = fireAuth

const provider = new firebase.auth.GoogleAuthProvider()
provider.setCustomParameters({ prompt: 'select_account' })
provider.addScope('email')

const hit_provider = new SAMLAuthProvider('saml.hit')
const travelcounsellors_provider = new SAMLAuthProvider(
  'saml.travelcounsellors'
)
const bonarea_provider = new SAMLAuthProvider('saml.bonarea')

export const signInWithGoogle = async () => {
  try {
    const { user } = await fireAuth.signInWithPopup(provider)
    console.log({ 'fireAuth.currentUser': fireAuth.currentUser, user })
    return user
  } catch (error) {
    logger.error(error)
    throw error
  }
}

export const signInWithHIT = async () => {
  try {
    console.log('signInWithHIT')
    const auth = getAuth()
    console.log('getAuth')
    console.log({ auth })
    console.log('signInWithRedirect')
    const result = await signInWithRedirect(auth, hit_provider)
    console.log({ signInWithRedirectResult: result })

    // User is signed in.
    // Provider data available from the result.user.getIdToken()
    // or from result.user.providerData
    console.log({ signInWithRedirectResult: result })

    const rawUserInfo = JSON.parse(result._tokenResponse.rawUserInfo)
    var { name, maslul, Shlucha, email } = rawUserInfo
    //"{\"name\":\"דמו סטודנט\",\"maslul\":\"16\",\"Shlucha\":\"0\",\"email\":\"mazi1000@gmail.com\"}"

    await fireAuth.currentUser.updateProfile({ displayName: name, email })
    var { user } = result
    return {
      ...user,
      displayName: name,
      email,
      idToken: result._tokenResponse.idToken,
    }
  } catch (error) {
    /*
            // Handle Errors here.
        const errorCode = error.code;
        const errorMessage = error.message;
        // The email of the user's account used.
        const email = error.customData.email;
        // The AuthCredential type that was used.
        const credential = SAMLAuthProvider.credentialFromError(error);
        // Handle / display error.
        console.log({signInWithPopupError: error})
        // ...

    // [END auth_saml_signin_popup_modular]
    */
    console.log({ signInWithHITError: error })
    logger.error(error)
    throw error
  }
}

export const signInWithTravelcounsellors = async () => {
  try {
    const auth = getAuth()
    //const result = await signInWithPopup(auth, travelcounsellors_provider)
    const result = await signInWithRedirect(auth, travelcounsellors_provider)

    // User is signed in.
    // Provider data available from the result.user.getIdToken()
    // or from result.user.providerData
    console.log({ signInWithRedirectResult: result })
    /*
    "{"http://schemas.microsoft.com/identity/claims/displayname":"tagbox1","http://schemas.microsoft.com/ws/2008/06/identity/claims/groups":["85e1778e-2a0b-48a7-96f0-24f729693d9a","d4aba6bb-bf09-4120-acc1-9d58c99722a6"],"http://schemas.microsoft.com/identity/claims/tenantid":"d794c622-14fe-4c4c-9afe-b91ef81ca280","http://schemas.microsoft.com/identity/claims/identityprovider":"https://sts.windows.net/d794c622-14fe-4c4c-9afe-b91ef81ca280/","http://schemas.microsoft.com/identity/claims/objectidentifier":"45421f38-b5e4-4ae5-a90c-d62ec0e36c50","http://schemas.xmlsoap.org/ws/2005/05/identity/claims/givenname":"tagbox1","http://schemas.xmlsoap.org/ws/2005/05/identity/claims/name":"tagbox1@travelcounsellors.com","http://schemas.microsoft.com/claims/authnmethodsreferences":["http://schemas.microsoft.com/ws/2008/06/identity/authenticationmethod/password","http://schemas.microsoft.com/claims/multipleauthn"],"http://schemas.xmlsoap.org/ws/2005/05/identity/claims/emailaddress":"tagbox1@travelcounsellors.com"}"
    */
    const rawUserInfo = JSON.parse(result._tokenResponse.rawUserInfo)

    //var displayname = rawUserInfo["http://schemas.microsoft.com/identity/claims/displayname"]
    var groups =
      rawUserInfo[
        'http://schemas.microsoft.com/ws/2008/06/identity/claims/groups'
      ]
    var emailaddress =
      rawUserInfo[
        'http://schemas.xmlsoap.org/ws/2005/05/identity/claims/emailaddress'
      ]
    var givenname =
      rawUserInfo[
        'http://schemas.xmlsoap.org/ws/2005/05/identity/claims/givenname'
      ]
    var surname =
      rawUserInfo[
        'http://schemas.xmlsoap.org/ws/2005/05/identity/claims/surname'
      ]
    var name =
      rawUserInfo['http://schemas.xmlsoap.org/ws/2005/05/identity/claims/name']
    var country =
      rawUserInfo[
        'http://schemas.xmlsoap.org/ws/2005/05/identity/claims/country'
      ]

    var displayName = surname ? givenname + ' ' + surname : givenname
    console.log({ rawUserInfo })
    console.log({
      givenname,
      surname,
      emailaddress,
      displayName,
      name,
      groups,
      country,
    })

    await fireAuth.currentUser.updateProfile({
      displayName,
      email: emailaddress,
    })
    var { user } = result
    return {
      ...user,
      displayName,
      email: emailaddress,
      groups,
      country,
      idToken: result._tokenResponse.idToken,
    }
  } catch (error) {
    /*
            // Handle Errors here.
        const errorCode = error.code;
        const errorMessage = error.message;
        // The email of the user's account used.
        const email = error.customData.email;
        // The AuthCredential type that was used.
        const credential = SAMLAuthProvider.credentialFromError(error);
        // Handle / display error.
        console.log({signInWithPopupError: error})
        // ...

    // [END auth_saml_signin_popup_modular]
    */
    console.log({ signInWithTravelcounsellors: error })
    logger.error(error)
    throw error
  }
}

export const signInWithBonarea = async () => {
  try {
    const auth = getAuth()
    const result = await signInWithRedirect(auth, bonarea_provider)
    //const result = await signInWithRedirect(auth, bonarea_provider)

    // User is signed in.
    // Provider data available from the result.user.getIdToken()
    // or from result.user.providerData
    console.log({ signInWithRedirectResult: result })

    return
  } catch (error) {
    /*
            // Handle Errors here.
        const errorCode = error.code;
        const errorMessage = error.message;
        // The email of the user's account used.
        const email = error.customData.email;
        // The AuthCredential type that was used.
        const credential = SAMLAuthProvider.credentialFromError(error);
        // Handle / display error.
        console.log({signInWithPopupError: error})
        // ...

    // [END auth_saml_signin_popup_modular]
    */
    console.log({ signInWithBonarea: error })
    logger.error(error)
    throw error
  }
}

export const register = async ({ username, password, displayName }) => {
  try {
    await fireAuth.createUserWithEmailAndPassword(username, password)
    await fireAuth.currentUser.updateProfile({ displayName })
    const { user } = await fireAuth.signInWithEmailAndPassword(
      username,
      password
    )
    return user
  } catch (error) {
    console.log('firebase.register')
    console.log({ firebaseError: error })
    logger.error(error)
    throw error
  }
}

export const login = async ({ username, password }) => {
  try {
    const { user } = await fireAuth.signInWithEmailAndPassword(
      username,
      password
    )
    console.log(fireAuth.currentUser, user)
    return user
  } catch (error) {
    logger.error(error)
    console.log({ firebaseLoginError: error })
    throw error
  }
}

export const loginAnonymously = async () => {
  try {
    const { user } = await fireAuth.signInAnonymously()
    console.log(fireAuth.currentUser, user)
    return user
  } catch (error) {
    logger.error(error)
    throw error
  }
}

export const resetPassword = async (email) => {
  try {
    const res = fireAuth.sendPasswordResetEmail(email)
    return res
  } catch (error) {
    throw error
  }
}

export const logout = () => {
  return fireAuth.signOut()
}

export const verifyUser = async () => {
  return new Promise((resolve, reject) => {
    console.log('verifyUser')
    console.log({ fireAuth })
    console.log({ fireAuthCurrentUser: fireAuth.currentUser })
    fireAuth.onAuthStateChanged((user) => {
      if (!user) {
        console.log('User not authenticated')
        reject(new Error('User not authenticated'))
      }
      console.log('User authenticated')
      console.log({ user })
      resolve(user)
    })
  })
}
