import React, { useState } from 'react'
import Dialog from '@material-ui/core/Dialog'
import './cleanup-wizard.scss'
import Button from '@material-ui/core/Button'
import IconButton from '@material-ui/core/IconButton'
import CloseIcon from '@material-ui/icons/Close'
import Splits from './Split'
import Merges from './Merge'
import Removes from './Remove'
import history from '../../services/history'
import BEAR from '../../assets/bear.png'

const CleanupWizard = ({
  SplitTags,
  MergeTags,
  DeleteTags,
  isOpen,
  closeDialog,
  onReset,
  gettingSuggestions,
  workspaceId,
  allTags,
}) => {
  const [menuState, setMenuState] = React.useState('split')
  const [tagsToSplit, setTagsToSplit] = useState([])
  const [tagsToMerge, setTagsToMerge] = useState([])
  const [tagsToRemove, setTagsToRemove] = useState([])

  const handleClose = () => {
    onReset()
    closeDialog(false)
    history.push(`/w/${workspaceId}`)
  }

  const nextStep = async () => {
    switch (menuState) {
      case 'split':
        const toSplit = tagsToSplit.filter((tag) => tag.checked)
        if (toSplit.length) {
          await SplitTags(toSplit)
          setMenuState('merge')
        } else {
          setMenuState('merge')
        }
        break
      case 'merge':
        const toMerge = tagsToMerge
          .filter((tag) => tag.checked)
          .map((group) => {
            return group.tags
          })
        if (toMerge.length) {
          await MergeTags(toMerge)
          setMenuState('remove')
        } else {
          setMenuState('remove')
        }
        break
      case 'remove':
        const toRemove = tagsToRemove
          .filter((tag) => tag.checked)
          .map((tag) => tag.id)
        if (toRemove.length) {
          await DeleteTags(toRemove)
          handleClose()
        } else {
          handleClose()
        }
        break
      default:
        break
    }
  }

  const qualifies = allTags.length > 20

  return (
    <Dialog
      onClose={handleClose}
      fullWidth={true}
      maxWidth={'lg'}
      aria-labelledby="simple-dialog-title"
      open={isOpen}
    >
      <IconButton
        className="close-dialog-setting"
        onClick={handleClose}
        component="span"
      >
        <CloseIcon />
      </IconButton>
      {!qualifies && (
        <div className="cleanup-wizard">
          <div className="cleanup-empty-state">
            <div className={'wizard-header'}>Tag Cleanup</div>
            <div>
              <img src={BEAR} className={'bear'} />
            </div>
            <div>
              Our magical Tag Cleanup will become
              <br />
              available when you have at least 20 tags.
              <br />
              <br />
              To unlock it, import or upload more files.
            </div>
          </div>
        </div>
      )}
      {qualifies && (
        <div className="cleanup-wizard">
          <p className="wizard-header">
            We can’t wait to start helping you get organized!
          </p>

          <div className="wizard-container">
            <div className="cleanup-wizard-tabs">
              <p
                className={menuState === 'split' ? 'selected' : ''}
                onClick={() => setMenuState('split')}
              >
                1. Split tags
              </p>
              <p
                className={menuState === 'merge' ? 'selected' : ''}
                onClick={() => setMenuState('merge')}
              >
                2. Merge tags
              </p>
              <p
                className={menuState === 'remove' ? 'selected' : ''}
                onClick={() => setMenuState('remove')}
              >
                3. Remove tags
              </p>
            </div>
            <div className="wizard-content-container">
              <div className="cleanup-form-container">
                {menuState === 'split' && (
                  <Splits
                    tagsToSplit={tagsToSplit}
                    setTagsToSplit={setTagsToSplit}
                    currentScreen={menuState}
                  />
                )}
                {menuState === 'merge' && (
                  <Merges
                    tagsToMerge={tagsToMerge}
                    setTagsToMerge={setTagsToMerge}
                    currentScreen={menuState}
                    MergeTags={MergeTags}
                  />
                )}
                {menuState === 'remove' && (
                  <Removes
                    tagsToRemove={tagsToRemove}
                    setTagsToRemove={setTagsToRemove}
                    currentScreen={menuState}
                  />
                )}
              </div>
            </div>
          </div>
          <div className="button-container">
            <Button onClick={() => nextStep()} disabled={gettingSuggestions}>
              {`${menuState} tags and ${
                menuState === 'remove' ? 'Finish' : 'Continue'
              }`}
            </Button>
          </div>
        </div>
      )}
    </Dialog>
  )
}

export default CleanupWizard
