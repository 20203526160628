import DriveSync from './DriveSync'
import { inject, observer } from 'mobx-react'
import './style.scss'

export default inject(({ filesStore }) => ({
  getDriveFolders: filesStore.getDriveFolders,
  setAuthResponse: filesStore.setAuthResponse,
  access_token: filesStore.driveAccessToken,
  nameFolders: filesStore.nameFolders,
}))(observer(DriveSync))
