import React from 'react'
import { AddCardLink } from './MyGlobalStyle'
import ADD from '../../assets/add.svg'
import './TagsManagment.scss'

export default ({ onClick, t, laneId }) => {
  return (
    <AddCardLink onClick={onClick}>
      <img src={ADD} className="addButton" alt="add tag button" />
      <p className="card-link-text">Add a Tag</p>
    </AddCardLink>
  )
}
