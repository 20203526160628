import React, { useState, useEffect } from 'react'
import Chip from '@material-ui/core/Chip'
import SwapHorizIcon from '@material-ui/icons/SwapHoriz'
import FormControlLabel from '@material-ui/core/FormControlLabel'
import Checkbox from '@material-ui/core/Checkbox'
import CircularProgress from '@material-ui/core/CircularProgress'
import '../cleanup-wizard.scss'
import EmptyStateCleanup from '../EmptyStateCleanup'
import Button from '@material-ui/core/Button'

const Merges = ({
  getMergeSuggestions,
  tagsToMerge,
  setTagsToMerge,
  currentScreen,
  MergeTags,
}) => {
  const [isCheckAll, setIsCheckAll] = useState(false)
  const [isLoading, setIsLoading] = useState(true)

  useEffect(() => {
    const startMerge = async () => {
      let allSuggestions = await getMergeSuggestions()
      const autoMerge = allSuggestions
        .filter((suggestion) => {
          return suggestion.similarity > 0.99
        })
        .map((suggestion) => suggestion.tags)
      if (autoMerge.length > 0) {
        await MergeTags(autoMerge)
        allSuggestions = await getMergeSuggestions()
      }

      allSuggestions.forEach((suggestion, id) => {
        suggestion.tags = [...suggestion.tags]
        suggestion.checked = false
        suggestion.id = (id + 1).toString()
      })

      setTagsToMerge(allSuggestions)
      setIsLoading(false)
    }

    startMerge()
  }, [])

  const handleSelectAll = (e) => {
    setIsCheckAll(!isCheckAll)
    const newList = [...tagsToMerge]
    if (isCheckAll) {
      newList.map((tag) => (tag.checked = false))
    } else {
      newList.map((tag) => (tag.checked = true))
    }
    setTagsToMerge(newList)
  }

  const handleClick = (e) => {
    const { id, checked } = e.target
    const newList = tagsToMerge.map((tag) =>
      tag.id === id ? { ...tag, checked: checked } : tag
    )
    if (!newList.some((tag) => !tag.checked)) {
      setIsCheckAll(true)
    }
    newList.some((tag) => !tag.checked)
      ? setIsCheckAll(false)
      : setIsCheckAll(true)
    setTagsToMerge(newList)
  }

  const switchTags = (e) => {
    let toSwitch = [...tagsToMerge]
    toSwitch.map((group) => {
      if (group.id === e) {
        ;[group.tags[0], group.tags[1]] = [group.tags[1], group.tags[0]]
      }
    })
    setTagsToMerge(toSwitch)
  }

  const merges = tagsToMerge.map(({ id, checked, tags }, index) => {
    return (
      <div className="cleanup-row" key={index}>
        <FormControlLabel
          control={
            <Checkbox
              key={id}
              type="checkbox"
              id={id}
              onChange={handleClick}
              checked={checked}
            />
          }
        />
        <div className="merger-wrapper">
          <Chip
            className="left-tags"
            style={{
              margin: '2px',
              background: '#bfc4c8' && '1.5px solid #00aceb',
            }}
            size="small"
            label={tags[0].name}
          />
        </div>
        <Button id={id} onClick={() => switchTags(id)} color="primary">
          <SwapHorizIcon className="merge-arrow" />
        </Button>
        <Chip
          className="right-tags"
          style={{
            margin: '2px',
            background: '#bfc4c8' && '1.5px solid #00aceb',
          }}
          size="small"
          label={tags[1].name}
        />
      </div>
    )
  })
  return (
    <div className="merge-form">
      {isLoading && (
        <div className="cleanup-spinner">
          <CircularProgress />
        </div>
      )}
      {!isLoading && !merges.length && (
        <EmptyStateCleanup currentScreen={currentScreen} />
      )}
      {!isLoading && merges.length ? (
        <div className="options-container">
          <div className="step-description">
            Here are some similar folders you might want to merge into a single
            tag. We will keep the first tag (switch by clicking the icon).
          </div>
          <FormControlLabel
            control={
              <Checkbox
                checked={isCheckAll}
                onChange={handleSelectAll}
                name="checkedB"
                color="primary"
              />
            }
            label="Select all"
          />
          <div className="form-options">{merges}</div>
        </div>
      ) : null}
    </div>
  )
}

export default Merges
