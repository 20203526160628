import { inject, observer } from 'mobx-react'
import Collections from './Collections'
import './Collections.scss'

export default inject(({ filesStore, orgsWorkspacesStore }) => ({
  collections: filesStore.collections,
  isAllowed: orgsWorkspacesStore.isAllowed,
  COLLECTIONS_IN_PAGE: filesStore.COLLECTIONS_IN_PAGE,
  getAllCollections: filesStore.getAllCollections,
  collectionsCount: filesStore.collectionsCount,
  orgPlanLimits: orgsWorkspacesStore.orgPlanLimits,
  searchCollections: filesStore.searchCollections,
  textToSearch: filesStore.textToSearch,
}))(observer(Collections))
