import React, { useState, useEffect } from 'react'
import Dialog from '@material-ui/core/Dialog'
import Button from '@material-ui/core/Button'
import DialogActions from '@material-ui/core/DialogActions'
import DialogContent from '@material-ui/core/DialogContent'
import DialogTitle from '@material-ui/core/DialogTitle'
import { useDropzone } from 'react-dropzone'
import UPLOAD from '../../assets/upload.svg'
import { MyButton } from '../_MyComponents'
import LinearProgress from '@mui/material/LinearProgress'
import IMAGE from '../../assets/image.svg'

const NewVersionUpload = ({
  uploadToS3,
  filesInQ = [],
  analysisQIndex,
  recentlyUploadedFilesIds,
  fileId,
  isUpload,
  closeUpload,
  mergeVersion,
  deleteFile,
  isOpen,
  close,
  isMobile,
}) => {
  const [progress, setProgress] = React.useState(0)

  React.useEffect(() => {
    const timer = setInterval(() => {
      setProgress((oldProgress) => {
        if (oldProgress === 100) {
          return 0
        }
        const diff = Math.random() * 10
        return Math.min(oldProgress + diff, 100)
      })
    }, 1000)

    return () => {
      clearInterval(timer)
    }
  }, [])

  const onFinishUpload = async () => {
    //console.log({originalId: fileId})
    //console.log({newVersion: recentlyUploadedFilesIds[0]})
    await mergeVersion({
      newFileId: recentlyUploadedFilesIds[0],
      oldFileId: fileId,
    })
    closeUpload()
    close()
  }

  const onCloseDialog = async () => {
    const fileId = recentlyUploadedFilesIds[0] || null
    if (fileId) {
      await deleteFile(fileId)
    }

    closeUpload()
    close()
  }

  const onDrop = (acceptedFiles) => {
    uploadToS3(acceptedFiles)
  }

  const handleUpload = () => {
    const fileInput = document.getElementById('upload-input')
    const selectedFiles = [...fileInput.files]
    uploadToS3(selectedFiles)
  }

  const { getRootProps, getInputProps, isDragAccept } = useDropzone({ onDrop })

  const formatFileSize = (size) => {
    const newSize = size / 1000
    if (newSize < 1000) {
      return `${newSize.toFixed(2)}KB`
    } else {
      return `${(newSize / 1000).toFixed(2)}MB`
    }
  }

  const files = filesInQ.map((file, idx) => (
    <div className="uploading-file-container" key={file.name}>
      <img src={IMAGE} />
      <div className="content">
        <div className="text">
          <p className="file-name light">{file.name}</p>
          <p className="file-size">{formatFileSize(file.size)}</p>
        </div>
        <LinearProgress
          variant="determinate"
          style={{ color: '#FBC02D' }}
          color="inherit"
          value={idx < analysisQIndex ? 100 : progress}
        />
      </div>
    </div>
  ))

  return (
    <Dialog
      onClose={onCloseDialog}
      fullWidth={isMobile ? false : true}
      maxWidth={'xs'}
      aria-labelledby="simple-dialog-title"
      open={isOpen}
    >
      <DialogTitle {...getRootProps()} id="form-dialog-title">
        Upload New Version
      </DialogTitle>
      <input {...getInputProps()} />
      <DialogContent className="dialog-content">
        <div className={'dropzone-area solid'}>
          <div className="flex-row">
            <img src={UPLOAD} />
            <div className="dropzone-text">
              <p className="p">Upload a new file</p>
              <p className="p light">
                JPG, PNG, or any other format (no size limit)
              </p>
            </div>
          </div>
          <label htmlFor="upload-input">
            <MyButton variant="contained" component="span" color="primary">
              Upload
            </MyButton>
          </label>
        </div>
        <div className="uploading-files-container">{files}</div>
        <input
          accept="*"
          style={{ display: 'none' }}
          id="upload-input"
          name="file"
          type="file"
          onClick={(e) => {
            e.target.value = ''
          }}
          onChange={(e) => handleUpload(e.target.files)}
        />
      </DialogContent>
      <DialogActions>
        <Button onClick={onCloseDialog} variant="contained" color="grey">
          Cancel
        </Button>
        <Button
          onClick={onFinishUpload}
          color="primary"
          variant="contained"
          disabled={filesInQ.length < 1 || isUpload}
        >
          {'Replace Version'}
        </Button>
      </DialogActions>
    </Dialog>
  )
}

export default NewVersionUpload
